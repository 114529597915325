import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';


import { UserListComponent } from './user-list.component';
import { UserViewComponent } from './user-view.component';
import { UserEditComponent } from './user-edit.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { MyprofileComponent } from './myprofile.component';
import { SuperclientUserListComponent } from './superclient-user-list/superclient-user-list.component';
import { SuperclientUserEditComponent } from './superclient-user-edit/superclient-user-edit.component';
import { SuperclientUserViewComponent } from './superclient-user-view/superclient-user-view.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Select2Module } from 'ng-select2-component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [UserListComponent, UserViewComponent, UserEditComponent, MyprofileComponent, SuperclientUserListComponent, SuperclientUserEditComponent, SuperclientUserViewComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    NgxPaginationModule,
    Select2Module,
    ImageCropperModule
  ],
  exports:[
    UserViewComponent,
    SuperclientUserViewComponent
  ]
})
export class UserModule { }
