import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormControlName, AbstractControl } from '@angular/forms';
import { data, escapeSelector } from 'jquery';
import { UserService } from '../user/user.service';
import { FileUploadService } from '../config/file-upload/file-upload.service';
import { Observable, merge, fromEvent, Subscription, timer } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';
import { IUserInfo } from '../user/user-info';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { ConfigService } from '../shared/services/config.service';
//import { settings } from 'cluster';

/**
 * 
 * {
    "resourceTypes": [
        {
            "code": "Client",
            "name": "Client",
            "displayName": "SE_Client"
        },
        {
            "code": "Technician",
            "name": "Technician",
            "displayName": "SE_Technician"
        }
    ],
    "matchingAlgorithnmFields": [
        {
            "fieldName": "IT System",
            "isConsidered": "true"
        }
    ]
}
 */

@Component({
  selector: 'wfm-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  configForm: FormGroup;
  notificationModal: any;
  isSubmitInvalid: boolean = false

  errorMessage: string;
  userInfo: IUserInfo = {} as IUserInfo;
  private sub: Subscription;
  previewUrl: any;
  userImage = '';
  fileData: File = null;
  updateUserId: string;
  isResetPassword = false;
  isEditCounty: boolean = false
  selectedCounty: any
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperVisible: boolean;
  holdMunicipalityChange: boolean = false;
  isAllMunicipalitySelected: boolean = false;

  imageType: string;

  configDisableExperienceAreas: boolean;
  configDisableUpdatingEmpAvailability: boolean;
  configClientIsAbleToSelectTheEmployeesInBooking: boolean;

  constructor(
    private fb: FormBuilder, private userService: UserService, private uploadService: FileUploadService,
    private configService: ConfigService
  ) {

  }

  ngOnInit() {
    this.configForm = this.fb.group({
      resourceTypes: this.fb.array([this.createResource()]),
      matchingAlgorithnmFields: this.fb.array([this.createMatchingField()])
    })

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.getCurrentConfigData();
    this.getAdminConfigurationDetails();
  }

  changeColor(event, criteria:FormGroup,index:number) {
    criteria.value.colorCode = event;
   // const x = this.configForm.value.resourceTypes[index];
    this.configForm.value.resourceTypes[index] = criteria.value;
    this.configForm.patchValue(this.configForm.value)

    console.log("event-", criteria)
  }

  saveAdminConfigurationDetails(): void {
    let request: any[] = [];
    request = [
      {
        title: 'configDisableExperienceAreas', isActive: this.configDisableExperienceAreas
      },
      {
        title: 'configDisableUpdatingEmpAvailability', isActive: this.configDisableUpdatingEmpAvailability
      },
      {
        title: 'configClientIsAbleToSelectTheEmployeesInBooking', isActive: this.configClientIsAbleToSelectTheEmployeesInBooking
      }
    ];
    this.configService.updateAdminCOnfigurations(request).subscribe(data => {
      this.setNotificationData('update', true);

    })
  }

  getAdminConfigurationDetails(): void {
    this.configService.getAdminCOnfigurations().subscribe(data => {
      if (data) {
        this.configDisableExperienceAreas = data.find(a => a.title === 'configDisableExperienceAreas').isActive;
        this.configDisableUpdatingEmpAvailability = data.find(a => a.title === 'configDisableUpdatingEmpAvailability').isActive;
        this.configClientIsAbleToSelectTheEmployeesInBooking = data.find(a => a.title === 'configClientIsAbleToSelectTheEmployeesInBooking').isActive;

      }
    })
  }

  createResource(id = 0, code = null, name = null, displayName = null, displayLetter = null, colorCode = null) {
    return this.fb.group({
      id: [id == null ? 0 : id],
      code: [code ? code : ''],
      colorCode: [colorCode?  colorCode: ''],
      displayLetter: [displayLetter ? displayLetter : '', Validators.required],
      name: [name ? name : '', Validators.required],
      displayName: [displayName ? displayName : '', Validators.required]
    })
  }
  createMatchingField(id = 0, fieldName = null, isConsidered = null) {
    return this.fb.group({
      id: [id],
      fieldName: [fieldName ? fieldName : ''],
      isConsidered: [isConsidered ? true : false]
    })
  }

  get resourceTypes(): FormArray {
    return this.configForm.get('resourceTypes') as FormArray;
  }

  get matchingAlgorithnmFields(): FormArray {
    return this.configForm.get('matchingAlgorithnmFields') as FormArray;
  }

  addResource(resource) {
    this.resourceTypes.push(this.createResource(resource));
  }
  removeResource(index) {
    this.resourceTypes.removeAt(index);
  }

  addMatchingField(field) {
    this.matchingAlgorithnmFields.push(this.createMatchingField(field));
  }
  removeMatchingField(index) {
    this.matchingAlgorithnmFields.removeAt(index);
  }

  save() {
    this.validateAllFormFields()
    if (this.configForm.valid) {
      this.userService.saveConfigurations(this.configForm.value).subscribe(
        (res) => {
          this.setNotificationData('update', res);
          this.getCurrentConfigData();
        });
    } else {
      this.isSubmitInvalid = true
    }

  }
  // control.markAsTouched({ onlySelf: true });
  validateAllFormFields() {
    const resourceArray = this.configForm.get('resourceTypes') as FormArray;
    Object.keys(resourceArray['controls']).forEach(element => {
      const control = resourceArray.get(element);
      Object.keys(control['controls']).forEach(field => {
        const controlItem = control.get(field);
        console.log(controlItem);
        if (controlItem instanceof FormControl) {
          controlItem.markAsTouched({ onlySelf: true });
        }
      });
    });
  }

  getCurrentConfigData() {
    this.userService.getConfigData().subscribe((res) => {

      //  let algorithm = res.matchingAlgorithnmFields.filter(item=>item.fieldName =='Experience_Areas');
      //  if(!algorithm.length)
      //  {
      //    let experienceCritirea = {id: 0,fieldName: "Experience_Areas",isConsidered: false};
      //    res.matchingAlgorithnmFields.push(experienceCritirea);
      //  }
      //  console.log(res.matchingAlgorithnmFields);

      const resourceTypesArray = this.fb.array([]);
      res.resourceTypes.forEach(resource => {
        var newResource = this.createResource(resource.id, resource.code, resource.name, resource.displayName, resource.displayLetter, resource.colorCode)
        resourceTypesArray.push(newResource);
      });
      this.configForm.setControl('resourceTypes', resourceTypesArray);

      const matchingFildssArray = this.fb.array([]);

      // ######################################
      // trim array to show only IT systems
      // To be removed in later upgrade
      // res.matchingAlgorithnmFields.length = 1;
      // ######################################

      res.matchingAlgorithnmFields.forEach(field => {
        var newField = this.createMatchingField(field.id, field.fieldName, field.isConsidered)
        matchingFildssArray.push(newField);
      });
      this.configForm.setControl('matchingAlgorithnmFields', matchingFildssArray);


      setTimeout(() => {
        res.resourceTypes.forEach((resource,index) => {
          var divElement = document.getElementById("item-"+index);
          divElement.style.backgroundColor = resource.colorCode;
        });    
      }, 100);

    });

   

    // this.configService.getMatchingAlgorithmCriterias().subscribe((res) => {
    //   console.log(res);
    //   const matchingFildssArray = this.fb.array([]);
    //   res.forEach(field => {
    //     var newField = this.createMatchingField(field.id, field.fieldName, field.isConsidered)
    //     matchingFildssArray.push(newField);
    //   });
    //   this.configForm.setControl('matchingAlgorithnmFields', matchingFildssArray);
    // });
  }

  setNotificationData(flow, status): void {
    if (flow === 'update') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Uppdatering av konfiguration har lyckats!';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av konfiguration misslyckades! Vänligen försök igen.';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#configNotificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#configNotificationModal') as any).modal('hide');
    }, 1000);
  }
}
