import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav.component';
import { AppRoutingModule } from '../app-routing.module';
import { HasClaimDirective } from '../security/has-claim.directive';
import { UserClientListComponent } from './user-client-list.component';
import {FormsModule} from '@angular/forms';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { SuperclientClientListComponent } from './superclient-client-list/superclient-client-list.component';
import { FooterComponent } from './components/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [ NavComponent, HasClaimDirective, UserClientListComponent, FooterComponent, SuperclientClientListComponent ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    FilterPipeModule,
    TranslateModule
  ],
  exports: [
    NavComponent,
    UserClientListComponent,
    FooterComponent,
    SuperclientClientListComponent,
    TranslateModule
  ]
})
export class SharedModule { }
