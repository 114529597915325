import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../user/user.service';
import { IUserInfo } from '../user/user-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportInfo } from './report-info';
import { ReportSearchCriteria } from './report-search-criteria';
import { CalendarService } from '../calendar/calendar.service';
import { ConfigService } from '../shared/services/config.service';
import SwedishHolidays from '../shared/constants/swedishHolidays';

@Component({
  selector: 'wfm-employee-report',
  templateUrl: './admin-report.component.html',
  styleUrls: ['./admin-report.component.css']
})
export class AdminReportComponent implements OnInit, AfterViewInit {

  empType = 'Employee';
  swedishHolidays: typeof SwedishHolidays;
  errorMessage: string;
  usersList: IUserInfo[];
  searchForm: FormGroup;
  reportInfo: ReportInfo = {} as ReportInfo;
  cashierReportInfo: ReportInfo = {} as ReportInfo;
  searchCriteria: ReportSearchCriteria = {} as ReportSearchCriteria;
  showNoRecordMessage: boolean;
  currentDate: Date;
  notificationModal: any;
  resourceTypes: any[]
  reportInfoList: any[];
  firstReportInfo: any;
  bookingUnitName: any = '';
  empHourlyRate: number = 0;
  totalAmount: number;
  showEmpHourlyRate: boolean;
  configClientOverTimeRule: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private calendarService: CalendarService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    // this.userService.getUserList(this.empType).subscribe(
    //   (res: IUserInfo[]) => {
    //     this.usersList = res;
    //   },
    //   (error: any) => this.errorMessage = error as any
    // );
    this.swedishHolidays = SwedishHolidays;
    this.searchForm = this.fb.group({
      userType: [],
      userId: [],
      month: []
    });
    this.reportInfo.timeRecords = [];
    this.cashierReportInfo.timeRecords = [];
    this.searchCriteria.resourceTypeId = 1;

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };
    this.getCurrentConfigData();
    this.getBookingUnit();
    let loggedInUserRole: string;
    loggedInUserRole = localStorage.getItem('role');

    if (loggedInUserRole == 'Administrator' ||  loggedInUserRole == 'Employee'
    ) {
      this.showEmpHourlyRate = true;
    }

  }

  getRowStyle(item: any) {
    console.log("x-",item)
    return 'background-color : red' ;// Example: applying background color based on the value of 'column3'
      // Add more styles as needed based on other properties
    ;
  }

  ngAfterViewInit(): void {

    ($('.kendo-picker') as any).bind('focus', function () {
      $(this).data('kendoDatePicker').open();
    });

    ($('#kendo-month') as any).kendoDatePicker({
      format: 'MMMM yyyy',
      start: 'year',
      depth: 'year',
      change: (val) => {
        this.searchForm.patchValue({
          month: val.sender._value
        });
        if (this.searchForm.get('userType').value) {
          this.filtersChange()
        }
      }
    });
    // $("#kendo-month").data("kendoDatePicker").value(new Date());
    // this.searchForm.patchValue({
    //   month: new Date()
    // });
  }

  getEmployeeHourlyRateDetails(userId: string): void {
    this.userService.getEmployeeHourlyRateDetails(userId).subscribe(data => {
      this.empHourlyRate = 0;
      this.totalAmount = 0;
      if (data) {
        if(data.isActive)
        {
          this.empHourlyRate = data.hourlyRate;
          this.totalAmount = this.empHourlyRate * this.reportInfoList[0].total;
        }
       
      }
    })
  }

  filtersChange(): void {
    const selectedType = this.searchForm.get('userType').value;
    var selectedMonth = this.searchForm.get('month').value;
    this.userService.getReportUserList(selectedMonth.toISOString(), selectedType).subscribe(
      (res: IUserInfo[]) => {
        this.usersList = res;
      },
      (error: any) => this.errorMessage = error as any
    );
    // this.searchForm.patchValue({
    //   userId :''
    // });
  }

  getUserWiseAdminConfigurationDetails(userId: string): void {
    this.configService.getUserWiseAdminCOnfigurations(userId).subscribe(data => {
      if (data.length) {
         this.configClientOverTimeRule = data.find(a => a.title === 'configClientOverTimeRule').isActive;    
      }
    })
  }

  generateReport(): void {

    const validator = ($('#reportSearchForm') as any).kendoValidator().data('kendoValidator');
    if (validator.validate()) {
      this.searchCriteria.userType = this.searchForm.get('userType').value;
      this.searchCriteria.userId = this.searchForm.get('userId').value;
      this.searchCriteria.month = this.searchForm.get('month').value;
      // this.searchCriteria.month = this.searchForm.get('month').value;
      //this.searchCriteria.resourceTypeId = 1;
      this.reportInfoList = [];
      this.userService.getReportData(this.searchCriteria).subscribe(
        (res) => {
          this.reportInfoList = res;
          console.log("report info-", this.reportInfoList);
          this.showNoRecordMessage = true;
          for (const resourceTypeId in this.reportInfoList) {
            if (Object.prototype.hasOwnProperty.call(this.reportInfoList, resourceTypeId)) {
              const element = this.reportInfoList[resourceTypeId];
              if (resourceTypeId == '1' || resourceTypeId == '0') {
                this.firstReportInfo = element;
              }
              this.showNoRecordMessage = this.showNoRecordMessage && element.timeRecords.length == 0;
            }
          }
          this.getEmployeeHourlyRateDetails(this.searchCriteria.userId);
          this.getUserWiseAdminConfigurationDetails(this.searchCriteria.userId);

          // if (this.reportInfoList.every(e => e.timeRecords.length == 0)) {
          //   this.showNoRecordMessage = true;
          // } else {
          //   this.showNoRecordMessage = false;
          // }

          // if (this.searchCriteria.userType === 'Client') {

          //   this.userService.getReportData(this.searchCriteria).subscribe(
          //     (res2: ReportInfo) => {
          //       this.cashierReportInfo = res2;
          //       if (this.reportInfo.timeRecords.length == 0 && this.cashierReportInfo.timeRecords.length == 0) {
          //         this.showNoRecordMessage = true;
          //       } else {
          //         this.showNoRecordMessage = false;
          //       }
          //     },
          //     (error: any) => this.errorMessage = error as any
          //   );
          // } else {
          //   this.cashierReportInfo.timeRecords = [];
          //   if (this.reportInfo.timeRecords.length == 0) {
          //     this.showNoRecordMessage = true;
          //   } else {
          //     this.showNoRecordMessage = false;
          //   }
          // }

        },
        (error: any) => this.errorMessage = error as any
      );
    }
  }

  markSalaryOrInvoiceSent(): void {
    //this.reportInfo.isSalaryOrInvoiceSent = true;
    this.userService.updateSalaryInvoiceSentDetails(this.searchCriteria).subscribe(
      (res: boolean) => {
        this.reportInfo.isSalaryOrInvoiceSent = true;
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  checkItemExists(hourlyWorkedPecentages, percentage) {
    if (hourlyWorkedPecentages.length > 0) {
      var item = hourlyWorkedPecentages.filter(p => p.typeOfPercentage == percentage);
      if (item.length > 0) {
        return item[0].workedHours;
      }
    }

    return 0;
  }

  confirmMissingTimeLogUpdate() {
    ($('#missingTimeUpdateConfirm') as any).modal('show');
  }

  addMissingTimeLogs(): void {
    ($('#missingTimeUpdateConfirm') as any).modal('hide');
    this.currentDate = new Date();
    const month = this.currentDate.getMonth()
    var pastMonthsHolidays: any[] = []
    for (let i = month; i > -1; i--) {
      let jsonMonthKey = this.getJsonDateFormat(i)
      let monthHolidayArr = this.swedishHolidays[jsonMonthKey];
      if (monthHolidayArr) {
        monthHolidayArr.forEach(element => {
          let holidayStr = this.getJsonDateStrFormat(i, element.date);
          let dateObj = new Date(holidayStr)
          pastMonthsHolidays.push(dateObj.toISOString())
        });
      }
    }
    this.calendarService.updateAllocationMissingTimeLogs(pastMonthsHolidays).subscribe(
      (status: boolean) => {
        this.setNotificationData('update', status);
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  getJsonDateFormat(monthInt: number): string {
    return this.currentDate.getFullYear() + '' + (monthInt + 1 > 9 ? monthInt + 1 : '0' + (monthInt + 1))
  }
  getJsonDateStrFormat(monthInt: number, date: string): string {
    return this.currentDate.getFullYear() + '-' + (monthInt + 1 > 9 ? monthInt + 1 : '0' + (monthInt + 1)) + '-' + date
  }

  setNotificationData(flow, status): void {
    if (flow === 'update') {
      if (status) {
        this.notificationModal.header = 'Successfull';
        this.notificationModal.description = 'Missing time added successfully.';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades'; //'Failed';
        this.notificationModal.description = 'Missing time adding failed!';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#notificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#notificationModal') as any).modal('hide');
    }, 3000);
  }

  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
      console.log(res);
      this.resourceTypes = res.resourceTypes;
    })
  }

  getResourceTypeName(id) {
    return (this.resourceTypes.find(x => x.id == id)).displayName
  }

  getBookingUnit() {
    this.configService.getBookingUnits().subscribe(res => {
      if (res) {
        this.bookingUnitName = (res.bookingUnits.length > 0) ? res.bookingUnits[0].name : '';
      }
    });
  }
}
