import { UserService } from './../../user/user.service';
import { ReportSearchCriteria } from './../report-search-criteria';
import { ReportInfo } from './../report-info';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IUserInfo } from './../../user/user-info';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';

@Component({
  selector: 'wfm-superclient-report',
  templateUrl: './superclient-report.component.html',
  styleUrls: ['./superclient-report.component.css']
})
export class SuperclientReportComponent implements OnInit, AfterViewInit {

  errorMessage: string;
  usersList: IUserInfo[];
  searchForm: FormGroup;
  reportInfo: ReportInfo = {} as ReportInfo;
  cashierReportInfo: ReportInfo = {} as ReportInfo;
  searchCriteria: ReportSearchCriteria = {} as ReportSearchCriteria;
  showNoRecordMessage: boolean;
  resourceTypes:any;
  reportInfoList:any;
  firstreportinfo:any;
  bookingUnitName : any = '';

  constructor(private fb: FormBuilder, private userService: UserService,  private configService : ConfigService) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      userType: [],
      userId: [],
      month: []
    });
    this.reportInfo.timeRecords = [];
    this.cashierReportInfo.timeRecords = [];
    this.searchCriteria.resourceTypeId = 1;
    (<FormGroup>this.searchForm).setValue(this.initializeSearchForm(), {onlySelf: true});
    this.getSubClientList();
    this.getCurrentConfigData();
    this.getBookingUnit();
  }

  ngAfterViewInit(): void {
    ($('.kendo-picker') as any).bind('focus', function () {
      $(this).data('kendoDatePicker').open();
    });

    ($('#kendo-month') as any).kendoDatePicker({
      format: 'MMMM yyyy',
      start: 'year',
      depth: 'year',
      change: (val) => {
        this.searchForm.patchValue({
          month: val.sender._value
        });
      }
    });
    $("#kendo-month").data("kendoDatePicker").value(new Date());
    this.searchForm.patchValue({
      month: new Date()
    });
  }

  getSubClientList(): void {
    const selectedType = this.searchForm.get('userType').value;
    this.userService.getSubClientUserList(selectedType).subscribe(
      (res: IUserInfo[]) => {
        this.usersList = res;
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  generateReport(): void {

    const validator = ($('#superClientReportSearchForm')as any).kendoValidator().data('kendoValidator');
    if (validator.validate()) {
      this.searchCriteria.userType = this.searchForm.get('userType').value;
      this.searchCriteria.userId = this.searchForm.get('userId').value;
      this.searchCriteria.month = this.searchForm.get('month').value;
      this.searchCriteria.month = this.searchForm.get('month').value;
      this.searchCriteria.resourceTypeId = 1;

      this.userService.getSuperClientReportData(this.searchCriteria).subscribe(
        (res: ReportInfo) => {
          // this.reportInfo = res;
          // this.searchCriteria.resourceTypeId = 2;

          // if (this.searchCriteria.userType === 'Client') {
          //   this.userService.getSuperClientReportData(this.searchCriteria).subscribe(
          //     (res2: ReportInfo) => {
          //       this.cashierReportInfo = res2;
          //       if (this.reportInfo.timeRecords.length == 0 && this.cashierReportInfo.timeRecords.length == 0) {
          //         this.showNoRecordMessage = true;
          //       } else {
          //         this.showNoRecordMessage = false;
          //       }
          //     },
          //     (error: any) => this.errorMessage = error as any
          //   );
          // } else {
          //   this.cashierReportInfo.timeRecords = [];
          //   if (this.reportInfo.timeRecords.length == 0) {
          //     this.showNoRecordMessage = true;
          //   } else {
          //     this.showNoRecordMessage = false;
          //   }
          // }

          //this.reportInfo = res;
          //this.searchCriteria.resourceTypeId = 2;
          this.reportInfoList = res;
          //this.firstreportinfo = res['1']
          this.showNoRecordMessage = true;
          for (const resourceTypeId in this.reportInfoList) {
            if (Object.prototype.hasOwnProperty.call(this.reportInfoList, resourceTypeId)) {
              const element = this.reportInfoList[resourceTypeId];
              if (resourceTypeId =='1' || resourceTypeId =='0') {
                this.firstreportinfo = element;
              }
                this.showNoRecordMessage = this.showNoRecordMessage && element.timeRecords.length==0;
            }
          }

        },
        (error: any) => this.errorMessage = error as any
      );
    }
  }

  markSalaryOrInvoiceSent(): void {
    //this.reportInfo.isSalaryOrInvoiceSent = true;
    this.userService.updateSalaryInvoiceSentDetails(this.searchCriteria).subscribe(
      (res: boolean) => {
          this.reportInfo.isSalaryOrInvoiceSent = true;
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  initializeSearchForm() {
    return {
      userType: 'Client',
      userId: '',
      month: ''
    }
  }

  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
       console.log(res);
      this.resourceTypes = res.resourceTypes;
    })
  }

  getResourceTypeName(id){
    return (this.resourceTypes.find(x => x.id == id)).displayName
  }

  checkItemExists(hourlyWorkedPecentages, percentage)
  {
    //console.log(hourlyWorkedPecentages);
    //console.log(percentage);
    if(hourlyWorkedPecentages.length > 0)
    {
      var item = hourlyWorkedPecentages.filter(p =>p.typeOfPercentage == percentage);
      //console.log(item);
      if(item.length > 0)
      {
        //console.log(item[0].workedHours);
        return item[0].workedHours ;
      }
    }
   
    return 0;
  }
  
  getBookingUnit()
  {
        this.configService.getBookingUnits().subscribe(res => {
          console.log(res);
          if(res)
          {
            this.bookingUnitName = res.bookingUnits[0].name;
            console.log(this.bookingUnitName);
          }
      });
  }
}
