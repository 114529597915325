import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { GenericValidator } from '../shared/generic-validator';
import { Observable, merge, fromEvent, Subscription, timer } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';
import { IUserInfo } from './user-info';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './user.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfigService } from '../shared/services/config.service';

@Component({
  selector: 'wfm-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  userForm: FormGroup;
  subClients: FormArray;
  resetPasswordForm: FormGroup;
  pageTitle: string;
  errorMessage: string;
  userTypes: any[];
  jobTypes: any[];
  subClientsArray: any[];
  isAllAdded: boolean = false;
  resourceTypes: any[];
  matchingAlgorithnmFields: any[];
  userInfo: IUserInfo = {} as IUserInfo;
  private sub: Subscription;
  previewUrl: any;
  userImage = '';
  fileData: File = null;
  updateUserId: string;
  isResetPassword = false;
  isEditCounty: boolean = false
  selectedCounty: any
  isSubmitInvalid: boolean = false
  cropperVisible: boolean
  holdMunicipalityChange: boolean = false
  isAllMunicipalitySelected: boolean = false

  displayMessage: { [key: string]: string } = {};
  displayResetPwdMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  bookingUnitName: any = '';

  configDisableExperienceAreas: boolean;
  configDisableUpdatingEmpAvailability: boolean;
  configClientIsAbleToSelectTheEmployeesInBooking: boolean;
  configClientOverTimeRule: boolean;
  configClientLongBookingPeriod: number = 0;
  configClientEditBookingTime: boolean;
  configClientOverrideLongBookingDateRule: boolean;
  configEmployeeMonthlyMaxWorkingHours: number = 0;
  configEmployeeBookHimself: boolean;

  show0: boolean = true;
  show1: boolean;
  show2: boolean;
  show3: boolean;
  show4: boolean;
  show5: boolean;
  totalRating: number = 0;

  notificationModal: any;
  empHourlyRate: number;
  isActiveEmpHourlyRate: boolean;

  showAdminConfig1: boolean;
  showAdminConfig2: boolean;


  jsonCountyMunicipalities: any[] = [];
  countyMunicipalities: any[] = [];
  counties: any[] = [];
  municipalities: any[] = [{ value: null, label: '', disabled: true }, { value: null, label: '', disabled: true }];
  pharmacySystems: any[] = [];
  usersList: IUserInfo[] = [];

  // favouriteEmployees:any[]=[];
  legitimations: any[] = [];
  experienceAreas: any[] = [];
  skillLevels: any[] = [];
  languageObjArray: any[] = [];
  languages: any[] = [];
  yesNo: any[] = [
    { value: true, label: 'Ja ' },
    { value: false, label: 'Nej ' },
  ];
  yearsOfExperiences: any[] = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10+' },
  ];
  matchingAlgorithnmFieldIsConsidered: any = "";

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private configService: ConfigService
  ) {
    this.userTypes = [
      { id: '1', name: 'Administrator', sename: 'Administratörer' },
      { id: '2', name: 'Employee', sename: 'Anställd' },
      { id: '3', name: 'Client', sename: 'Kund' },
      { id: '4', name: 'SuperClient', sename: 'Super Kund' }
    ];

    this.jobTypes = [
      { id: '1', name: 'Heltid' },
      { id: '2', name: 'Deltid' }
    ];

    this.resourceTypes = [];

    this.subClientsArray = [];

    // Defines all of the validation messages for the form.
    // These could instead be retrieved from a file or database.
    this.validationMessages = {
      userTypeId: {
        required: 'anställningsform krävs'
      },
      firstName: {
        required: 'förnamn krävs',
        minlength: 'måste ha minst 2 tecken',
        maxlength: 'max 50 tecken'
      },
      lastName: {
        required: 'efternamn krävs',
        minlength: 'måste ha minst 2 tecken',
        maxlength: 'max 50 tecken'
      },
      address: {
        required: 'adress krävs',
        maxlength: 'max 100 tecken'
      },
      city: {
        required: 'stad krävs',
        maxlength: 'max 50 tecken'
      },
      zip: {
        required: 'postnummer krävs',
        maxlength: 'max 10 tecken'
      },
      email: {
        required: 'e-post krävs',
        email: 'e-posten ogiltig',
        maxlength: 'max 256 tecken'
      },
      phoneNumber: {
        required: 'telefonnummer krävs',
        maxlength: 'max 50 tecken'
      },
      jobCategoryId: {
        required: 'anställningsform krävs'
      },
      clientName: {
        required: 'apoteketsnamn krävs',
        maxlength: 'max 100 tecken'
      },
      pharmacyId: {
        required: 'apotek-ID krävs',
        maxlength: 'max 100 tecken'
      },
      userName: {
        required: 'användarnamn krävs',
        minlength: 'användarnamn måste ha minst 3 tecken'
      },
      password: {
        required: 'lösenord krävs',
        minlength: 'lösenord måste ha minst 6 tecken'
      },
      conpassword: {
        required: 'lösenord krävs',
        invalid: 'lösenord matchar inte'
      },
      resetpassword: {
        required: 'lösenord krävs',
        minlength: 'lösenord måste ha minst 6 tecken'
      },
      resetconpassword: {
        required: 'lösenord krävs',
        match: 'vlösenord matchar inte'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  setCustomVallidations() {
    const jobCategoryControl = this.userForm.get('jobCategoryId');
    const resourceTypeControl = this.userForm.get('resourceTypeId');
    const clientNameControl = this.userForm.get('clientName');
    const pharmacyIdControl = this.userForm.get('pharmacyId');
    const usernameControl = this.userForm.get('userName');
    const passwordControl = this.userForm.get('passwords.password');
    const conpasswordControl = this.userForm.get('passwords.conpassword');
    const municipalityControl = this.userForm.get('municipality');
    const pharmacySystemsControl = this.userForm.get('pharmacySystems');
    const countyControl = this.userForm.get('county');
    //const subClientsFormArray = this.userForm.get('subClients') as FormArray;
    usernameControl.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(20)]);

    if (this.updateUserId == "0") {

      passwordControl.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(50)]);
      conpasswordControl.setValidators([Validators.required]);
      //usernameControl.setAsyncValidators([this.functionloginAsyncValidator(this.userService)]);
      usernameControl.setAsyncValidators(this.functionloginAsyncValidator(this.userService));
    }
    else {
      usernameControl.setAsyncValidators(this.functionloginEditAsyncValidator(this.userService));
    }

    this.userForm.get('userTypeId').valueChanges
      .subscribe(userType => {
        if (userType === '1') {
          jobCategoryControl.clearValidators();
          resourceTypeControl.clearValidators();
          clientNameControl.clearValidators();
          municipalityControl.clearValidators();
          pharmacySystemsControl.clearValidators();
          countyControl.clearValidators();
          pharmacyIdControl.clearValidators();
          //subClientsFormArray.clearValidators();
          this.municipalities.shift();
          this.counties.forEach(element => {
            element.disabled = false
          });
        } else if (userType === '2') {
          jobCategoryControl.setValidators([Validators.required]);
          resourceTypeControl.setValidators([Validators.required]);
          clientNameControl.clearValidators();
          municipalityControl.clearValidators();
          pharmacySystemsControl.clearValidators();
          countyControl.clearValidators();
          pharmacyIdControl.clearValidators();
          //subClientsFormArray.clearValidators();
          this.municipalities.unshift({ value: 0, code: "0000", label: "Alla Kommuner" })
          this.counties.forEach(element => {
            element.disabled = false
          });
        } else if (userType === '3') {
          clientNameControl.setValidators([Validators.required, Validators.maxLength(100)]);
          pharmacyIdControl.setValidators([Validators.required, Validators.maxLength(100)]);
          jobCategoryControl.clearValidators();
          municipalityControl.setValidators([Validators.required]);
          //pharmacySystemsControl.setValidators([Validators.required]); 
          // Clear validations for Enhet type
          pharmacySystemsControl.clearValidators();
          countyControl.setValidators([Validators.required]);
          resourceTypeControl.clearValidators();
          //subClientsFormArray.clearValidators();
          this.municipalities.shift()
          this.counties.forEach(element => {
            element.disabled = false
          });
        } else if (userType === '4') {
          this.getAllSubClients();
          // if (subClientsFormArray.controls.length === 0) { }
          this.counties.forEach(element => {
            element.disabled = false
          });
          clientNameControl.setValidators([Validators.required, Validators.maxLength(100)]);
          pharmacyIdControl.clearValidators();
          jobCategoryControl.clearValidators();
          resourceTypeControl.clearValidators();
          municipalityControl.clearValidators();
          pharmacySystemsControl.clearValidators();
          countyControl.clearValidators();
          this.municipalities.shift()
        }
        jobCategoryControl.updateValueAndValidity();
        resourceTypeControl.updateValueAndValidity();
        clientNameControl.updateValueAndValidity();
        pharmacyIdControl.updateValueAndValidity();
        pharmacySystemsControl.updateValueAndValidity();
        countyControl.updateValueAndValidity();
        municipalityControl.updateValueAndValidity();
      });
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  ngOnInit() {

    this.subClients = this.fb.array([]);

    this.userForm = this.fb.group({
      adminConfig1: false,
      adminConfig2: false,
      adminConfig3: false,
      adminConfig4: false,
      adminConfig5: '',
      adminConfig6: false,
      configEmployeeBookHimself:false,
      empHourlyRate: 0,
      empMaxWorkingHrs: 0,
      isActiveEmpHourlyRate: false,
      userId: [''],
      userTypeId: ['', Validators.required],
      jobCategoryId: [],
      resourceTypeId: [],
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      clientName: [''],
      pharmacyId: [],
      address: ['', [Validators.required, Validators.maxLength(100)]],
      city: ['', [Validators.required, Validators.maxLength(20)]],
      zip: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(256)]],
      phoneNumber: ['', [Validators.required, Validators.maxLength(50)]],
      userName: ['', null],
      passwords: this.fb.group({
        password: [''],
        conpassword: [''],
      }, { validator: passwordCompare }),
      password: [],
      conpassword: [],
      isActive: [true],
      subClients: this.subClients,
      county: [[], [Validators.required]],
      municipality: ['', [Validators.required]],
      pharmacySystems: [[], [Validators.required]],
      employeeNo: [''],
      isStaredEmployeesOnly: [null],
      countyMunicipalities: [[]],
      legitimationId: [],
      experienceAreas: [],
      yearsOfExperience: [],
      isStdProceduresRead: [null],
      aboutMe: [''],
      cmJson: '',
      favouriteEmployees: [[]],
      languages: [],
      skillLevel: []
    });

    this.resetPasswordForm = this.fb.group({
      resetpassword: ['', Validators.required],
      resetconpassword: ['', Validators.required]
    }, { validator: resetPasswordCompare });



    this.userService.getInnitialMasterData().subscribe((data) => {

      this.pharmacySystems = data.pharmacySystems
      this.counties = data.counties
      this.experienceAreas = data.experienceAreas
      this.legitimations = data.legitimations
      this.skillLevels = data.skillLevels
      this.languages = data.languages

      for (let [key, value] of Object.entries(this.pharmacySystems)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.counties)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.experienceAreas)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.legitimations)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.skillLevels)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.languages)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }

    })


    this.userService.getUserList('Employee').subscribe(
      (res: IUserInfo[]) => {
        this.usersList = res;
        for (let [key, value] of Object.entries(this.usersList)) {
          value['value'] = value.userId;
          value['label'] = value.firstName + ' ' + value.lastName;
        }
      },
      (error: any) => this.errorMessage = error as any
    );

    // Read the product Id from the route parameter
    this.sub = this.route.paramMap.subscribe(
      params => {
        const id = params.get('id');

        this.updateUserId = id;
        this.getUser(id);
      }
    );

    this.setCustomVallidations();
    this.getCurrentConfigData();

    this.getBookingUnit();
    this.getUserWiseAdminConfigurationDetails(this.updateUserId);
    this.getEmployeeHourlyRateDetails(this.updateUserId);
  }

  getUserWiseAdminConfigurationDetails(userId: string): void {
    this.configService.getUserWiseAdminCOnfigurations(userId).subscribe(data => {
      if (data.length) {

        if (data.find(a => a.title === 'configClientIsAbleToSelectTheEmployeesInBooking')) {
          this.configClientIsAbleToSelectTheEmployeesInBooking = data.find(a => a.title === 'configClientIsAbleToSelectTheEmployeesInBooking').isActive;
        }
        if (data.find(a => a.title === 'configDisableUpdatingEmpAvailability')) {
          this.configDisableUpdatingEmpAvailability = data.find(a => a.title === 'configDisableUpdatingEmpAvailability').isActive;
        }
        if (data.find(a => a.title === 'configClientOverTimeRule')) {
          this.configClientOverTimeRule = data.find(a => a.title === 'configClientOverTimeRule').isActive;
        }
        if (data.find(a => a.title === 'configClientLongBookingPeriod')) {
          this.configClientLongBookingPeriod = data.find(a => a.title === 'configClientLongBookingPeriod').additionalValue1;
        }
        if (data.find(a => a.title === 'configClientEditBookingTime')) {
          this.configClientEditBookingTime = data.find(a => a.title === 'configClientEditBookingTime').isActive;
        }
        if (data.find(a => a.title === 'configClientOverrideLongBookingDateRule')) {
          this.configClientOverrideLongBookingDateRule = data.find(a => a.title === 'configClientOverrideLongBookingDateRule').isActive;
        }
        if (data.find(a => a.title === 'configEmployeeMonthlyMaxWorkingHours')) {
          this.configEmployeeMonthlyMaxWorkingHours = data.find(a => a.title === 'configEmployeeMonthlyMaxWorkingHours').additionalValue1;
        }

        if (data.find(a => a.title === 'configEmployeeBookHimself')) {
          this.configEmployeeBookHimself = data.find(a => a.title === 'configEmployeeBookHimself').isActive;
        }

        if (data.find(a => a.title === 'userRating')) {
          const rating = data.find(a => a.title === 'userRating').additionalValue1;
          this.onclickStar(rating, false);
        }

        this.userForm.patchValue({
          adminConfig1: this.configDisableUpdatingEmpAvailability,
          adminConfig2: this.configClientIsAbleToSelectTheEmployeesInBooking,
          adminConfig3: this.configClientOverTimeRule,
          adminConfig5: this.configClientLongBookingPeriod,
          adminConfig4: this.configClientEditBookingTime,
          adminConfig6: this.configClientOverrideLongBookingDateRule,
          empMaxWorkingHrs: this.configEmployeeMonthlyMaxWorkingHours,
          configEmployeeBookHimself: this.configEmployeeBookHimself
        });
      }
    })
  }

  getEmployeeHourlyRateDetails(userId: string): void {
    this.userService.getEmployeeHourlyRateDetails(userId).subscribe(data => {
      if (data) {
        this.empHourlyRate = data.hourlyRate;
        this.isActiveEmpHourlyRate = data.isActive;
      }
    })
  }

  updateEmployeeHourlyRateDetails(userId: string): void {
    let empHourlyRate = this.userForm.get('empHourlyRate').value;
    let isActiveEmpHourlyRate = this.userForm.get('isActiveEmpHourlyRate').value;

    if (!empHourlyRate) {
      empHourlyRate = 0;
    }
    if (userId == null) {
      userId = this.userForm.get('userId').value
    }
    const request = {
      EmployeeId: userId,
      HourlyRate: empHourlyRate,
      IsActive: isActiveEmpHourlyRate
    }
    this.userService.updateEmployeeHourlyRateDetails(request).subscribe(data => {
      this.setNotificationData('update', true);
    })
  }



  onclickStar(no: any, checked: boolean) {

    if (!checked) {
      this.totalRating = no;
      this.show0 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;

      if (no == 0) {
        this.show0 = true;
      }
      else if (no == 1) {
        this.show1 = true;
      } else if (no == 2) {
        this.show2 = true;
      }
      else if (no == 3) {
        this.show3 = true;
      }
      else if (no == 4) {
        this.show4 = true;
      }
      else if (no == 5) {
        this.show5 = true;
      }
    } else {
      // no = no - 1;
      this.totalRating = no;
      this.show0 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;

      if (no == 1) {
        this.totalRating = 0;
        this.show0 = true;
      } else if (no == 2) {
        this.show2 = true;
      }
      else if (no == 3) {
        this.show3 = true;
      } else if (no == 4) {
        this.show4 = true;
      }
      else if (no == 5) {
        this.show5 = true;
      }
    }
  }

  saveAdminConfigurationDetails(userId: string): void {

    this.configDisableUpdatingEmpAvailability = this.userForm.get('adminConfig1').value;
    this.configClientIsAbleToSelectTheEmployeesInBooking = this.userForm.get('adminConfig2').value;
    this.configClientOverTimeRule = this.userForm.get('adminConfig3').value;
    this.configClientLongBookingPeriod = this.userForm.get('adminConfig5').value;
    this.configClientEditBookingTime = this.userForm.get('adminConfig4').value;
    this.configClientOverrideLongBookingDateRule = this.userForm.get('adminConfig6').value;
    this.configEmployeeMonthlyMaxWorkingHours = this.userForm.get('empMaxWorkingHrs').value;
    this.configEmployeeBookHimself = this.userForm.get('configEmployeeBookHimself').value;


    if (userId == null) {
      userId = this.userForm.get('userId').value
    }

    let request: any[] = [];

    if (this.userInfo.userTypeId != "2") {

      request.push(
        {
          userId: userId, configurationType: 1, title: 'configClientIsAbleToSelectTheEmployeesInBooking', AdditionalValue1: 0, isActive: this.configClientIsAbleToSelectTheEmployeesInBooking
        },
        {
          userId: userId, configurationType: 5, title: 'configClientEditBookingTime', AdditionalValue1: 0, isActive: this.configClientEditBookingTime
        },
        {
          userId: userId, configurationType: 4, title: 'configClientLongBookingPeriod', AdditionalValue1: this.configClientLongBookingPeriod, isActive: true
        },
        {
          userId: userId, configurationType: 3, title: 'configClientOverrideLongBookingDateRule', AdditionalValue1: 0, isActive: this.configClientOverrideLongBookingDateRule
        },
        {
          userId: userId, configurationType: 3, title: 'configClientOverTimeRule', AdditionalValue1: 0, isActive: this.configClientOverTimeRule
        }
      )
    }


    if (this.userInfo.userTypeId == "2") {
      request.push(
        {
          userId: userId, configurationType: 1, title: 'configEmployeeMonthlyMaxWorkingHours', AdditionalValue1: this.configEmployeeMonthlyMaxWorkingHours, isActive: 0
        }
        ,
        {
          userId: userId, configurationType: 2, title: 'configDisableUpdatingEmpAvailability', AdditionalValue1: 0, isActive: this.configDisableUpdatingEmpAvailability
        },
        {
          userId: userId, configurationType: 6, title: 'userRating', AdditionalValue1: this.totalRating, isActive: true
        },
        {
          userId: userId, configurationType: 7, title: 'configEmployeeBookHimself', AdditionalValue1: 0, isActive: this.configEmployeeBookHimself
        }
      )

    }


    this.configService.updateuserwiseadminconfiguration(request).subscribe(data => {
      this.setNotificationData('update', true);

    })
  }

  setNotificationData(flow, status): void {
    if (flow === 'update') {
      if (!this.notificationModal) {
        this.notificationModal = {}
      }
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Uppdatering av konfiguration har lyckats!';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av konfiguration misslyckades! Vänligen försök igen.';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#configNotificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#configNotificationModal') as any).modal('hide');
    }, 1000);
  }

  functionloginAsyncValidator(authService: UserService, time: number = 500) {

    return (input: FormControl) => {
      return timer(time).pipe(
        switchMap(() => authService.checkUsername(input.value, this.updateUserId)),
        map(res => {
          return res ? { loginExist: true } : null;
        })
      );
    };
  }

  functionloginEditAsyncValidator(authService: UserService, time: number = 500) {
    return (input: FormControl) => {
      return timer(time).pipe(
        switchMap(() => authService.checkUsername(input.value, this.updateUserId)),
        map(res => {
          return res ? { loginExist: true } : null;
        })
      );
    };
  }

  createSubClientFormGroup(id: string, value: string, isActive: boolean): FormGroup {
    return this.fb.group({
      id: [id],
      clientName: [value],
      isActive: [isActive]
    });
  }

  addSubClient(selectElement: any) {
    selectElement = $(selectElement);
    let text = selectElement[0].option.clientName;
    let value = selectElement[0].option.value;

    if (value !== undefined) {
      if (this.userInfo.userId !== '0') {
        this.subClients.controls.forEach((el, i) => {
          if (el.value.id == value) {
            this.subClients.removeAt(i);
          }
        });
      }
      this.subClients.push(this.createSubClientFormGroup(value, text, true));
      this.userForm.get('subClients').markAsDirty();
      $("option[value='" + value + "']").remove();
      if (selectElement.has('option').length != 1) {
        //  this.isAllAdded = true;
      }
    } else {
      this.isAllAdded = false;
      this.errorMessage = 'Please select an option from the list';
    }
  }

  removeSubClient(rowIndex: number, selectElement: any) {
    selectElement = $(selectElement);
    let deletedOption = this.subClients.controls[rowIndex].value;
    //  selectElement.append('<option value="' + deletedOption.id + '">' + deletedOption.clientName + '</option>');

    if (this.userInfo.userId === '0') {
      this.subClients.removeAt(rowIndex);
    } else {
      this.subClients.controls[rowIndex].value.isActive = false;
      $('#superclient_' + rowIndex).css('display', 'none');
    }
    this.userForm.get('subClients').markAsDirty();
    this.isAllAdded = false;
  }

  getAllSubClients() {
    this.userService.getNotAllocatedSubClients()
      .subscribe(
        (subClients: any) => {
          if (this.userInfo.userId === '0') {
            this.subClientsArray = subClients;

          } else {
            let inactiveSubClients = [];
            subClients.forEach(element => {
              if (element.isActive === false) {
                inactiveSubClients.push(element);
              }
            });
            this.subClientsArray = inactiveSubClients;
          }
          this.subClientsArray.forEach(item => {
            item.label = item.clientName;
            item.value = item.id;
          });

        },
        (error: any) => this.errorMessage = error as any
      );
  }

  isUserExists(): boolean {
    return this.userForm.get('userName').hasError('loginExist');
  }

  ngAfterViewInit(): void {
  }

  getUser(id: string): void {
    this.userService.getById(id)
      .subscribe(
        (user: IUserInfo) => {
          if (user.countyMunicipalities && user.countyMunicipalities.length > 0) {
            this.userService.getMunicipalitiesByCountyId(user.countyMunicipalities[0].countyId).subscribe((data: []) => {
              this.municipalities = data
              this.municipalities.unshift({ value: 0, code: "0000", label: "Alla Kommuner" })
              for (let [key, value] of Object.entries(this.municipalities)) {
                value['value'] = value.id;
                value['label'] = value.name;
              }
              this.displayUser(user)
            });
          } else {
            this.displayUser(user)
          }

        },
        (error: any) => this.errorMessage = error as any
      );
  }

  displayUser(user: IUserInfo): void {
    if (this.userForm) {
      this.userForm.reset();
    }
    this.userInfo = user;

    if (this.userInfo.userId === '0') {
      this.pageTitle = 'Ange Användarinformation';
    } else {
      this.userImage = this.userInfo.imagePath;
      this.pageTitle = `Redigera Användarinformation: ${this.userInfo.firstName}`;
      const userTypeControl = this.userForm.get('userTypeId');
      userTypeControl.disable();
    }
    if (this.userInfo.cmJson) {
      this.jsonCountyMunicipalities = JSON.parse(this.userInfo.cmJson);
    }

    // Update the data on the form
    this.userForm.patchValue({
      userId: this.userInfo.userId,
      empHourlyRate: this.empHourlyRate,
      empMaxWorkingHrs: this.configEmployeeMonthlyMaxWorkingHours,
      isActiveEmpHourlyRate: this.isActiveEmpHourlyRate,
      adminConfig1: this.configDisableUpdatingEmpAvailability,
      adminConfig2: this.configClientIsAbleToSelectTheEmployeesInBooking,
      adminConfig3: this.configClientOverTimeRule,
      adminConfig4: this.configClientEditBookingTime,
      adminConfig5: this.configClientLongBookingPeriod,
      adminConfig6: this.configClientOverrideLongBookingDateRule,
      userTypeId: this.userInfo.userTypeId,
      jobCategoryId: this.userInfo.jobCategoryId ? this.userInfo.jobCategoryId.toString() : null,
      resourceTypeId: this.userInfo.resourceTypeId ? this.userInfo.resourceTypeId : null,
      firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName,
      clientName: this.userInfo.clientName,
      pharmacyId: this.userInfo.pharmacyId,
      address: this.userInfo.address,
      city: this.userInfo.city,
      zip: this.userInfo.zip,
      email: this.userInfo.email,
      phoneNumber: this.userInfo.phoneNumber,
      isActive: this.userInfo.isActive.toString(),
      userName: this.userInfo.userName
    });

    if (this.userInfo.userTypeId === '4') {
      let subClients = this.userForm.controls['subClients'] as FormArray;
      this.userInfo.subClients.forEach((el: any) => {
        subClients.push(this.createSubClientFormGroup(el.id, el.clientName, el.isActive));
      })
      // this.userForm.patchValue({
      //   pharmacySystems:  this.userInfo.pharmacySystems? this.userInfo.pharmacySystems[0]:'',
      // });
    }

    if (this.userInfo.userTypeId == '2') {
      this.userForm.patchValue({
        county: null,
        municipality: this.userInfo.municipality,
        pharmacySystems: this.userInfo.pharmacySystems,
        legitimationId: this.userInfo.legitimationId,
        experienceAreas: this.userInfo.experienceAreas,
        yearsOfExperience: this.userInfo.yearsOfExperience,
        isStdProceduresRead: this.userInfo.isStdProceduresRead,
        aboutMe: this.userInfo.aboutMe,
        employeeNo: this.userInfo.employeeNo
      });

      this.languageObjArray = this.userInfo.languages ? this.userInfo.languages : [];
      this.countyMunicipalities = this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities : [];

      if (this.userInfo.countyMunicipalities) {
        this.countyMunicipalities.forEach(obj => {
          this.counties.forEach(element => {
            if (element.value == obj.countyId) {
              element.disabled = true
            }
          });

          console.log("x1-",this.counties);
        });
      }
    }

    if (this.userInfo.userTypeId == '3') {

      this.userForm.patchValue({
        county: this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities[0].countyId : '',
        municipality: this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities[0].municipalityId : '',
        pharmacySystems: this.userInfo.pharmacySystems ? this.userInfo.pharmacySystems[0] : '',
        isStaredEmployeesOnly: this.userInfo.isStaredEmployeesOnly,
        favouriteEmployees: this.userInfo.favouriteEmployees
      });

      this.userForm.value.municipality = this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities[0].municipalityId : ''
      this.userForm.value.favouriteEmployees = this.userInfo.favouriteEmployees

    }
  }


  saveUser() {
    if (this.userForm.valid) {
      if (this.userForm.dirty) {
        if (this.userInfo.userTypeId == '2' || this.userForm.get('userTypeId').value == '2') {

          this.userForm.patchValue({
            countyMunicipalities: this.countyMunicipalities,
            cmJson: JSON.stringify(this.jsonCountyMunicipalities),
          })
          this.userForm.value.languages = this.languageObjArray ? this.languageObjArray : []
          this.userForm.value.experienceAreas = this.userForm.value.experienceAreas ? this.userForm.value.experienceAreas : []
          this.userForm.value.pharmacySystems = this.userForm.value.pharmacySystems ? this.userForm.value.pharmacySystems : []


        } else if (this.userInfo.userTypeId == '3' || this.userForm.get('userTypeId').value == '3') {
          if (this.userForm.value.county && this.userForm.value.municipality) {
            this.userForm.patchValue({
              countyMunicipalities: [{ countyId: this.userForm.value.county, municipalityId: this.userForm.value.municipality }],
              // pharmacySystems: [this.userForm.value.pharmacySystems]
            })
          } else {
            this.userForm.patchValue({
              countyMunicipalities: []
            })
          }

          this.userForm.value.favouriteEmployees = this.userForm.value.favouriteEmployees ? this.userForm.value.favouriteEmployees : []
          this.userForm.value.pharmacySystems = this.userForm.value.pharmacySystems ? [this.userForm.value.pharmacySystems] : []

        }
        // else if (this.userInfo.userTypeId == '4' || this.userForm.get('userTypeId').value =='4') {
        //   this.userForm.value.pharmacySystems =this.userForm.value.pharmacySystems? [this.userForm.value.pharmacySystems]:[]
        // }

        const u = { ...this.userInfo, ...this.userForm.value };
        if (u.userId === '0') {
          u.password = this.userForm.get('passwords.password').value;
          u.isActive = true;
          u.userType = this.userTypes.filter((element, index, array) => {
            return element.id === u.userTypeId;
          })[0].name;

          this.userService.createUser(u)
            .subscribe(
              (userId) => {
                this.onSaveComplete('add', true, userId);
              },
              (error: any) => this.errorMessage = error as any
            );
        } else {
          u.image = this.userInfo.image;
          this.userService.updateUser(u)
            .subscribe(
              () => this.onSaveComplete('update', true, u.userId),
              (error: any) => this.errorMessage = error as any
            );
        }
      } else {
        this.onSaveComplete('update', true, this.userForm.get('userId').value);
      }
    } else {
      this.validateAllFormFields(this.userForm)
      this.validateAllFormFields(this.resetPasswordForm)
      this.errorMessage = 'Please correct the validation errors.';
      this.isSubmitInvalid = true
    }
  }

  onSaveComplete(flow: string, status: boolean, userId: string): void {
    this.saveAdminConfigurationDetails(userId);
    this.updateEmployeeHourlyRateDetails(userId);
    if (this.fileData && userId != null) {
      const formData = new FormData();
      formData.append('file', this.fileData);
      formData.append('userId', userId);

      this.userService.uploadUserImage(formData)
        .subscribe(
          (st: boolean) => {
            this.userForm.reset();
            this.router.navigate(['/users'], { state: { flow, status } });
          },
          (error: any) => this.errorMessage = error as any
        );
    } else {
      this.userForm.reset();
      this.router.navigate(['/users'], { state: { flow, status } });
    }
  }

  uploadImageClick(): void {
    $("#fileUploadBtn").click();
  }

  // Cropper Functions
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.cropperVisible = true
    $('#upload-demo').css('display', 'block')
  }
  imageCropped(event: ImageCroppedEvent) {
    // For Preview
    this.croppedImage = event.base64;
    // prepare for upload
    const fileBeforeCrop = this.imageChangedEvent.target.files[0];
    var strImage = this.croppedImage.replace(/^data:image\/[a-z]+;base64,/, "");
    const imageBlob = this.dataURItoBlob(strImage);
    this.fileData = new File([imageBlob], fileBeforeCrop.name, { type: fileBeforeCrop.type }) as File
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  removeImage(): void {
    if (this.fileData) {
      this.fileData = null;
      this.previewUrl = null;
      this.croppedImage = ''
    }
    if (this.userImage) {
      this.userImage = '';
      this.userInfo.image = null;
      this.userInfo.imagePath = null;
    }
    this.cropperVisible = false
    $('#upload-demo').css('display', 'none')
  }
  cancelCrop() {
    this.fileData = null
    this.cropperVisible = false
    $('#upload-demo').css('display', 'none')
  }

  toggleCropper() {
    this.cropperVisible = !this.cropperVisible
    if (this.cropperVisible) {
      $('#upload-demo').css('display', 'block')
    } else {
      $('#upload-demo').css('display', 'none')
    }
  }

  showResetPasswordForm(): void {
    this.isResetPassword = !this.isResetPassword;
  }

  resetPassword(): void {
    const formData = new FormData();
    formData.append('userId', this.updateUserId);
    formData.append('pwd', this.resetPasswordForm.get('resetpassword').value);
    this.userService.resetPassword(formData)
      .subscribe(
        (st: boolean) => {
          this.resetPasswordForm.reset();
          this.router.navigate(['/users'], { state: { flow: 'update', status: st } });
        },
        (error: any) => this.errorMessage = error as any
      );
  }


  onCountyChange(): void {
    if (this.userForm.value.county > 0) {
      this.userForm.patchValue({
        municipality: []
      })
      this.userService.getMunicipalitiesByCountyId(this.userForm.value.county).subscribe((data: []) => {
        this.municipalities = data
        if (this.userForm.get('userTypeId').value == '2') {
          this.municipalities.unshift({ id: 0, code: "0000", name: "Alla Kommuner" })
        }
        for (let [key, value] of Object.entries(this.municipalities)) {
          value['value'] = value.id;
          value['label'] = value.name;
        }
      },
        (error: any) => this.errorMessage = error as any
      )
    }
  }

  handleMunicipalityChange() {
    if (!this.holdMunicipalityChange) {
      this.onMunicipalityChange()
    }
  }

  onMunicipalityChange() {
    let allMunicipalities = null
    if (this.isAllMunicipalitySelected && this.userForm.get('municipality').value.length > 1) {
      this.userForm.get('municipality').value.shift()
      this.isAllMunicipalitySelected = false
    }

    allMunicipalities = this.userForm.value.municipality.find((element) => { return element == '0' })

    if ((allMunicipalities == '0') && (this.userForm.get('municipality').value.length > 1)) {
      this.holdMunicipalityChange = true
      this.userForm.patchValue({
        municipality: [0]
      })
      this.holdMunicipalityChange = false
      this.isAllMunicipalitySelected = true
    }
  }

  onAddCountyMunicipals(): void {
    if (this.isEditCounty) {
      var county = this.selectedCounty.value
    } else {
      var county = this.userForm.value.county
    }

    this.counties.forEach(element => {
      if (element.value == county) {
        element.disabled = true
      }
    });

    var mArray = []
    if (this.countyMunicipalities && this.countyMunicipalities.length > 0) {
      var existingCounty = this.countyMunicipalities.find(e => e.countyId == county)
    }
    if (existingCounty) {
      this.onRemoveCounty(county)
    }

    if ((this.userForm.value.municipality.length > 0) && (this.userForm.value.municipality[0] != '0')) {
      this.userForm.value.municipality.forEach(element => {
        var obj = { countyId: county, municipalityId: element }
        this.countyMunicipalities.push(obj)
        mArray.push(this.municipalities.find(e => e.value == element))
      });
      var cArray: [] = this.counties.find(e => e.value == county)
      var Obj = { c: cArray, m: mArray, isAll: false }
      this.jsonCountyMunicipalities.push(Obj)

    } else {
      this.municipalities.forEach(element => {
        if (element.id != '0') {
          var obj = { countyId: county, municipalityId: element.id }
          this.countyMunicipalities.push(obj)
          mArray.push(this.municipalities.find(e => e.value == element.id))
        }
      });
      var cArray: [] = this.counties.find(e => e.value == county)
      var Obj = { c: cArray, m: mArray, isAll: true }
      this.jsonCountyMunicipalities.push(Obj)
    }
    this.userForm.patchValue({
      county: '',
      municipality: ''
    })
    this.isEditCounty = false;
  }

  onCancelCountyMunicipals() {
    this.userForm.patchValue({
      county: '',
      municipality: ''
    })
    this.isEditCounty = false
    this.isAllMunicipalitySelected = false
  }

  onRemoveCounty(countyId): void {
    var countyObj1 = this.jsonCountyMunicipalities.find(county => county.c.value == countyId)
    var countyObj1Index = this.jsonCountyMunicipalities.indexOf(countyObj1)
    this.jsonCountyMunicipalities.splice(countyObj1Index, 1)

    let objArr = []
    this.countyMunicipalities.forEach(element => {
      if (element.countyId == countyId) {
        objArr.push(element)
      }
    });

    objArr.forEach(ele => {
      let index = this.countyMunicipalities.indexOf(ele)
      this.countyMunicipalities.splice(index, 1);
    })
    // if (!this.isEditCounty) {
    //   this.userForm.patchValue({
    //     county: '',
    //     municipality: ''
    //   })
    // }

    if (!this.isEditCounty) {
      this.counties.forEach(element => {
        if (element.value == countyId) {
          element.disabled = false
        }
      });
    }

    this.isEditCounty = false
    this.isAllMunicipalitySelected = false
  }
  onEditCounty(countyId): void {
    var countyObj1 = this.jsonCountyMunicipalities.find(county => {
      return county.c.value == countyId
    })
    this.selectedCounty = countyObj1.c
    var municipalityArr: any[] = []
    if (countyObj1.isAll) {
      municipalityArr.push(0)
      this.isAllMunicipalitySelected = true
    } else {
      countyObj1.m.forEach(element => {
        municipalityArr.push(element.id)
      });
    }

    this.userService.getMunicipalitiesByCountyId(countyId).subscribe((data: []) => {
      this.municipalities = data
      this.municipalities.unshift({ id: 0, code: "0000", name: "Alla Kommuner" })
      for (let [key, value] of Object.entries(this.municipalities)) {
        value['value'] = value.id;
        value['label'] = value.name;
      }
      this.userForm.patchValue({
        conuty: parseInt(countyId),
        municipality: municipalityArr
      })
      this.userForm.value.conuty = countyId
      this.isEditCounty = true;
    },
      (error: any) => this.errorMessage = error as any
    )
  }

  onAddLangSkills(): void {
    var langId = this.userForm.value.languages
    var skillLevelId = this.userForm.value.skillLevel

    var langObj = this.languageObjArray.find(element => element.languageId == langId)
    if (langObj) {
      this.onRemoveLangSkills(langId)
      var obj = { languageId: langId, skillLevelId: skillLevelId }
      this.languageObjArray.push(obj)
    } else {
      var obj = { languageId: langId, skillLevelId: skillLevelId }
      this.languageObjArray.push(obj)
    }
    this.userForm.patchValue({
      languages: '',
      skillLevel: ''
    })
  }

  onRemoveLangSkills(langId): void {
    var langObj = this.languageObjArray.find(lang => lang.languageId == langId)
    var langObjIndex = this.languageObjArray.indexOf(langObj)
    this.languageObjArray.splice(langObjIndex, 1)
    this.userForm.patchValue({
      languages: '',
      skillLevel: ''
    })
  }

  getLangNameById(langId): string {
    if (this.languages) {
      var langObj = this.languages.find(element => element.value == langId)
    }
    return langObj.label || ''
  }
  getSkillLevelNameById(skillLevelId): string {
    if (this.skillLevels) {
      var skillLevelObj = this.skillLevels.find(element => element.value == skillLevelId)
    }
    return skillLevelObj.label || ''
  }

  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
      console.log(res)
      this.resourceTypes = res.resourceTypes;
      this.matchingAlgorithnmFields = res.matchingAlgorithnmFields;
      if (this.matchingAlgorithnmFields.length > 0) {
        this.matchingAlgorithnmFieldIsConsidered = this.matchingAlgorithnmFields[0] ? this.matchingAlgorithnmFields[0].isConsidered : false;
      }
    })
  }

  getBookingUnit() {
    this.configService.getBookingUnits().subscribe(res => {
      if (res) {
        this.bookingUnitName = (res.bookingUnits.length > 0) ? res.bookingUnits[0].name : '';
        // this.userTypes.push({ id: (this.userTypes.length + 1).toString(), name: 'Client', sename: this.bookingUnitName != '' ? this.bookingUnitName :'Client' });
      }
    });
  }

}
function resetPasswordCompare(c: AbstractControl): { [key: string]: boolean } | null {
  const pwdControl = c.get('resetpassword');
  const conPwdControl = c.get('resetconpassword');

  if (pwdControl.pristine || conPwdControl.pristine) {
    return null;
  }

  if (pwdControl.value !== conPwdControl.value) {
    return { 'match': true };
  }
  return null;
}

function passwordCompare(c: AbstractControl): { [key: string]: boolean } | null {
  const pwdControl = c.get('password');
  const conPwdControl = c.get('conpassword');

  if (pwdControl.pristine || conPwdControl.pristine) {
    return null;
  }

  if (pwdControl.value !== conPwdControl.value) {
    return { 'match': true };
  }
  return null;
}
