import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName, FormControl, AbstractControl } from '@angular/forms';
import * as jQuery from "jquery";
import { CalendarService } from './../calendar.service';
import { AllocationInfo } from './../allocation-info';
import { AvailabilityModel } from './../availability-model';
import { AvailabilityInfo } from './../availability-info';
import { AvailabilityUpdateModel } from './../availability-update-model';
import { TimeLogModel } from './../time-log-model';
import { BookingInfo } from './../booking-info';
import { IUserInfo } from '../../user/user-info';
import { UserService } from '../../user/user.service';
import { GenericValidator } from '../../shared/generic-validator';
import { Observable, merge, fromEvent, Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SwapEmployeeModel } from './../swap-employee-model';
import { PartialAvailabilityModel } from './../partial-availability-model';
import { SignalrService } from 'src/app/shared/services/common/signalr.service';
import SwedishHolidays from 'src/app/shared/constants/swedishHolidays';

declare var kendo: any;

@Component({
  selector: 'wfm-employee-inquiry-calender',
  templateUrl: './employee-inquiry-calender.component.html',
  styleUrls: ['./employee-inquiry-calender.component.css']
})
export class EmployeeInquiryCalenderComponent implements OnInit, AfterViewInit {
  // @Input() selectedUserId: Subject<any>
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  availabilityCheckDates: AvailabilityModel[];
  swedishHolidays : typeof SwedishHolidays;
  errorMessage: string;
  availabilityForm: FormGroup;
  availabilityUpdateModel: AvailabilityUpdateModel;
  allocation: BookingInfo;
  allocationTimeLogsForm: FormGroup;
  visibleTimeLogs = false;
  usersList: IUserInfo[];
  userFilter: any = { fullName: '' };
  userId: string;
  selectedUserFullName: string;
  currentDate = new Date();
  notificationModal: any;
  subscription: Subscription;
  partialAvailabilityForm: FormGroup;
  selectedPartialAvlDate: string;
  selectedCalendarMonth: Date = new Date();
  selectedUserInfo: IUserInfo;
  holidayLastMonth:string
  selectedUserIdSub : Subscription
  newSelectedUserSub : Subscription
  allocationHistory: any
  sendRequest:any;
  isLoadOnSubscription:boolean = false
  // viewStartDate:Date
  // viewEndDate:Date
  deviceWidth:number = window.innerWidth;
  deviceHight:number = window.innerHeight;

  displayValidationMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;


  constructor(private fb: FormBuilder, private calendarService: CalendarService, private userService: UserService,
              private signalRService: SignalrService) {
    this.validationMessages = {
      month: {
        required: 'month is required'
      },
      startDate: {
        required: 'start is required'
      },
      endDate: {
        required: 'end is required'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.subscription = this.signalRService.receiveCalendarUpdates().subscribe(st => {
      if (st) {
        const scheduler = $('#inquiry-scheduler').data('kendoScheduler');
        scheduler.dataSource.read({ userId: this.userId });
        scheduler.refresh();
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.newSelectedUserSub.unsubscribe();
  }

  setCustomVallidations() {
    const month = this.availabilityForm.get('month');
    const start = this.availabilityForm.get('startDate');
    const end = this.availabilityForm.get('endDate');

    this.availabilityForm.get('changeTypeId').valueChanges
      .subscribe(changeType => {
        if (changeType === '1') {
          month.setValidators([Validators.required]);
          start.clearValidators();
          end.clearValidators();
        } else if (changeType === '2') {
          start.setValidators([Validators.required]);
          end.setValidators([Validators.required]);
          month.clearValidators();
        } else if (changeType === '3') {
          start.setValidators([Validators.required]);
          month.clearValidators();
          end.clearValidators();
        }
        month.updateValueAndValidity();
        start.updateValueAndValidity();
        end.updateValueAndValidity();
      });

  }

  ngOnInit() {
    this.swedishHolidays =   SwedishHolidays;
    kendo.culture('sv-SE');
    this.currentDate.setHours(0, 0, 0, 0);
    this.initalizaForms();

    this.allocation = {
      isExpAreaValidationFail:null,
      id: 0,
      title: '',
      sDate: '',
      eDate: '',
      lunch: 0,
      isPastDate: false,
      bookingTotal: 0,
      start: new Date(),
      end: new Date(),
      empName: '',
      empImage: '',
      empJobCategoryId: 0,
      empJobCategory: '',
      clientName: '',
      clientContactName: '',
      clientAddress: '',
      clientPhone: '',
      clientEmail: '',
      isTimeLogged: false,
      timeLogStartTime: '',
      timeLogEndTime: '',
      timeLogLunch: 0,
      timeLogTotal: 0,
      colorClass: 'slot-blue',
      timeLoggedClass: 'slot-no-check',
      empImagePath: '',
      clientImagePath: '',
      resourceTypeId: 1,
      resourceTypeClass: '',
      isInquiry:false,
      clientId:'',
      resourceTypeIconLetter:''
    };

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.userService.getAllocatedUserList('Employee',0).subscribe(
      (res: IUserInfo[]) => {
        this.usersList = res;
      },
      (error: any) => this.errorMessage = error as any
    );

    this.setCustomVallidations();

    this.partialAvailabilityForm.get('avlTypeId').valueChanges
      .subscribe(avlType => {
        if (avlType === '1') {
          $('#kendo-prl-start').data('kendoTimePicker').value('');
          $('#kendo-prl-end').data('kendoTimePicker').value('');
          $('#kendo-prl-start').data('kendoTimePicker').enable(false);
          $('#kendo-prl-end').data('kendoTimePicker').enable(false);
        } else {
          $('#kendo-prl-start').data('kendoTimePicker').enable(true);
          $('#kendo-prl-end').data('kendoTimePicker').enable(true);
        }
      });
    this.selectedUserInfo = this.userService.initializeUser();
    // this.getBookingsCount();
    $(window).resize(()=>{
      const scheduler = $('#inquiry-scheduler').data('kendoScheduler');
      // scheduler.dataSource.read({ userId: this.userId});
      scheduler.refresh();
    })

    // this.selectedUserIdSub = this.selectedUserId.subscribe((res)=>{
    //   this.userId = res.id
    //   if (res && res.id && res.type) {
    //     const scheduler = $('#inquiry-scheduler').data('kendoScheduler');
    //     scheduler.dataSource.read({ userId: this.userId });
    //     scheduler.refresh();
    //   }
    // })

    this.newSelectedUserSub = this.userService.newSelectedUser.subscribe((data)=>{
      if (this.isLoadOnSubscription) {
        setTimeout(() => {
          this.loadCalendar(data);
        }, 100);
      }else if(data && data.userId){
        this.userId =data.userId
      }
    })

    // var date = new Date();
    // this.viewStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
    // this.viewEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  loadCalendar(data: any): void {
    if (data == null || this.userId === data.userId) {
      this.userId = 'all';
      this.selectedUserFullName = null;
      $('.employee-wrapper').removeClass('employee-wrapper-active');
      $('.k-scheduler-table td').removeClass('cell-celected');
      // this.selectedResourceTypeId = '1';

    } else {
      this.userId = data.userId;
      this.selectedUserFullName = data.fullName;
      // this.userType = data.userType;
    }
    // this.userId = data.userId;
    const scheduler = $('#inquiry-scheduler').data('kendoScheduler');
    scheduler.dataSource.read({ userId: this.userId});
    // scheduler.refresh();
  }



  initalizaForms(): void {
    this.availabilityForm = this.fb.group({
      changeTypeId: ['1'],
      month: ['', Validators.required],
      startDate: [''],
      endDate: [''],
      availabilityId: ['1']
    });
    this.allocationTimeLogsForm = this.fb.group({
      allocationId: [],
      isTimeLogged: [],
      startTime: [],
      endTime: [],
      lunchTime: []
    });

    this.partialAvailabilityForm = this.fb.group({
      avlTypeId: [],
      avlDate: [],
      avlStart: [],
      avlEnd: []
    });
  }


  ngAfterViewInit(): void {
    const calendarService = this.calendarService;
    let allocationList = [] as BookingInfo[];
    this.initializeKendoComponents();


    let userId = this.userId;
    // let viewStartDate = this.viewStartDate
    // let viewEndDate = this.viewEndDate
    // let patentThis = this

    const customAgenda = kendo.ui.AgendaView.extend({
      endDate() {
        const date = kendo.ui.AgendaView.fn.startDate.call(this);
        return kendo.date.addDays(date, 30);
      }
    });

    $('.mob-bookings').removeClass('btn-success');
    $('.mob-bookings').addClass('tab-inactive');

    var eventHeight : number
    var eventsPerDay = 2
    var isAdaptiveSlotHeight:boolean
    if (this.deviceWidth < 576) {
      eventHeight = 60
      isAdaptiveSlotHeight = true
    } else if (this.deviceWidth < 768) {
      eventHeight = 43
      isAdaptiveSlotHeight = true
    } else if (this.deviceWidth < 992) {
      eventHeight = 43
      isAdaptiveSlotHeight = false
    } else if (this.deviceWidth < 1200) {
      eventHeight = 31
      isAdaptiveSlotHeight = false
    } else {
      isAdaptiveSlotHeight = false
      eventHeight = 30
    }

    ($('#inquiry-scheduler') as any).kendoScheduler({
     views: [
        {
          type: 'month',
          eventsPerDay:eventsPerDay,
          eventHeight: eventHeight,
          adaptiveSlotHeight: isAdaptiveSlotHeight,
        },
        { type: 'day', allDaySlot: false, eventTemplate: $('#event-day-template').html() },
        { type: customAgenda, title: 'Agenda', eventTemplate: $('#event-template').html() ,
          selectedDateFormat: "{0:dd/MM/yyyy} - {1:dd/MM/yyyy}",
          selectedShortDateFormat: "{0:dd/MM} - {1:dd/MM}"}
      ],
      eventTemplate: ($('#event-slot-template') as any).html(),
      dateHeaderTemplate: kendo.template("<strong>#=kendo.toString(date, 'ddd dd/M')#</strong>"),
      timezone: "Europe/Stockholm",
      selectable: false,
      editable: {
        destroy: false,
        move: false,
        resize: false,
        create: false
      },
      dataSource:
      {
        transport: {
          read(o) {
            let loadData = false;

            // if (!o.data.userId && !userId) {
            //   loadData = true;
            //   userId = o.data.userId ? o.data.userId : '';
            // } else if (o.data.userId) {
            //   loadData = true;
            //   userId = o.data.userId;
            // }
            if (!o.data.userId && !userId) {
                loadData = true;
                userId = '';
            } else if (o.data.userId && !userId) {
                loadData = true;
                userId = o.data.userId;
            } else if (o.data.userId && userId ){
              loadData = true;
              userId = o.data.userId;
            } else if (!o.data.userId && userId ){
              loadData = true;
            } 

            // viewStartDate = o.data.viewStartDate? o.data.viewStartDate : viewStartDate
            // viewEndDate = o.data.viewEndDate? o.data.viewEndDate : viewEndDate
            if (loadData) {
              calendarService.getEmployeeInquiryList(userId)
                .subscribe(
                  (allocations: BookingInfo[]) => {
                    allocationList = allocations;
                    o.success(allocations);
                  },
                  (error: any) => this.errorMessage = error as any
                );
            } else {
              o.success([]);
            }
          }
        }
      },
      toolbar: ['pdf'],
      pdf: {
        fileName: 'Bookings-Export.pdf',
      }
    });

    const scheduler = $('#inquiry-scheduler').data('kendoScheduler');

    scheduler.wrapper.on('mouseup', '.k-scheduler-table td, .k-event', function (e) {
      const target = $(e.currentTarget);
      if (target.hasClass('k-event')) {
        const event = scheduler.occurrenceByUid(target.data('uid'));
        scheduler.editEvent(event);
      }
    });

    scheduler.bind('edit', (event) => {
      event.preventDefault();
      this.schedulerEdit(event);
    });

    scheduler.bind('dataBound', (e) => {
      e.preventDefault();
      this.schedulerDataBound(scheduler);
      setTimeout(() => {
        $('.k-scheduler-navigation').css('pointer-events', 'all');
        $('.k-scheduler-navigation').css('opacity', '1');
        $('.k-state-default.k-header.k-nav-prev').removeClass('k-state-hover');
        $('.k-state-default.k-header.k-nav-next').removeClass('k-state-hover');
      }, 500);
    });

    scheduler.bind('change', (e) => {
      e.preventDefault();
      if (e.events.length === 0) {
        //scheduler.select(null);
      }
    });

    scheduler.bind('navigate', (e) => {
      // this.setViewDateRange(e)
      
      if (e.action=='next') {
          $('.k-state-default.k-header.k-nav-next').addClass('k-state-hover');
      }
      if (e.action=='previous') {
          $('.k-state-default.k-header.k-nav-prev').addClass('k-state-hover');
      }

      this.selectedCalendarMonth = e.date;
      // this.getBookingsCount();
      if (e.view === 'day') {
        e.sender.select(null);
        setTimeout(() => {
          this.scrollToHour(8);
        }, 200);
      }
    });

    scheduler.element.on('dblclick', '.k-scheduler-content td', (e) => {
      e.preventDefault()
    });

    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.availabilityForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(200)
    ).subscribe(value => {
      this.displayValidationMessage = this.genericValidator.processMessages(this.availabilityForm);
    });



    $('.k-scheduler-tools').hide();
      $('#inquiry-scheduler .k-scheduler-views').append('<li class="k-state-default  k-exportpdf" data-name="Export"><a role="button" href="#" class="k-link mobile-export">Skriv ut</a></li>')
    $('#inquiry-scheduler .mobile-export').click(()=>{
      $('#inquiry-scheduler .k-pdf').click();
    });
    if (kendo.support.mobileOS) {
      $('.k-sm-date-format').css('font-size','100%');
    }
    
    $('#employeeInquiryModal').on('hide.bs.modal', () => {
      $('.collapse').removeClass('show');
      this.sendRequest = false;
    }) 
    this.isLoadOnSubscription = true;
  }



  initializeKendoComponents(): void {
    ($('.employee-inquiry #kendo-start-date') as any).kendoDatePicker({
      format: 'yyyy-MM-dd',
      min: new Date(),
      change: (val) => {
        this.availabilityForm.patchValue({
          startDate: val.sender._value
        });
      }
    });

    ($('.employee-inquiry #kendo-end-date') as any).kendoDatePicker({
      format: 'yyyy-MM-dd',
      min: new Date(),
      change: (val) => {
        this.availabilityForm.patchValue({
          endDate: val.sender._value
        });
      }
    });
    ($('.employee-inquiry  #kendo-month') as any).kendoDatePicker({
      format: 'MMMM yyyy',
      min: new Date(),
      start: 'year',
      depth: 'year',
      change: (val) => {
        this.availabilityForm.patchValue({
          month: val.sender._value
        });
      }
    });

    ($('.employee-inquiry .kendo-picker') as any).bind('focus', function () {
      $(this).data('kendoDatePicker').open();
    });

    // ($('.kendo-time') as any).bind('focus', function () {
    //   $(this).data('kendoTimePicker').open();
    // });
  }

  schedulerDataBound(scheduler): void {
    // const kscheduler = $('#inquiry-scheduler').data('kendoScheduler');
    const schView = scheduler.view().name;

    if (schView === 'month') {

      $('.k-scheduler-navigation').css('pointer-events', 'none');
      $('.k-scheduler-navigation').css('opacity', '0.5');

      const container = scheduler.view().element;
      const cells = container.find('td[role=gridcell]');

      $('.slot-orange').parent().addClass('dayview-slot-orange');
      $('.slot-yellow').parent().addClass('dayview-slot-yellow');
      $('.slot-blue').parent().addClass('dayview-slot-blue');
      $('.slot-yellow .font-weight-bold').css('border-color','red');

      this.availabilityCheckDates = [];
      for (let i = 0; i < cells.length; i++) {
        const cell = $(cells[i]);
        const slot = scheduler.slotByElement(cell);
        const avl = {} as AvailabilityModel;
        avl.category = scheduler.view().name;
        avl.startTime = slot.startDate;
        avl.endTime = slot.endDate;
        avl.startTimeString = avl.startTime.getFullYear() + '-' + (avl.startTime.getMonth() + 1) + '-' + avl.startTime.getDate();
        avl.endTimeString = avl.endTime.getFullYear() + '-' + (avl.endTime.getMonth() + 1) + '-' + avl.endTime.getDate();
        this.availabilityCheckDates.push(avl);
      }

      this.calendarService.checkEmployeeAvailability(this.availabilityCheckDates).subscribe(
        (avlData: AvailabilityInfo[]) => {
          for (let i = 0; i < cells.length; i++) {
            const cell = $(cells[i]);
              const slot = scheduler.slotByElement(cell);
              if (slot) {
                  const slotStartDate = slot.startDate as Date;
                  const slotEndDate = slot.endDate as Date;
                  const filterData = avlData.find(function (data) {
                      return (data.startTime.toDateString() === slotStartDate.toDateString()
                          && data.endTime.toDateString() === slotEndDate.toDateString());
                  });
                  if (filterData) {
                      // Mark Sundays & saturdays with different color using CSS class
                      if (slotStartDate.getDay() == 0) {
                          cell.addClass('cell-sunday')
                      }
                      if (slotStartDate.getDay() == 6) {
                          cell.addClass('cell-saturday')
                      }

                      if (filterData && filterData.startTime >= this.currentDate) {
                          if (filterData.isAvailable) {
                              cell.css('background-image', 'linear-gradient(225deg, #85BD3C, #85BD3C 30px, transparent 0px, transparent)');
                              cell.css('position', 'relative');

                              if (!filterData.hasAllocations) {
                                  if (filterData.isPartial) {
                                      cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>'); 
                                      // Button removed on FB132 : <button type="button" class="avlful btnpartial" data-type="2" data-sdate="' + slotStartDate.toDateString() + '">' + filterData.partialFrom + '-' + filterData.partialTo + '</button>'
                                  } else {
                                      cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>'); 
                                      // Full day Button removed on FB132 : <button type="button" class="avlful btnpartial" data-type="1" data-sdate="' + slotStartDate.toDateString() + '">Full Day</button>
                                  }
                              } else {
                                  cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                              }
                          } else {
                              cell.css('background-image', 'linear-gradient(225deg, #FF6161, #FF6161 30px, transparent 0px, transparent)');
                              cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                          }
                      } else {
                          cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                          cell.css('background-image', 'linear-gradient(225deg, #d1d1d1, #d1d1d1 30px, transparent 0px, transparent)');
                      }

                      var holidayArr: any[];
                      var slotDateJsonFormat = this.getJsonDateFormat(slot.startDate);
                      if (this.holidayLastMonth !== slotDateJsonFormat) {
                          this.holidayLastMonth = slotDateJsonFormat
                          holidayArr = this.swedishHolidays[slotDateJsonFormat]
                      }
                      if (holidayArr) {
                          var holidayObj = holidayArr.find(e => e.date == slot.startDate.getDate())
                          if (holidayObj) {
                              cell.addClass('holiday')
                              cell.append(`<a class="d-lg-block d-xl-block d-md-block badge badge-info holiday-name mobile-hide " style="color:white;margin-top: 2px;" >${holidayObj.holiday}</a >`);
                          }
                      }

                      // Check for monthname
                      var isFirstDay: boolean
                      if (slotStartDate.getDate() == 1) {
                          isFirstDay = true
                          var month = slotStartDate.toLocaleString('sv-SE', { month: 'long' });
                          var monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1)
                      } else {
                          isFirstDay = false
                      }

                      // Display month first day label if not holiday
                      if (!holidayObj && isFirstDay) {
                          if (cell.children('.badge-info.holiday-name').length == 0) {
                              cell.append(`<a class=" d-lg-block d-xl-block d-md-block  badge badge-info holiday-name mobile-hide" style="color:white;margin-top: 2px;" >Första ${monthCapitalized}</a>`);
                          }
                      }

                      // Dispaly week number for every monday
                      if (slotStartDate.getDay() == 1) {
                          var weekNoArr = this.calendarService.getWeekNumber(slotStartDate)
                          cell.append(`<span class="badge badge-pill week-number mobile-hide" >V.${weekNoArr[1]}</span >`);
                      }
                  }
              }
          }
          $('.avlful').on('click', (e) => {
            const sDate = $(e.target).data('sdate');
            // this.partialAvailabilityClick(sDate);
          });
        },
        (error: any) => this.errorMessage = error as any
      );

    } if (schView === 'day') {
      $('.slot-orange.day-view').parent().addClass('dayview-slot-orange');
      $('.slot-yellow.day-view').parent().addClass('dayview-slot-yellow');
      $('.slot-blue.day-view').parent().addClass('dayview-slot-blue');
    }
    $('.k-scheduler-navigation').css('pointer-events', 'all');
    $('.k-scheduler-navigation').css('opacity', '1');

    if (this.deviceWidth < 768) {
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(1)').text('Mån');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(2)').text('Tis');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(3)').text('Ons');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(4)').text('Tor');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(5)').text('Fre');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(6)').text('Lör');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(7)').text('Sön');
  }
  }

  getJsonDateFormat(date:Date):string{
    return date.getFullYear()+''+(date.getMonth()+1>9? date.getMonth()+1:'0'+(date.getMonth()+1))
  }

  schedulerEdit(event): void {
    if (event.event.id != 0) {
      if (event.event.isTimeLogged) {
        this.allocationTimeLogsForm.patchValue({
          allocationId: event.event.id,
          isTimeLogged: event.event.isTimeLogged,
          startTime: event.event.timeLogStartTime,
          endTime: event.event.timeLogEndTime,
          lunchTime: event.event.timeLogLunch
        });
      } else {
        this.allocationTimeLogsForm.patchValue({
          allocationId: event.event.id,
          isTimeLogged: event.event.isTimeLogged,
          startTime: event.event.start,
          endTime: event.event.end,
          lunchTime: event.event.lunch
        });
        // $('#kendo-tl-start').data('kendoTimePicker').value(event.event.start);
        // $('#kendo-tl-end').data('kendoTimePicker').value(event.event.end);
      }

      this.allocation = event.event;
      if (this.allocation.start <= new Date()) {
        this.visibleTimeLogs = (event.event.isTimeLogged ? false : true);
      } else {
        this.visibleTimeLogs = false;
      }
      ($('#employeeInquiryModal') as any).modal('show');
    }
  }

  setNotificationData(flow, status): void {
    if (flow === 'availability') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tillgänglighet uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    if (flow === 'timelog') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tidrapportering uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tidrapportering misslyckades! Var god och försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    if (flow === 'partialavl') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tillgänglighet uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    if (flow === 'assign') {
      if (status) {
        this.notificationModal.header = 'Lyckades';// Success
        this.notificationModal.description = 'Passet tilldelades till dig.'; //'Successfully assigned to you!';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades'; //'Failed';
        this.notificationModal.description = 'Tilldelning av förfrågan misslyckades. Vänligen försök igen.'; // 'Inquiry assigning failed! Please try again.';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#notificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#notificationModal') as any).modal('hide');
    }, 3000);
  }


  scrollToHour(hour): void {
    const time = new Date();
    time.setHours(hour);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);

    const scheduler = $('#inquiry-scheduler').data('kendoScheduler');
    const contentDiv = scheduler.element.find('div.k-scheduler-content');
    const rows = contentDiv.find('tr');

    for (let i = 0; i < rows.length; i++) {
      const element = $(rows[i]);
      const slot = scheduler.slotByElement(element);

      const slotTime = kendo.toString(slot.startDate, 'HH:mm');
      const targetTime = kendo.toString(time, 'HH:mm');

      if (targetTime === slotTime) {
        contentDiv.scrollTop(0);

        const elementTop = element.offset().top;
        const containerTop = contentDiv.offset().top;

        contentDiv.scrollTop(elementTop - containerTop);
      }
    }
  }

  onAcceptInquiry(allocationId:number): void {
    this.calendarService.assignInquiryEmployee(allocationId).subscribe(
      (status: boolean) => {
        ($('#employeeInquiryModal') as any).modal('hide');
        this.setNotificationData('assign', status);
        const scheduler = $('#inquiry-scheduler').data('kendoScheduler');
        scheduler.dataSource.read({ userId: this.userId});
        scheduler.refresh();
        this.signalRService.broadcastCalendarUpdates();
        const schedulerBooking = $('#scheduler').data('kendoScheduler');
        schedulerBooking.dataSource.read({ userId: this.userId });
      },
      (error: any) => this.errorMessage = error as any
    )

  }
  onViewHistoryBtnClick(allocationId: number) {
    this.toggleSendRequest();
    if (this.sendRequest) {
      this.calendarService.getAllocationHistory(allocationId).subscribe(
        (res) => {
            this.allocationHistory = res;
        },
        (error: any) => this.errorMessage = error as any
    );
    }
    
  }
  toggleSendRequest(){
    this.sendRequest = !this.sendRequest
  }


}
