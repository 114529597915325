import { Component, OnInit, Input } from '@angular/core';
import { IUserInfo } from './user-info';
import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { ConfigService } from '../shared/services/config.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'wfm-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit {

  @Input('employeeNo') employeeProfileId: Subject<string>
  @Input() isViewModal: boolean = false

  pageTitle: string;
  errorMessage: string;
  userTypes: any[];
  jobTypes: any[];
  userInfo: IUserInfo = {} as IUserInfo;
  previewUrl: any;
  userImage = '';
  fileData: File = null;
  private sub: Subscription;
  userForm: FormGroup;
  subClients: FormArray;
  jsonCountyMunicipalities: any[] = [];
  countyMunicipalities: any[] = [];
  counties: any[] = [];
  municipalities: any[] = [];
  pharmacySystems: any[] = [];
  legitimations: any[] = [];
  experienceAreas: any[] = [];
  usersList: IUserInfo[] = [];
  selectedEmployeeId: string
  skillLevels: any[] = [];
  empResourceType: any
  resourceTypes: any = [];

  configDisableExperienceAreas: boolean;
  configDisableUpdatingEmpAvailability: boolean;
  configClientIsAbleToSelectTheEmployeesInBooking: boolean;
  configClientOverTimeRule: boolean;
  configClientLongBookingPeriod: any;
  notificationModal: any;
  configClientEditBookingTime: boolean;
  empHourlyRate: number;
  applyHourlyRate: boolean;
  deviceWidth: number = window.innerWidth;
  deviceHight: number = window.innerHeight;
  rating: any;
  show0: boolean = true;
  show1: boolean;
  show2: boolean;
  show3: boolean;
  show4: boolean;
  show5: boolean;
  totalRating: number = 0;

  languages: any[] = [
    { value: 1, label: 'English' },
    { value: 2, label: 'Swedish' }
  ];
  yearsOfExperiences: any[] = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '10+' },
  ];

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private userService: UserService) {

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.userTypes = [
      { id: '2', name: 'Anställd' },
      { id: '3', name: 'Enhet' },
      { id: '4', name: 'Super Client' }
    ];

    this.jobTypes = [
      { id: '1', name: 'Heltid' },
      { id: '2', name: 'Deltid' }
    ];
  }

  ngOnInit() {
    this.subClients = this.fb.array([]);

    this.userForm = this.fb.group({
      userId: [''],
      userTypeId: [],
      jobCategoryId: [],
      firstName: [''],
      lastName: [],
      clientName: [],
      pharmacyId: [],
      address: [],
      city: [],
      zip: [],
      email: [],
      phoneNumber: [],
      userName: [],
      password: [],
      conpassword: [],
      isActive: [true],
      subClients: this.subClients,
      county: [{ disabled: true }, []],
      municipality: [[]],
      pharmacySystems: [],
      isStaredEmployeesOnly: [Boolean],
      employeeNo: [''],
      countyMunicipalities: [[]],
      legitimationId: [],
      experienceAreas: [],
      yearsOfExperience: [],
      isStdProceduresRead: [Boolean],
      aboutMe: [''],
      languages: [],
      cmJson: '',
      favouriteEmployees: []
    });

    if (this.isViewModal) {
      this.employeeProfileId.subscribe((userId) => {
        this.selectedEmployeeId = userId
        if (userId) {
          this.getUser(this.selectedEmployeeId);
          this.getUserWiseAdminConfigurationDetails(this.selectedEmployeeId);
          this.getEmployeeHourlyRateDetails(this.selectedEmployeeId);
          ($('#employeeProfileViewModal') as any).modal('show');

        }
      })
    } else {
      this.sub = this.route.paramMap.subscribe(
        params => {
          const id = params.get('userid');
          this.getUser(id);
        }
      );
    }

    this.userService.getInnitialMasterData().subscribe((data) => {

      this.pharmacySystems = data.pharmacySystems
      this.counties = data.counties
      this.experienceAreas = data.experienceAreas
      this.legitimations = data.legitimations
      this.skillLevels = data.skillLevels
      this.languages = data.languages

      for (let [key, value] of Object.entries(this.pharmacySystems)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.counties)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.experienceAreas)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.legitimations)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.skillLevels)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.languages)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
    })
    this.getCurrentConfigData();

  }

  getEmployeeHourlyRateDetails(userId: string): void {
    this.userService.getEmployeeHourlyRateDetails(userId).subscribe(data => {
      if (data) {
        this.applyHourlyRate = data.isActive;
        this.empHourlyRate = data.hourlyRate;
      }
    })
  }


  createSubClientFormGroup(id: string, value: string, isActive: boolean): FormGroup {
    return this.fb.group({
      id: [id],
      clientName: [value],
      isActive: [isActive]
    });
  }

  getUserWiseAdminConfigurationDetails(userId: string): void {
    this.configService.getUserWiseAdminCOnfigurations(userId).subscribe(data => {
      if (data.length) {
        if (data.find(a => a.title === 'configClientIsAbleToSelectTheEmployeesInBooking')) {
          this.configClientIsAbleToSelectTheEmployeesInBooking = data.find(a => a.title === 'configClientIsAbleToSelectTheEmployeesInBooking').isActive;
        }
        if (data.find(a => a.title === 'configDisableUpdatingEmpAvailability')) {
          this.configDisableUpdatingEmpAvailability = data.find(a => a.title === 'configDisableUpdatingEmpAvailability').isActive;
        }
        if (data.find(a => a.title === 'configClientOverTimeRule')) {
          this.configClientOverTimeRule = data.find(a => a.title === 'configClientOverTimeRule').isActive;
        }
        if (data.find(a => a.title === 'configClientLongBookingPeriod')) {
          this.configClientLongBookingPeriod = data.find(a => a.title === 'configClientLongBookingPeriod').additionalValue1;
        }
        if (data.find(a => a.title === 'configClientEditBookingTime')) {
          this.configClientEditBookingTime = data.find(a => a.title === 'configClientEditBookingTime').isActive;
        }

        if (data.find(a => a.title === 'userRating')) {
          this.rating = data.find(a => a.title === 'userRating').additionalValue1;
          this.onclickStar(this.rating, false);
        }

        this.userForm.value.adminConfig1 = this.configDisableUpdatingEmpAvailability

        this.userForm.patchValue({
          adminConfig1: this.configDisableUpdatingEmpAvailability,
          adminConfig2: this.configClientIsAbleToSelectTheEmployeesInBooking,
          adminConfig3: this.configClientOverTimeRule,
        });
      }
    })
  }

  onclickStar(no: any, checked: boolean) {
    if (!checked) {
      this.totalRating = no;
      this.show0 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;

      if (no == 0) {
        this.show0 = true;
      }
      else if (no == 1) {
        this.show1 = true;
      } else if (no == 2) {
        this.show2 = true;
      }
      else if (no == 3) {
        this.show3 = true;
      }
      else if (no == 4) {
        this.show4 = true;
      }
      else if (no == 5) {
        this.show5 = true;
      }
    } else {
      // no = no - 1;
      this.totalRating = no;
      this.show0 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;

      if (no == 1) {
        this.totalRating = 0;
        this.show0 = true;
      } else if (no == 2) {
        this.show2 = true;
      }
      else if (no == 3) {
        this.show3 = true;
      } else if (no == 4) {
        this.show4 = true;
      }
      else if (no == 5) {
        this.show5 = true;
      }
    }
  }

  getUser(id: string): void {
    this.userService.getById(id)
      .subscribe(
        (user: IUserInfo) => {
          if (user.countyMunicipalities && user.countyMunicipalities.length > 0) {
            this.userService.getMunicipalitiesByCountyId(user.countyMunicipalities[0].countyId).subscribe((data: []) => {
              this.municipalities = data
              for (let [key, value] of Object.entries(this.municipalities)) {
                value['value'] = value.id;
                value['label'] = value.name;
              }
              this.displayUser(user)
            });
          } else {
            this.displayUser(user)
          }

        },
        (error: any) => this.errorMessage = error as any
      );
  }

  displayUser(user: IUserInfo): void {
    this.userInfo = user;

    this.userImage = this.userInfo.imagePath;
    this.pageTitle = `Edit User Data: ${this.userInfo.firstName}`;
    const userTypeControl = this.userForm.get('userTypeId');

    if (this.userInfo.cmJson) {
      this.jsonCountyMunicipalities = JSON.parse(this.userInfo.cmJson)
    }

    this.userForm.patchValue({
      userId: this.userInfo.userId,
      userTypeId: this.userInfo.userTypeId,
      jobCategoryId: this.userInfo.jobCategoryId ? this.userInfo.jobCategoryId.toString() : null,
      firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName,
      clientName: this.userInfo.clientName,
      pharmacyId: this.userInfo.pharmacyId,
      address: this.userInfo.address,
      city: this.userInfo.city,
      zip: this.userInfo.zip,
      email: this.userInfo.email,
      phoneNumber: this.userInfo.phoneNumber,
      isActive: this.userInfo.isActive.toString()
    });

    if (this.userInfo.userTypeId === '4') {
      let subClients = this.userForm.controls['subClients'] as FormArray;
      this.userInfo.subClients.forEach((el: any) => {
        subClients.push(this.createSubClientFormGroup(el.id, el.clientName, el.isActive));
      })
    }

    if (this.userInfo.userTypeId == '2') {
      this.userForm.patchValue({
        county: (this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities.length > 0) ? this.userInfo.countyMunicipalities[0].countyId : null,
        municipality: (this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities.length > 0) ? this.userInfo.countyMunicipalities[0].municipalityId : null,
        pharmacySystems: this.userInfo.pharmacySystems,
        legitimationId: this.userInfo.legitimationId,
        experienceAreas: this.userInfo.experienceAreas,
        yearsOfExperience: this.userInfo.yearsOfExperience,
        isStdProceduresRead: this.userInfo.isStdProceduresRead,
        aboutMe: this.userInfo.aboutMe,
        languages: this.userInfo.languages,
        employeeNo: this.userInfo.employeeNo
      });
      this.countyMunicipalities = this.userInfo.countyMunicipalities
      this.empResourceType = this.resourceTypes.find(x => x.id == this.userInfo.resourceTypeId);
    }

    if (this.userInfo.userTypeId == '3') {

      this.userService.getUserList('Employee').subscribe(
        (res: IUserInfo[]) => {
          this.usersList = res;
          for (let [key, value] of Object.entries(this.usersList)) {
            value['value'] = value.userId;
            value['label'] = value.firstName + ' ' + value.lastName;
          }
          this.userForm.patchValue({
            county: this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities[0].countyId : '',
            municipality: this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities[0].municipalityId : '',
            pharmacySystems: this.userInfo.pharmacySystems,
            isStaredEmployeesOnly: this.userInfo.isStaredEmployeesOnly,
            aboutMe: this.userInfo.aboutMe,
          });

          this.userForm.value.municipality = this.userInfo.countyMunicipalities ? this.userInfo.countyMunicipalities[0].municipalityId : ''
          this.userForm.value.favouriteEmployees = this.userInfo.favouriteEmployees
        },
        (error: any) => this.errorMessage = error as any
      );

    }


  }

  getLeditimationName() {
    if (this.legitimations) {
      var element = this.legitimations.find((e) => e.value == this.userForm.value.legitimationId)
    }
    return element ? element.label : '-'
  }
  getExperience() {
    if (this.yearsOfExperiences) {
      var element = this.yearsOfExperiences.find((e) => e.value == this.userForm.value.yearsOfExperience)
    }
    return element ? element.label : '-'
  }
  getExperienceAreas() {
    if (this.userInfo.experienceAreas) {
      let str: string = '';
      this.userInfo.experienceAreas.forEach(element => {
        let obj = this.experienceAreas.find((e) => e.value == element)
        str = str + obj.label + ', '
      });
      var newStr = str.slice(0, -2);
      return newStr
    }
  }
  getLanguageById(langId: number) {
    if (this.languages) {
      var element = this.languages.find((e) => e.value == langId)
      return element ? element.label : '-'
    }
  }
  getSkillLevelById(skillLevelId: number) {
    if (this.skillLevels) {
      var element = this.skillLevels.find((e) => e.value == skillLevelId)
    }
    return element ? element.label : '-'
  }
  getPharmacySystems() {
    let str: string = '';
    if (this.userInfo.pharmacySystems) {
      this.userInfo.pharmacySystems.forEach(element => {
        let obj = this.pharmacySystems.find((e) => e.value == element)
        if (obj) {
          str = str + obj.label + ', '
        }
      });
      var newStr = str.slice(0, -2);
      return newStr || '-'
    }
  }
  getUserType(id) {
    if (this.userTypes) {

    }
    var element = this.userTypes.find((e) => e.id == id)
    return element ? element.label : '-'
  }
  getJobType() {
    var element = this.jobTypes.find((e) => e.id == this.userForm.value.jobCategoryId)
    return element ? element.label : '-'
  }
  getCountyName() {
    var element = this.counties.find((e) => e.value == this.userForm.value.county)
    return element ? element.label : '-'
  }
  getMunicipalityName() {
    var element = this.municipalities.find((e) => e.value == this.userForm.value.municipality)
    return element ? element.label : '-'
  }
  getFavouriteEmployees() {
    let str: string = '';
    let newStr: string = '';
    if (this.userInfo.favouriteEmployees) {
      this.userInfo.favouriteEmployees.forEach(element => {
        let obj = this.usersList.find((e) => e.userId == element)
        if (obj) {
          str = str + obj.fullName + ', '
        }
      });
    }
    newStr = str.slice(0, -2);
    return newStr || '-'
  }
  getMunicipalities(mArray) {
    let str: string = '';
    let newStr: string = '';
    if (mArray) {
      mArray.forEach(element => {
        str += element.name + ', '
      });
    }
    newStr = str.slice(0, -2);
    return newStr || '-'
  }
  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
      // console.log(res);
      this.resourceTypes = res.resourceTypes

    })
  }

  saveAdminConfigurationDetails(): void {
    let request: any[] = [];
    request = [
      {
        title: 'configDisableExperienceAreas', isActive: this.configDisableExperienceAreas
      },
      {
        title: 'configDisableUpdatingEmpAvailability', isActive: this.configDisableUpdatingEmpAvailability
      },
      {
        title: 'configClientIsAbleToSelectTheEmployeesInBooking', isActive: this.configClientIsAbleToSelectTheEmployeesInBooking
      }
    ];
    this.configService.updateAdminCOnfigurations(request).subscribe(data => {
      this.setNotificationData('update', true);

    })
  }

  getAdminConfigurationDetails(): void {
    this.configService.getAdminCOnfigurations().subscribe(data => {
      if (data) {
        this.configDisableExperienceAreas = data.find(a => a.title === 'configDisableExperienceAreas').isActive;
        this.configDisableUpdatingEmpAvailability = data.find(a => a.title === 'configDisableUpdatingEmpAvailability').isActive;
        this.configClientIsAbleToSelectTheEmployeesInBooking = data.find(a => a.title === 'configClientIsAbleToSelectTheEmployeesInBooking').isActive;

      }
    })
  }

  setNotificationData(flow, status): void {
    if (flow === 'update') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Uppdatering av konfiguration har lyckats!';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av konfiguration misslyckades! Vänligen försök igen.';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#configNotificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#configNotificationModal') as any).modal('hide');
    }, 1000);
  }
}
