import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators, FormControlName, FormControl, AbstractControl, 
  NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, ValidationErrors } from '@angular/forms';
import { escapeSelector } from 'jquery';
import { ConfigService } from '../../shared/services/config.service';
import { Observable, merge, fromEvent, Subscription, timer } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'wfm-experience-area',
  templateUrl: './experience-area.component.html',
  styleUrls: ['./experience-area.component.css'],
  providers : [
    {
      provide:NG_VALUE_ACCESSOR,
      useExisting : forwardRef (() => ExperienceAreaComponent),
      multi : true
    },
    {
      provide : NG_VALIDATORS,
      useExisting : forwardRef(() => ExperienceAreaComponent),
      multi: true
    }
  ]
})


export class ExperienceAreaComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  public expForm: FormGroup;

  notificationModal :any;
  isSubmitInvalid:boolean = false;
  constructor(private fbp: FormBuilder, private configService: ConfigService ) { }
  itemToRemove : any;

  ngOnInit() {

    this.expForm = this.fbp.group({
      experienceAreas: this.fbp.array([this.createExperienceArea()]),
    })

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.getExperienceAreas();
  }

  createExperienceArea(id=0, code = null)
  {
    return this.fbp.group({
      id:[id==null ? 0 : id],
      code: [code ? code : '', Validators.required]
    })
  }

  get experienceAreas() : FormArray {
    return this.expForm.get('experienceAreas') as FormArray;
  }

  addExperienceArea(experience)
  { 
    this.experienceAreas.push(this.createExperienceArea(experience));
  }

  removeExperienceArea(index)
  { 
    let deletingItem = this.experienceAreas.at(index);
    this.itemToRemove = index;  
     if(deletingItem.value.id == 0) 
     {
      this.experienceAreas.removeAt(this.itemToRemove);
     }
     else{
      ($('#deleteExpAreaModal') as any).modal('show');
     }
  }

  deleteExperienceArea()
  {
    this.experienceAreas.removeAt(this.itemToRemove);
    ($('#deleteExpAreaModal') as any).modal('hide');
    this.itemToRemove = -1;
  }

  cancelDeletionExperienceArea()
  {  
    this.itemToRemove = -1;
    ($('#deleteExpAreaModal') as any).modal('hide');
  }

  validateFormFields() {
    const expArray = this.expForm.get('experienceAreas') as FormArray;
   
    Object.keys(expArray['controls']).forEach(element => {
      const control = expArray.get(element);
      Object.keys(control['controls']).forEach(field => {
        const controlItem = control.get(field);
        //console.log(controlItem);
        if (controlItem instanceof FormControl) {
          controlItem.markAsTouched({ onlySelf: true });
        }
      });
    });
  }

    save() {
      this.validateFormFields();
      if (this.expForm.valid) {
        this.configService.saveExperienceAreas(this.expForm.value).subscribe(
          //() => this.onSaveComplete('update', true, '0'),
          (res) => {
           //console.log(res);
          this.setNotificationData('update', res);
          this.getExperienceAreas();
        });
      }else{
        this.isSubmitInvalid = true
      }   
    }

    cancel()
    {
      const expArray = this.expForm.get('experienceAreas') as FormArray;
   
      Object.keys(expArray['controls']).forEach(element => {
        const control = expArray.get(element);
        Object.keys(control['controls']).forEach(field => {
          const controlItem = control.get(field);
          if (controlItem instanceof FormControl) {
            controlItem.markAsUntouched({ onlySelf: false });
          }
        });
      });
    }

    getExperienceAreas() {
      this.configService.getExperienceAreas().subscribe((res) => {
         //console.log(res);

         const expArray = this.fbp.array([]);
         res.experienceAreas.forEach(item => {
           var objt = this.createExperienceArea(item.id, item.code)
           expArray.push(objt);
         });
   
         this.expForm.setControl('experienceAreas', expArray);
      })
    }

    setNotificationData(flow, status): void {
      //console.log(flow);
      //console.log(status);
      if (flow === 'update') {
        if (status) {
          console.log("Upadate Successfull !.");
          this.notificationModal.header = 'Lyckades';
          this.notificationModal.description = 'Upplevelsesområde Uppdatering lyckades!';
          this.notificationModal.type = 'success';
        } else {
          console.log("Upadate Failed !.");
          this.notificationModal.header = 'Misslyckades';
          this.notificationModal.description = 'Upplevelsesområde uppdateringen misslyckades! Var god försök igen.';
          this.notificationModal.type = 'danger';
        }
      }
      ($('#expNotificationModal') as any).modal('show');

      setTimeout(() => {
        ($('#expNotificationModal') as any).modal('hide');
      }, 1000);
    }
}
