export class AlliocationModel {
  id:any;
  startDate: Date;
  ClientId:any;
  IsPartial:boolean;
  endDate: Date;
  startDateString: string;
  endDateString: string;
  weekdayStart: string;
  weekdayEnd: string;
  weekdayLunch: number;
  weekendStart: string;
  weekendEnd: string;
  weekendLunch: number;
  resourceTypeId: number;
  isInquiry: boolean;
}
