import { UserService } from './../../user/user.service';
import { Subject, Subscription } from 'rxjs';
import { IUserInfo } from './../../user/user-info';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CalendarService } from 'src/app/calendar/calendar.service';

@Component({
  selector: 'wfm-superclient-client-list',
  templateUrl: './superclient-client-list.component.html',
  styleUrls: ['./superclient-client-list.component.css']
})
export class SuperclientClientListComponent implements OnInit {

  userId : null
  usersList: IUserInfo[];
  errorMessage: string;
  empType = 'Client';
  empSubType = 1;
  resourceTypes: []
  userFilter: any = { fullName: '' };
  selectedMonthSubscription: Subscription
  refreshSubscription: Subscription
  newSelectedUserSub: Subscription
  @Input() selectedMonth: Subject<Date> = new Subject<Date>();
  @Input() userListAction: Subject<any> = new Subject<any>();
  @Output() userSelected: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() employeeProfileClick: EventEmitter<string> = new EventEmitter<string>();
  selectedUserData: any;
  selectedUserMonth: Date = new Date();
  isInitSubscription : boolean = true

  constructor(private userService: UserService,private calendarService: CalendarService) { }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.selectedMonthSubscription.unsubscribe()
    this.refreshSubscription.unsubscribe()
    this.newSelectedUserSub.unsubscribe();
  }

  ngOnInit() {
    this.selectedMonthSubscription = this.selectedMonth.subscribe(response => {
      this.selectedUserMonth = response;
      this.userService.getSuperClientUserList(response, this.empSubType).subscribe(
        (res: IUserInfo[]) => {
          // let filteredUserList = []
          // if (this.empType == 'Employee' && this.empSubType != 0) {
          //   if (this.empSubType == 1 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Pharmacist') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   } else if (this.empSubType == 2 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Cashier') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   }
          // } else {
          //   filteredUserList = res;
          // }
          this.usersList = res;

          if (this.selectedUserData && this.selectedUserData.userId) {
            setTimeout(() => {
              //console.log('ngOnInit');
              $('.employee-wrapper').removeClass('employee-wrapper-active');
              $('#employee-wrapper-' + this.selectedUserData.userId).addClass('employee-wrapper-active');
            }, 200);
          }
        },
        (error: any) => {
          this.errorMessage = error as any;
        }
      );
    });

    this.newSelectedUserSub = this.userService.newSelectedUser.subscribe(res => {

      if (res && res.userId) {
        this.userId = res.userId
        if (this.isInitSubscription) {
          this.setUserTypeTab(res.userId, res.userType, res.userSubType)
        }
        setTimeout(() => {
          //console.log('newSelectedUserSub');
          $('.employee-wrapper').removeClass('employee-wrapper-active');
          $('#employee-wrapper-' + res.userId).addClass('employee-wrapper-active');
        }, 100);
      } else {
        if (res==null) {
          if (this.empType=="Client") {
            this.getSuperClientUserList();
          } 
        } else {
          this.empType=='Employee'
          this.empSubType = res;
          this.userService.getAllocatedUserList('SuperClient', this.empSubType).subscribe( // TODO filter by resource type
            (res: IUserInfo[]) => {
              // let filteredUserList = []
              // if (this.empSubType != 0) {
              //   if (this.empSubType == 1) {
              //     res.forEach(element => {
              //       if (element.resourceType == 'Pharmacist') {
              //         filteredUserList.push(element)
              //       }
              //     });
              //   } else if (this.empSubType == 2) {
              //     res.forEach(element => {
              //       if (element.resourceType == 'Cashier') {
              //         filteredUserList.push(element)
              //       }
              //     });
              //   }
              // } else {
              //   filteredUserList = res;
              // }
              this.usersList = res;
            },
            (error: any) => {
              this.errorMessage = error as any;
            }
          );
        }
      }
    })
    this.refreshSubscription = this.userListAction.subscribe(res =>{
      if(res=='refresh'){
        this.getSuperClientUserList();
      }
    })
    //this.getSuperClientUserList();
    this.isInitSubscription = false;
    this.getCurrentConfigData()
  }

  getSuperClientUserList(){
    this.userService.getSuperClientUserList(this.selectedUserMonth, this.empSubType).subscribe(
      (res: IUserInfo[]) => {
        // let filteredUserList = []
        // if (this.empType == 'Employee' && this.empSubType != 0) {
        //   if (this.empSubType == 1 ) {
        //     res.forEach(element => {
        //       if (element.resourceType=='Pharmacist') {
        //         filteredUserList.push(element)
        //       }
        //     });
        //   } else if (this.empSubType == 2 ) {
        //     res.forEach(element => {
        //       if (element.resourceType=='Cashier') {
        //         filteredUserList.push(element)
        //       }
        //     });
        //   }
        // } else {
        //   filteredUserList = res;
        // }
        this.usersList = res;
        if (this.selectedUserData && this.selectedUserData.userId) {
          setTimeout(() => {
            //console.log('getSuperClientUserList');
            $('.employee-wrapper').removeClass('employee-wrapper-active');
            $('#employee-wrapper-' + this.selectedUserData.userId).addClass('employee-wrapper-active');
          }, 200);
        }
      },
      (error: any) => {
        this.errorMessage = error as any;
      }
    );
  }

  userSelect(userId: string, userType: string, userSubType:number , fullName: string): void {
    this.selectedUserData = {
      userId,
      userType,
      userSubType,
      fullName
    };

    
    if (this.userId == userId) {
      this.userId = null
      this.empType = userType;
      this.empSubType = userSubType ? userSubType : 0//userSubType=='Pharmacist'? 1:( userSubType=='Cashier'? 2 :null);
      this.selectedUserData = null;
      this.userService.emmitNewSelectedUser(null);
      setTimeout(() => {
        $('.employee-wrapper').removeClass('employee-wrapper-active');
      }, 100);

    } else {
      setTimeout(() => {
        //console.log('userSelect');
        $('.employee-wrapper').removeClass('employee-wrapper-active');
        $('#employee-wrapper-' + userId).addClass('employee-wrapper-active');
      }, 100);
      this.userService.emmitNewSelectedUser(this.selectedUserData);
    }
  }

  /**
   * Set the active employee type tab and highlight the user according to the selected user.
   * 
   * @param userId 
   * @param empType 
   * @param empSubType 
   */

  setUserTypeTab(userId:string,empType: string,empSubType: number): void {
    this.empType = empType;
    this.empSubType = empSubType //empSubType=='Pharmacist'? 1:( empSubType=='Cashier'? 2 :null);

    if (this.empType == 'Employee') {
      this.userService.getAllocatedUserList('SuperClient', this.empSubType).subscribe(
        (res: IUserInfo[]) => {
          // let filteredUserList = []
          // if (this.empSubType != 0) {
          //   if (this.empSubType == 1 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Pharmacist') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   } else if (this.empSubType == 2 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Cashier') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   }
          // } else {
          //   filteredUserList = res;
          // }
          this.usersList = res;
  
          //this.userSelected.emit(null);
        },
        (error: any) => {
          this.errorMessage = error as any;
        }
      );
    } else {
      this.userService.getSuperClientUserList(this.selectedUserMonth, this.empSubType).subscribe(
        (res: IUserInfo[]) => {
          // let filteredUserList = []
          // if (this.empType == 'Employee' && this.empSubType != 0) {
          //   if (this.empSubType == 1 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Pharmacist') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   } else if (this.empSubType == 2 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Cashier') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   }
          // } else {
          //   filteredUserList = res;
          // }
          this.usersList = res;
          setTimeout(() => {
            //console.log('setUserTypeTab');
            $('.employee-wrapper').removeClass('employee-wrapper-active');
            $('#employee-wrapper-' + userId).addClass('employee-wrapper-active');
          }, 500);
    
        },
        (error: any) => {
          this.errorMessage = error as any;
        }
      );
    }
  }

  userTypeClick(empType: string, empSubType:number): void {
    this.empType = empType;
    this.empSubType = empSubType;
    if (empType=='Client') {
      this.userService.getSuperClientUserList(this.selectedUserMonth, this.empSubType).subscribe(
        (res: IUserInfo[]) => {
          this.usersList = res;
          this.userService.emmitNewSelectedUser(null);
        },
        (error: any) => {
          this.errorMessage = error as any;
        }
      );
    } else if(empType=='Employee')  {
      this.userService.getAllocatedUserList('SuperClient', this.empSubType).subscribe( // TODO filter by resourcetype
        (res: IUserInfo[]) => {
          // let filteredUserList = []
          // if (this.empSubType != 0) {
          //   if (this.empSubType == 1 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Pharmacist') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   } else if (this.empSubType == 2 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Cashier') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   }
          // } else {
          //   filteredUserList = res;
          // }
          this.usersList = res;
  
          // this.userSelected.emit(null);
          //this.calendarService.emmitOnResourceTypeChange(this.empSubType);
          this.userService.emmitNewSelectedUser(this.empSubType); // resourceTypeId number
        },
        (error: any) => {
          this.errorMessage = error as any;
        }
      );
    }
    
  }

  onEmployeeProfileClick(userId:string):void {
    this.employeeProfileClick.emit(userId)
  }

  closeDrawer(){
    if( $('.mob-bookings').hasClass('last-selection')){
     var tab = 'bookings'
    }else if($('.mob-inquiry').hasClass('last-selection')){
      var tab = 'inquiry'
    }
    $('.mob-tab').removeClass('btn-success')
    $('.mob-tab').addClass('tab-inactive')
    if (tab=='userlist') {
      $('.mob-userlist').addClass('btn-success')
      $('.mob-userlist').removeClass('tab-inactive')
    } else if (tab=='bookings') {
      $('.mob-bookings').addClass('btn-success')
      $('.mob-bookings').removeClass('tab-inactive')
    } else if (tab=='inquiry') {
      $('.mob-inquiry').addClass('btn-success')
      $('.mob-inquiry').removeClass('tab-inactive')
    }
    $('#myTab').css('display', 'none')
    $('#myTabContent').css('display', 'none')
    setTimeout(() => {
      $('.k-scheduler-navigation').css('pointer-events', 'all');
      $('.k-scheduler-navigation').css('opacity', '1');
    }, 500);
  }

  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
      this.resourceTypes = res.resourceTypes 
      
    })
  }
}
