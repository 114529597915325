import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { LongBookingTemplate } from 'src/app/shared/models/long-booking/long-booking-template.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { LongBookingService } from 'src/app/shared/services/domain/booking/long-booking.service';
import { UserService } from 'src/app/user/user.service';
import { CalendarService } from '../../calendar.service';

@Component({
  selector: 'long-booking-list',
  templateUrl: './longbooking-list.component.html',
  styleUrls: ['./longbooking-list.component.css']
})
export class LongBookingListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() templateList: LongBookingTemplate[] = [];
  @Output() deleteTemplateFunc: EventEmitter<any> = new EventEmitter();

  showEdit: boolean;
  selectedIndex: number;
  clicked: boolean;
  changedValue: boolean;
  updatedInProgress: boolean;
  userInfoList: any = [];
  searchCritera: any = {};
  config: any = {};
  longBookings:any[]=[];

  bookingModalHeader = "Make Long Bookings";
  longBookingSelected: boolean;


  constructor(private calendarService: CalendarService,private longBookingService: LongBookingService, private userService: UserService, private configService: ConfigService) { }
  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.searchCritera.pageNumber = 1;
    this.searchCritera.pageSize = 10;
    this.searchCritera.orderBy = 'FirstName';
    this.searchCritera.isAscending = true;
    this.searchCritera.isActive = true;

    this.config = {
      itemsPerPage: this.searchCritera.pageSize,
      currentPage: this.searchCritera.pageNumber,
      totalItems: 10
    };
    this.getUsers();
    this.getLongBookings();
  }

  private getUsers(): void {
    this.userService.getAll(this.searchCritera).subscribe(
      pagedResult => {
        this.config.totalItems = pagedResult.totalNumberOfRecords;
        this.config.itemsPerPage = 10;
        this.config.currentPage = 1;

        this.userInfoList = pagedResult.results;
      },
      // error => this.errorMessage = error as any
    );
  }

  editLongBooking(allocation: any) {
    this.calendarService.emmitLongBookingDetails(allocation);
    ($('#employeeBookingModal') as any).modal('hide');
    this.bookingModalHeader = "Edit Long Booking";
    this.longBookingSelected = true;
    setTimeout(() => {
        ($('#requestAllocationForLongBookinModalType1') as any).modal('show');
    }, 500);
}

  pageChanged(event): void {
    this.searchCritera.pageNumber = event;
    this.getLongBookings();
    this.config.currentPage = event;
  }

  getLongBookings(): void {
    this.longBookingService.getLongBookings(this.searchCritera).subscribe(data => {
      this.longBookings = data.results;
    })
  }

  close() {
    ($('#editTemplateForLongBookinModal') as any).modal('hide');
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
}
