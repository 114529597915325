import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './security/login.component';
import { UserListComponent } from './user/user-list.component';
import { UserViewComponent } from './user/user-view.component';
import { UserEditComponent } from './user/user-edit.component';
import { AdminReportComponent } from './report/admin-report.component';
import { AdminCalendarComponent } from './calendar/admin-calendar.component';
import { SuperclientCalendarComponent } from './calendar/superclient-calendar/superclient-calendar.component';
import { SuperclientUserListComponent } from './user/superclient-user-list/superclient-user-list.component';
import { SuperclientUserEditComponent } from './user/superclient-user-edit/superclient-user-edit.component';
import { ClientCalendarComponent } from './calendar/client-calendar.component';
import { AuthGuard } from './security/auth.guard';
import { EmployeeCalendarComponent } from './calendar/employee-calendar.component';
import { EmployeeReportComponent } from './report/employee-report.component';
import { ClientReportComponent } from './report/client-report.component';
import { MyprofileComponent } from './user/myprofile.component';
import { SuperclientReportComponent } from './report/superclient-report/superclient-report.component';
import { SuperclientUserViewComponent } from './user/superclient-user-view/superclient-user-view.component';
import { ForgotPasswordComponent } from './security/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './security/reset-password/reset-password.component';
import { BookingCalendarComponent } from './calendar/booking-calendar/booking-calendar.component';
import { AdminInquiryCalendarComponent } from './calendar/admin-inquiry-calendar/admin-inquiry-calendar.component';
import { EmployeeInquiryCalenderComponent } from './calendar/employee-inquiry-calender/employee-inquiry-calender.component';
import { ConfigComponent } from './config/config.component';
import { LongBookingComponent } from './calendar/long-booking/long-booking.component';
import { LongBookingListComponent } from './calendar/long-booking/longbooking-list/longbooking-list.component';
import { ShortBookingListComponent } from './calendar/booking-calendar/shortbooking-list/shortbooking-list.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessAdminFeatures' },
    component: UserListComponent
  },
  {
    path: 'user/:userid',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessAdminFeatures' },
    component: UserViewComponent
  },
  {
    path: 'user/update/:id',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessAdminFeatures' },
    component: UserEditComponent
  },
  {
    path: 'users/calendar',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessAdminFeatures' },
    component: AdminCalendarComponent,
    children:[
      {path:'', redirectTo:'bookings', pathMatch:'full'},
      {path:'bookings',component:BookingCalendarComponent ,data: { role: 'Admin' }},
      {path:'inquiries',component:AdminInquiryCalendarComponent,data: { role: 'Admin' }}
    ]
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessAdminFeatures' },
    component: AdminReportComponent
  },
  {
    path: 'clients/calendar',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessSuperClientFeatures' },
    component: SuperclientCalendarComponent,
    children:[
      {path:'', redirectTo:'bookings', pathMatch:'full'},
      {path:'bookings',component:BookingCalendarComponent ,data: { role: 'SuperClient' }},
      {path:'inquiries',component:AdminInquiryCalendarComponent,data: { role: 'SuperClient' }}
    ]
  },
  {
    path: 'clients/users',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessSuperClientFeatures' },
    component: SuperclientUserListComponent
  },
  {
    path: 'clients/user/:userid',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessSuperClientFeatures' },
    component: SuperclientUserViewComponent
  },
  {
    path: 'clients/user/update/:id',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessSuperClientFeatures' },
    component: SuperclientUserEditComponent
  },
  {
    path: 'clients/reports',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessSuperClientFeatures' },
    component: SuperclientReportComponent
  },
  {
    path: 'calendar',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessClientFeatures' },
    component: ClientCalendarComponent
  },
  {
    path: 'longbookings',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessAdminFeatures' },
    component: LongBookingListComponent
  },
  {
    path: 'shortbookings',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessAdminFeatures' },
    component: ShortBookingListComponent
  },
  
  {
    path: 'bookings',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessEmployeeFeatures' },
    component: EmployeeCalendarComponent,
    children:[
      {path:'', redirectTo:'bookings', pathMatch:'full'},
      {path:'bookings',component:BookingCalendarComponent ,data: { role: 'Employee' }},
      {path:'inquiries',component:EmployeeInquiryCalenderComponent,data: { role: 'Employee' }}
    ]
  },
  {
    path: 'employee/reports',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessEmployeeFeatures' },
    component: EmployeeReportComponent
  },
  {
    path: 'client/reports',
    canActivate: [AuthGuard],
    data: { claimType: 'CanAccessClientFeatures' },
    component: ClientReportComponent
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    data: { claimType: ['CanAccessAdminFeatures','CanAccessClientFeatures','CanAccessEmployeeFeatures','CanAccessSuperClientFeatures'] },
    component: MyprofileComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'account/reset',
    component: ResetPasswordComponent
  },
  {
    path: 'site/config',
    canActivate: [AuthGuard],
    component: ConfigComponent,
    data: { claimType: 'CanAccessAdminFeatures' },
  },
  {
    path: 'config',
    redirectTo :'site/config'
  },
  {
    path: 'long',
    component :LongBookingComponent
  },
  {
    path: '**',
    component: LoginComponent
  },
  {
    path: '',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
