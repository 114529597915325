import { stringify } from '@angular/compiler/src/util';
import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../../../window.providers';

@Injectable()
export class HostService {

    constructor(@Inject(WINDOW) private window: Window) {
    }

    getHostname() : string {
        var hostName = window.location.hostname;
        var strs = hostName ? hostName.toString().split('.') :  '';
     //   var serverPath ='Demo TeamPlan';
        var serverPath ='PharmaLifeNordicAB';

        if(strs.length > 0)
        {
            const title = ''
            if(strs.length == 1)
            {
                serverPath = serverPath.concat(title + this.apitalizeFirstLetter(strs[0]))
            }
            else{

                serverPath = serverPath.concat(title + this.apitalizeFirstLetter(strs[strs.length-2]));
                // for(let i = 0; i < strs.length - 1 ; i++ )
                // {
                //     serverPath = serverPath.concat(' ' + this.apitalizeFirstLetter(strs[i]));
                // }                
            }                 
        }

        return serverPath;
    }

    apitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}