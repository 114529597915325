import { Component, OnInit, AfterViewInit, ElementRef, ViewChildren, OnDestroy, Input } from '@angular/core';
import * as jQuery from 'jquery';
import { CalendarService } from './../calendar.service';
import { AllocationInfo } from './../allocation-info';
import { AvailabilityModel } from './../availability-model';
import { AvailabilityInfo } from './../availability-info';
import { BookingInfo } from './../booking-info';
import { IUserInfo } from '../../user/user-info';
import { FormBuilder, FormGroup, Validators, FormControlName } from '@angular/forms';
import { TimeLogModel } from './../time-log-model';
import { ClientBookingInfo } from './../client-booking-info';
import { AvailabilityUpdateModel } from './../availability-update-model';
import { GenericValidator } from '../../shared/generic-validator';
import { Observable, fromEvent, merge, Subscription, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { PartialAvailabilityModel } from './../partial-availability-model';
import { PartialAvailabilityInfo } from '.././partial-availability-info';
import { AlliocationModel } from './../alliocation-model';
import { UserService } from '../../user/user.service';
import { ActivatedRoute } from '@angular/router';
import { SignalrService } from 'src/app/shared/services/common/signalr.service';
import SwedishHolidays from 'src/app/shared/constants/swedishHolidays';
declare var kendo: any;

@Component({
  selector: 'wfm-admin-inquiry-calendar',
  templateUrl: './admin-inquiry-calendar.component.html',
  styleUrls: ['./admin-inquiry-calendar.component.css']
})
export class AdminInquiryCalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  @Input() selectedUserId: Subject<any>
  @Input() selectedUserType: Subject<string>

  showExpAreaDetails: boolean;
  swedishHolidays: typeof SwedishHolidays;
  role: string
  userId: string = ''
  userType: string = 'all'
  availabilityCheckDates: AvailabilityModel[];
  errorMessage: string;
  allocation: BookingInfo;
  visibleTimeLogs = false;
  updateAllocationId: number;
  isSwapEmployeeSelected: boolean;
  swapUserList: any[];
  swapUserForm: FormGroup;
  allocationTimeLogsForm: FormGroup;
  allocationForm: FormGroup;
  allocationTimeUpdateForm: FormGroup;
  selectedUserFullName: string;
  notificationModal: any;
  availabilityForm: FormGroup;
  availabilityUpdateModel: AvailabilityUpdateModel;
  currentDate = new Date();
  isWeekend = true;
  displayValidationMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  subscription: Subscription;
  partialAvailabilityForm: FormGroup;
  selectedPartialAvlDate: string;
  partialAvailableListForDay: PartialAvailabilityInfo[];
  allocationTryDate: Date;
  allocationTryTime: string;
  allocationTryLunch: number;
  selectedResourceTypeId = 1;
  resourceTypes: any[];
  selectedCalendarMonth: Subject<Date> = new Subject<Date>();
  availabilityData: AvailabilityInfo[];
  isBookingSelected = true;
  isBookingFromPartialAvailability: boolean = false;
  holidayLastMonth: string
  availableEmployees: any[] = []
  preSystemSelectedEmployeeId: Subject<string> = new Subject<string>();
  clientCommonTimes: any[] = []
  isAllocationTimeEdit: boolean;
  allocationHistory: any
  showHistory: boolean = false
  isInquiryCalendarSelected: boolean = false
  isLoadOnSubscription: boolean = false
  selectedCalendarMonthStr: string = new Date().toISOString()
  selectedUserIdSub: Subscription
  routeParamSubscription: Subscription
  newSelectedUserSub: Subscription

  // viewStartDate:Date
  // viewEndDate:Date

  deviceWidth: number = window.innerWidth;
  deviceHight: number = window.innerHeight;

  timePickerTimeList: any[] = [
    { value: '00:00', label: '00:00' },
    { value: '00:15', label: '00:15' },
    { value: '00:30', label: '00:30' },
    { value: '00:45', label: '00:45' },
    { value: '01:00', label: '01:00' },
    { value: '01:15', label: '01:15' },
    { value: '01:30', label: '01:30' },
    { value: '01:45', label: '01:45' },
    { value: '02:00', label: '02:00' },
    { value: '02:15', label: '02:15' },
    { value: '02:30', label: '02:30' },
    { value: '02:45', label: '02:45' },
    { value: '03:00', label: '03:00' },
    { value: '03:15', label: '03:15' },
    { value: '03:30', label: '03:30' },
    { value: '03:45', label: '03:45' },
    { value: '04:00', label: '04:00' },
    { value: '04:15', label: '04:15' },
    { value: '04:30', label: '04:30' },
    { value: '04:45', label: '04:45' },
    { value: '05:00', label: '05:00' },
    { value: '05:15', label: '05:15' },
    { value: '05:30', label: '05:30' },
    { value: '05:45', label: '05:45' },
    { value: '06:00', label: '06:00' },
    { value: '06:15', label: '06:15' },
    { value: '06:30', label: '06:30' },
    { value: '06:45', label: '06:45' },
    { value: '07:00', label: '07:00' },
    { value: '07:15', label: '07:15' },
    { value: '07:30', label: '07:30' },
    { value: '07:45', label: '07:45' },
    { value: '08:00', label: '08:00' },
    { value: '08:15', label: '08:15' },
    { value: '08:30', label: '08:30' },
    { value: '08:45', label: '08:45' },
    { value: '09:00', label: '09:00' },
    { value: '09:15', label: '09:15' },
    { value: '09:30', label: '09:30' },
    { value: '09:45', label: '09:45' },
    { value: '10:00', label: '10:00' },
    { value: '10:15', label: '10:15' },
    { value: '10:30', label: '10:30' },
    { value: '10:45', label: '10:45' },
    { value: '11:00', label: '11:00' },
    { value: '11:15', label: '11:15' },
    { value: '11:30', label: '11:30' },
    { value: '11:45', label: '11:45' },
    { value: '12:00', label: '12:00' },
    { value: '12:15', label: '12:15' },
    { value: '12:30', label: '12:30' },
    { value: '12:45', label: '12:45' },
    { value: '13:00', label: '13:00' },
    { value: '13:15', label: '13:15' },
    { value: '13:30', label: '13:30' },
    { value: '13:45', label: '13:45' },
    { value: '14:00', label: '14:00' },
    { value: '14:15', label: '14:15' },
    { value: '14:30', label: '14:30' },
    { value: '14:45', label: '14:45' },
    { value: '15:00', label: '15:00' },
    { value: '15:15', label: '15:15' },
    { value: '15:30', label: '15:30' },
    { value: '15:45', label: '15:45' },
    { value: '16:00', label: '16:00' },
    { value: '16:15', label: '16:15' },
    { value: '16:30', label: '16:30' },
    { value: '16:45', label: '16:45' },
    { value: '17:00', label: '17:00' },
    { value: '17:15', label: '17:15' },
    { value: '17:30', label: '17:30' },
    { value: '17:45', label: '17:45' },
    { value: '18:00', label: '18:00' },
    { value: '18:15', label: '18:15' },
    { value: '18:30', label: '18:30' },
    { value: '18:45', label: '18:45' },
    { value: '19:00', label: '19:00' },
    { value: '19:15', label: '19:15' },
    { value: '19:30', label: '19:30' },
    { value: '19:45', label: '19:45' },
    { value: '20:00', label: '20:00' },
    { value: '20:15', label: '20:15' },
    { value: '20:30', label: '20:30' },
    { value: '20:45', label: '20:45' },
    { value: '21:00', label: '21:00' },
    { value: '21:15', label: '21:15' },
    { value: '21:30', label: '21:30' },
    { value: '21:45', label: '21:45' },
    { value: '22:00', label: '22:00' },
    { value: '22:15', label: '22:15' },
    { value: '22:30', label: '22:30' },
    { value: '22:45', label: '22:45' },
    { value: '23:00', label: '23:00' },
    { value: '23:15', label: '23:15' },
    { value: '23:30', label: '23:30' },
    { value: '23:45', label: '23:45' },
  ]

  lunchTimeList: any[] = [
    { value: 0, label: '0' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 45, label: '45' },
    { value: 60, label: '60' },
  ]

  constructor(private fb: FormBuilder, private calendarService: CalendarService, private signalRService: SignalrService, private userservice: UserService, private route: ActivatedRoute,
    private elementRef: ElementRef) {
    this.validationMessages = {
      month: {
        required: 'month is required'
      },
      startDate: {
        required: 'start is required'
      },
      endDate: {
        required: 'end is required'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.subscription = this.signalRService.receiveCalendarUpdates().subscribe(st => {
      if (st) {
        const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
        scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
        scheduler.refresh();
      }
    });

    this.resourceTypes = [
      { id: '1', name: 'USK' },
      { id: '2', name: 'SSK' }
    ];
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    // this.selectedUserIdSub.unsubscribe();
    this.newSelectedUserSub.unsubscribe();
  }

  setCustomVallidations() {
    const month = this.availabilityForm.get('month');
    const start = this.availabilityForm.get('startDate');
    const end = this.availabilityForm.get('endDate');

    this.availabilityForm.get('changeTypeId').valueChanges
      .subscribe(changeType => {
        if (changeType === '1') {
          month.setValidators([Validators.required]);
          start.clearValidators();
          end.clearValidators();
        } else if (changeType === '2') {
          start.setValidators([Validators.required]);
          end.setValidators([Validators.required]);
          month.clearValidators();
        } else if (changeType === '3') {
          start.setValidators([Validators.required]);
          month.clearValidators();
          end.clearValidators();
        }
        month.updateValueAndValidity();
        start.updateValueAndValidity();
        end.updateValueAndValidity();
      });

  }

  ngOnInit() {

    this.swedishHolidays = SwedishHolidays;
    kendo.culture('sv-SE');
    this.currentDate.setHours(0, 0, 0, 0);
    this.allocation = {
      expAreasDisplay:'',
      inquiryExperienceAreas:null,
      isExpAreaValidationFail: null,
      id: 0,
      title: '',
      sDate: '',
      eDate: '',
      lunch: 0,
      isPastDate: false,
      bookingTotal: 0,
      start: new Date(),
      end: new Date(),
      empName: '',
      empImage: '',
      empJobCategoryId: 0,
      empJobCategory: '',
      clientName: '',
      clientContactName: '',
      clientAddress: '',
      clientPhone: '',
      clientEmail: '',
      isTimeLogged: false,
      timeLogStartTime: '',
      timeLogEndTime: '',
      timeLogLunch: 0,
      timeLogTotal: 0,
      colorClass: 'slot-blue',
      timeLoggedClass: 'slot-no-check',
      empImagePath: '',
      clientImagePath: '',
      resourceTypeId: 1,
      resourceTypeClass: '',
      isInquiry: null,
      clientId: '',
      resourceTypeIconLetter: ''
    };

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };
    this.initializeForms();

    this.setCustomVallidations();

    this.partialAvailabilityForm.get('avlTypeId').valueChanges
      .subscribe(avlType => {
        if (avlType === '1') {
          $('#kendo-prl-start').data('kendoTimePicker').value('');
          $('#kendo-prl-end').data('kendoTimePicker').value('');
          $('#kendo-prl-start').data('kendoTimePicker').enable(false);
          $('#kendo-prl-end').data('kendoTimePicker').enable(false);
        } else {
          $('#kendo-prl-start').data('kendoTimePicker').enable(true);
          $('#kendo-prl-end').data('kendoTimePicker').enable(true);
        }
      });

    $(window).resize(() => {
      const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
      // scheduler.dataSource.read({ userId: this.userId, userType: this.userType, month:this.selectedCalendarMonthStr, role:this.role });
      scheduler.refresh();
    })

    // this.selectedUserIdSub = this.userservice.selectedUserId.subscribe((res)=>{
    //   this.userId = res.id
    //   this.userType = res.type
    //   if (res && res.id && res.type && this.isLoadOnSubscription) {
    //     const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
    //     scheduler.dataSource.read({ userId: this.userId, userType: this.userType, month:this.selectedCalendarMonthStr, role:this.role });
    //     scheduler.refresh();
    //   }

    // })

    this.routeParamSubscription = this.route.data.subscribe(data => {
      this.role = data.role
    });
    this.newSelectedUserSub = this.userservice.newSelectedUser.subscribe((data) => {
      if (this.isLoadOnSubscription) {
        setTimeout(() => {
          this.loadCalendar(data);
        }, 100);
      } else if (data && data.userId) {
        this.userId = data.userId
        this.userType = data.userType
      }

    })
    // var date = new Date();
    // this.viewStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
    // this.viewEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  initializeForms(): void {
    this.allocationForm = this.fb.group({
      startDate: [],
      endDate: ['', Validators.required],
      weekdayStart: ['', Validators.required],
      weekdayEnd: ['', Validators.required],
      weekdayLunch: ['', Validators.required],
      weekendStart: [],
      weekendEnd: [],
      weekendLunch: [],
      clientId: [],
      resourceTypeId: ['1'],
      isInquiry: [],
      employeeId: []
    });

    this.swapUserForm = this.fb.group({
      allocationId: [],
      swapUserId: [],
      swapType: []
    });

    this.allocationTimeLogsForm = this.fb.group({
      allocationId: [],
      isTimeLogged: [],
      startTime: [],
      endTime: [],
      lunchTime: []
    });

    this.availabilityForm = this.fb.group({
      changeTypeId: ['1'],
      month: ['', Validators.required],
      startDate: [''],
      endDate: [''],
      availabilityId: ['1']
    });

    this.partialAvailabilityForm = this.fb.group({
      avlTypeId: [],
      avlDate: [],
      avlStart: [],
      avlEnd: []
    });

    this.allocationTimeUpdateForm = this.fb.group({
      allocationId: [],
      startDate: [],
      endDate: [],
      lunchTime: []
    })

  }

  // get f() { return this.swapUserForm.controls; }


  ngAfterViewInit() {
    const calendarService = this.calendarService;
    let userId = this.userId;
    let userType = this.userType;
    let role = this.role;
    let selectedCalendarMonthStr = this.selectedCalendarMonthStr;
    // let viewStartDate = this.viewStartDate
    // let viewEndDate = this.viewEndDate
    const customAgenda = kendo.ui.AgendaView.extend({
      endDate() {
        const date = kendo.ui.AgendaView.fn.startDate.call(this);
        return kendo.date.addDays(date, 30);
      }
    });

    // const customMonthView = kendo.ui.MonthView.extend({
    // });

    var eventHeight: number
    var eventsPerDay = 2
    var isAdaptiveSlotHeight: boolean
    if (this.deviceWidth < 576) {
      eventHeight = 60
      isAdaptiveSlotHeight = true
    } else if (this.deviceWidth < 768) {
      eventHeight = 43
      isAdaptiveSlotHeight = true
    } else if (this.deviceWidth < 992) {
      eventHeight = 43
      isAdaptiveSlotHeight = false
    } else if (this.deviceWidth < 1200) {
      eventHeight = 31
      isAdaptiveSlotHeight = false
    } else {
      isAdaptiveSlotHeight = false
      eventHeight = 30
    }

    $('.mob-bookings').removeClass('btn-success');
    $('.mob-bookings').addClass('tab-inactive');

    ($('#admin-inquiry-scheduler') as any).kendoScheduler({
      views: [
        {
          type: 'month',
          eventsPerDay: eventsPerDay,
          eventHeight: eventHeight,
          adaptiveSlotHeight: isAdaptiveSlotHeight,
        },
        { type: 'day', allDaySlot: false, eventTemplate: $('#event-day-template').html() },
        {
          type: customAgenda,
          title: 'Agenda',
          eventTemplate: $('#event-agenda-template').html(),
          selectedDateFormat: "{0:dd/MM/yyyy} - {1:dd/MM/yyyy}",
          selectedShortDateFormat: "{0:dd/MM} - {1:dd/MM}"
        }],
      // { type: 'agenda', eventTemplate: $('#event-agenda-template').html() }],
      eventTemplate: ($('#event-slot-template') as any).html(),
      timezone: 'Europe/Stockholm',
      majorTimeHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'HH:mm\')#</strong>'),
      dateHeaderTemplate: kendo.template("<strong>#=kendo.toString(date, 'ddd dd/M')#</strong>"),
      selectable: true,
      editable: {
        destroy: false,
        move: false,
        resize: false,
        create: false
      },
      dataSource:
      {
        transport: {
          read(o) {
            let loadData = false;
            loadData = true;
            let finalUserId
            let finalUserType

            if (o.data.userId && o.data.userType) {
              finalUserId = o.data.userId
              finalUserType = o.data.userType
            } else if (!o.data.userId && !o.data.userType && userId) {
              finalUserId = userId
              finalUserType = userType
            } else {
              finalUserId = 'all'
              finalUserType = 'all'
            }
            // viewStartDate = o.data.viewStartDate? o.data.viewStartDate : viewStartDate
            // viewEndDate = o.data.viewEndDate? o.data.viewEndDate : viewEndDate
            if (loadData) {
              calendarService.getAdminInquiryList(o.data.month, finalUserId, finalUserType, role)
                .subscribe(
                  (allocations: BookingInfo[]) => {
                    o.success(allocations);
                  },
                  (error: any) => this.errorMessage = error as any
                );
            } else {
              o.success([]);
            }

          }

        }
      },
      toolbar: ['pdf'],
      pdf: {
        fileName: 'Bookings-Export.pdf',
      }
    });

    const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');


    scheduler.wrapper.on('mouseup', '.k-scheduler-table td, .k-event', function (e) {
      const target = $(e.currentTarget);
      if (target.hasClass('k-event')) {
        const event = scheduler.occurrenceByUid(target.data('uid'));
        scheduler.editEvent(event);
      }
    });

    scheduler.bind('edit', (event) => {
      event.preventDefault();
      this.schedulerEdit(event);
    });

    scheduler.bind('dataBound', (e) => {
      this.schedulerDataBound(scheduler);
      setTimeout(() => {
        $('.k-scheduler-navigation').css('pointer-events', 'all');
        $('.k-scheduler-navigation').css('opacity', '1');
        $('.k-state-default.k-header.k-nav-prev').removeClass('k-state-hover');
        $('.k-state-default.k-header.k-nav-next').removeClass('k-state-hover');
      }, 500);
    });
    scheduler.bind('navigate', (e) => {
      // this.setViewDateRange(e);
      if (e.action == 'next') {
        $('.k-state-default.k-header.k-nav-next').addClass('k-state-hover');
      }
      if (e.action == 'previous') {
        $('.k-state-default.k-header.k-nav-prev').addClass('k-state-hover');
      }
    });



    this.initializeKendoComponents();
    $('.k-scheduler-tools').hide();
    $('#admin-inquiry-scheduler .k-scheduler-views').append('<li class="k-state-default  k-exportpdf" data-name="Export"><a role="button" href="#" class="k-link mobile-export">Skriv ut</a></li>')
    $('#admin-inquiry-scheduler .mobile-export').click(() => {
      $('#admin-inquiry-scheduler .k-pdf').click();
    });
    if (kendo.support.mobileOS) {
      $('.k-sm-date-format').css('font-size', '100%');
    }

    $('#employeeBookingModal').on('hide.bs.modal', () => {
      this.allocationTimeUpdateForm.patchValue({
        allocationId: '',
        startDate: '',
        endDate: '',
        lunchTime: '',
      })
      this.swapUserForm.reset()
      this.isAllocationTimeEdit = false
      this.showHistory = false
    })


    this.isLoadOnSubscription = true;

  }

  onClickMakeBooking() {
    ($('#requestAllocationModal') as any).modal('show');
  }

  setAvailableEmployees() { }


  initializeKendoComponents(): void {
    // ($('.admin-inquiry #kendo-end-date') as any).kendoDatePicker({
    //   format: 'yyyy-MM-dd',
    //   min: new Date(),
    //   change: (val) => {
    //     this.isWeekend = (val.sender._value.getDay() === 6) || (val.sender._value.getDay() === 0);
    //     const startTime = (this.isWeekend ? '10:00' : '09:00');
    //     const endTime = (this.isWeekend ? '15:00' : '18:00');
    //     const lunch = (this.isWeekend ? '0' : '45');

    //     this.allocationForm.patchValue({
    //       startDate: val.sender._value,
    //       endDate: val.sender._value,
    //       weekdayStart: startTime,
    //       weekdayEnd: endTime,
    //       weekdayLunch: lunch
    //     });

    //     $('.admin-inquiry #kendo-wd-start').data('kendoTimePicker').value(startTime);
    //     $('.admin-inquiry #kendo-wd-end').data('kendoTimePicker').value(endTime);
    //   }
    // });

    // ($('.admin-inquiry .kendo-time-al') as any).kendoTimePicker({
    //   format: 'HH:mm',
    //   interval: 15,
    //   change: (val) => {
    //     const dt = new Date(val.sender._value);
    //     const hours = dt.getHours();
    //     const minutes = dt.getMinutes();
    //     const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
    //     const formControlName = val.sender.element[0].attributes['data-fcname'].value;
    //     this.allocationForm.get(formControlName).setValue(formattedTime);
    //   }
    // });

    ($('.admin-inquiry .kendo-time') as any).kendoTimePicker({
      format: 'HH:mm',
      interval: 15,
      change: (val) => {
        const dt = new Date(val.sender._value);
        const hours = dt.getHours();
        const minutes = dt.getMinutes();
        const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
        const formControlName = val.sender.element[0].attributes['data-fcname'].value;
        this.allocationTimeLogsForm.get(formControlName).setValue(formattedTime);
      },
      close: (val) => {
      }
    });

    ($('.kendo-time-tu-start') as any).kendoTimePicker({
      format: 'HH:mm',
      interval: 15,
      change: (val) => {
        // const dt = new Date(val.sender._value);
        var dt: any
        if (val.sender._value) {
          dt = new Date(val.sender._value);
        } else {
          var timeStr = val.sender._oldText
          dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
        }
        const hours = dt.getHours();
        const minutes = dt.getMinutes();
        const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
        this.setEndTimeMin(dt, 'tu');
        const formControlName = val.sender.element[0].attributes['data-fcname'].value;
        this.allocationTimeUpdateForm.get(formControlName).setValue(formattedTime);
      }
    });
    ($('.kendo-time-tu-end') as any).kendoTimePicker({
      format: 'HH:mm',
      interval: 15,
      change: (val) => {
        // const dt = new Date(val.sender._value);
        var dt: any
        if (val.sender._value) {
          dt = new Date(val.sender._value);
        } else {
          var timeStr = val.sender._oldText
          dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
        }
        const hours = dt.getHours();
        const minutes = dt.getMinutes();
        const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
        this.setStartTimeMax(dt, 'tu');
        const formControlName = val.sender.element[0].attributes['data-fcname'].value;
        this.allocationTimeUpdateForm.get(formControlName).setValue(formattedTime);
      }
    });

    // ($('.admin-inquiry .kendo-time-prl') as any).kendoTimePicker({
    //   format: 'HH:mm',
    //   interval: 15,
    //   change: (val) => {
    //     const dt = new Date(val.sender._value);
    //     const hours = dt.getHours();
    //     const minutes = dt.getMinutes();
    //     const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
    //     const formControlName = val.sender.element[0].attributes['data-fcname'].value;
    //     this.partialAvailabilityForm.get(formControlName).setValue(formattedTime);
    //   },
    //   open: (val) => {
    //     if (val.sender._value === null) {
    //       $(val.sender.element).data('kendoTimePicker').value('12:00');
    //     }
    //   }
    // });

    // ($('.admin-inquiry #kendo-avl-start-date') as any).kendoDatePicker({
    //   format: 'yyyy-MM-dd',
    //   min: new Date(),
    //   change: (val) => {
    //     this.availabilityForm.patchValue({
    //       startDate: val.sender._value
    //     });
    //   }
    // });

    // ($('.admin-inquiry #kendo-avl-end-date') as any).kendoDatePicker({
    //   format: 'yyyy-MM-dd',
    //   min: new Date(),
    //   change: (val) => {
    //     this.availabilityForm.patchValue({
    //       endDate: val.sender._value
    //     });
    //   }
    // });
    // ($('.admin-inquiry #kendo-avl-month') as any).kendoDatePicker({
    //   format: 'MMMM yyyy',
    //   min: new Date(),
    //   start: 'year',
    //   depth: 'year',
    //   change: (val) => {
    //     this.availabilityForm.patchValue({
    //       month: val.sender._value
    //     });
    //   }
    // });

    // ($('.admin-inquiry .kendo-picker') as any).bind('focus', function () {
    //   $(this).data('kendoDatePicker').open();
    // });


  }



  schedulerEdit(event): void {
    this.isSwapEmployeeSelected = false;
    this.showExpAreaDetails = false;
    if (event.event.id != 0) {
      this.allocationTimeLogsForm.patchValue({
        allocationId: event.event.id,
        isTimeLogged: event.event.isTimeLogged,
        startTime: event.event.timeLogStartTime,
        endTime: event.event.timeLogEndTime,
        lunchTime: event.event.timeLogLunch
      });
      $('.admin-inquiry #kendo-tl-start').data('kendoTimePicker').value(event.event.timeLogStartTime);
      $('.admin-inquiry #kendo-tl-end').data('kendoTimePicker').value(event.event.timeLogEndTime);

      this.allocation = event.event;
      if (this.allocation.inquiryExperienceAreas && this.allocation.inquiryExperienceAreas.length) {
        this.showExpAreaDetails = true;
        let displayExpAreas = '';
        this.allocation.inquiryExperienceAreas.forEach(item => {
          displayExpAreas = displayExpAreas + ' ' + item.experienceArea;
        })
        this.allocation.expAreasDisplay = displayExpAreas;
      }

      if (this.allocation.start <= new Date()) {
        this.visibleTimeLogs = true;
      } else {
        this.visibleTimeLogs = false;
      }
      ($('#employeeBookingModal') as any).modal('show');
    }
  }

  schedulerDataBound(scheduler): void {
    const schView = scheduler.view().name;


    if (schView === 'month') {

      $('.k-scheduler-navigation').css('pointer-events', 'none');
      $('.k-scheduler-navigation').css('opacity', '0.5');

      const container = scheduler.view().element;
      const cells = container.find('td[role=gridcell]');

      $('.slot-orange').parent().addClass('dayview-slot-orange');
      $('.slot-yellow').parent().addClass('dayview-slot-yellow');
      $('.slot-blue').parent().addClass('dayview-slot-blue');
      $('.slot-yellow .font-weight-bold').css('border-color', 'red');

      this.availabilityCheckDates = [];
      for (let i = 0; i < cells.length; i++) {
        const cell = $(cells[i]);
        const slot = scheduler.slotByElement(cell);
        const avl = {} as AvailabilityModel;
        avl.category = scheduler.view().name;
        avl.startTime = slot.startDate;
        avl.endTime = slot.endDate;
        avl.userId = (this.userId && this.userId !== 'all') ? this.userId : '';
        avl.startTimeString = avl.startTime.getFullYear() + '-' + (avl.startTime.getMonth() + 1) + '-' + avl.startTime.getDate();
        avl.endTimeString = avl.endTime.getFullYear() + '-' + (avl.endTime.getMonth() + 1) + '-' + avl.endTime.getDate();
        avl.resourceTypeId = this.selectedResourceTypeId;
        this.availabilityCheckDates.push(avl);
      }


      this.calendarService.checkAllAvailability(this.availabilityCheckDates).subscribe(
        (avlData: AvailabilityInfo[]) => {
          this.availabilityData = avlData
          for (let i = 0; i < cells.length; i++) {
            const cell = $(cells[i]);
            const slot = scheduler.slotByElement(cell);
            if (slot) {
              const slotStartDate = slot.startDate as Date;
              const slotEndDate = slot.endDate as Date;
              const filterData = avlData.find(function (data) {
                return (data.startTime.toDateString() === slotStartDate.toDateString()
                  && data.endTime.toDateString() === slotEndDate.toDateString());
              });
              if (filterData) {
                var holidayArr: any[];
                var slotDateJsonFormat = this.getJsonDateFormat(slot.startDate);
                if (this.holidayLastMonth !== slotDateJsonFormat) {
                  this.holidayLastMonth = slotDateJsonFormat
                  holidayArr = this.swedishHolidays[slotDateJsonFormat]

                }

                // Mark Sundays & saturdays with different color using CSS class
                if (slotStartDate.getDay() == 0) {
                  cell.addClass('cell-sunday')
                }
                if (slotStartDate.getDay() == 6) {
                  cell.addClass('cell-saturday')
                }

                if (filterData && filterData.startTime >= this.currentDate) {
                  if (filterData.isAvailable) {
                    cell.css('background-image', 'linear-gradient(225deg, #85BD3C, #85BD3C 30px, transparent 0px, transparent)');
                    cell.css('position', 'relative');
                    if (this.userType && this.userType === 'Employee' && this.userId !== 'all') {
                      if (!filterData.hasAllocations) {
                        // Full day button and  removed considering feednack comments
                        // if (filterData.isPartial) {
                        //     cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span><button type="button" class="avlful btnpartial" data-type="2" data-sdate="' + slotStartDate.toDateString() + '">' + filterData.partialFrom + '-' + filterData.partialTo + '</button>');
                        // } else {
                        //     cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span><button type="button" class="avlful btnpartial" data-type="1" data-sdate="' + slotStartDate.toDateString() + '">Full Day</button>');
                        // }

                        // Same logic added for is block
                        cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                      } else {
                        cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                      }
                    } else {
                      // cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                      //     '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                      //     filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                      //     '</span>');

                      if (this.role == 'Admin') {
                        cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                          '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                          filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                          '</span>');
                      } else {
                        cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                      }
                    }
                  } else {
                    cell.css('background-image', 'linear-gradient(225deg, #FF6161, #FF6161 30px, transparent 0px, transparent)');
                    cell.css('position', 'relative');
                    if (this.userType && this.userType === 'Employee' && this.userId !== 'all') {
                      cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                    } else {
                      // cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                      //     '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                      //     filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                      //     '</span>');

                      if (this.role == 'Admin') {
                        cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                          '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                          filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                          '</span>');
                      } else {
                        cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                      }
                    }
                  }
                  if (holidayArr) {
                    var holidayObj = holidayArr.find(e => e.date == slot.startDate.getDate())
                    if (holidayObj) {
                      cell.addClass('holiday')
                      if (cell.children('.badge-info.holiday-name').length == 0) {
                        cell.append(`<a class="d-lg-block d-xl-block d-md-block badge badge-info holiday-name mobile-hide" style="color:white;">${holidayObj.holiday}</a >`);
                      }
                    }
                  }
                } else {
                  cell.css('position', 'relative');
                  if (this.userType && this.userType === 'Employee' && this.userId !== 'all') {
                    cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                  } else {
                    // cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                    //     '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                    //     filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                    //     '</span>');

                    if (this.role == 'Admin') {
                      cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                        '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                        filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                        '</span>');
                    } else {
                      cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                    }
                  }
                  cell.css('background-image', 'linear-gradient(225deg, #d1d1d1, #d1d1d1 30px, transparent 0px, transparent)');
                  if (holidayArr) {
                    var holidayObj = holidayArr.find(e => e.date == slot.startDate.getDate())
                    if (holidayObj) {
                      cell.addClass('holiday')
                      if (cell.children('.badge-info.holiday-name').length == 0) {
                        cell.append(`<a class="d-lg-block d-xl-block d-md-block badge badge-info holiday-name mobile-hide" style="color:white;" >${holidayObj.holiday}</a >`);
                      }
                    }
                  }
                }

                // Check for monthname
                var isFirstDay: boolean
                if (slotStartDate.getDate() == 1) {
                  isFirstDay = true
                  var month = slotStartDate.toLocaleString('sv-SE', { month: 'long' });
                  var monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1)
                } else {
                  isFirstDay = false
                }

                // Display month first day label if not holiday
                if (!holidayObj && isFirstDay) {
                  if (cell.children('.badge-info.holiday-name').length == 0) {
                    cell.append(`<a class=" d-lg-block d-xl-block d-md-block  badge badge-info holiday-name mobile-hide" style="color:white;" >Första ${monthCapitalized}</a>`);
                  }
                }

                // Dispaly week number for every monday
                if (slotStartDate.getDay() == 1) {
                  var weekNoArr = this.calendarService.getWeekNumber(slotStartDate)
                  cell.append(`<span class="badge badge-pill week-number mobile-hide" >V.${weekNoArr[1]}</span >`);
                }
              }
            }
          }

        },
        (error: any) => this.errorMessage = error as any
      );

    } if (schView === 'day') {
      $('.slot-orange.day-view').parent().addClass('dayview-slot-orange');
      $('.slot-yellow.day-view').parent().addClass('dayview-slot-yellow');
      $('.slot-blue.day-view').parent().addClass('dayview-slot-blue');
    }
    $('.k-scheduler-navigation').css('pointer-events', 'all');
    $('.k-scheduler-navigation').css('opacity', '1');

    if (this.deviceWidth < 768) {
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(1)').text('Mån');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(2)').text('Tis');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(3)').text('Ons');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(4)').text('Tor');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(5)').text('Fre');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(6)').text('Lör');
      $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(7)').text('Sön');
    }
  }

  getJsonDateFormat(date: Date): string {
    return date.getFullYear() + '' + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
  }

  loadCalendar(data: any): void {
    if (data == null || this.userId === data.userId) {
      this.userId = 'all';
      this.selectedUserFullName = null;
      $('.employee-wrapper').removeClass('employee-wrapper-active');
      $('.k-scheduler-table td').removeClass('cell-celected');
      this.selectedResourceTypeId = 1;

    } else {
      this.userId = data.userId;
      this.selectedUserFullName = data.fullName;
      this.userType = data.userType;
    }
    // this.userId = data.userId;
    const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
    scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
    // scheduler.refresh();
  }

  openDeleteConfirmModal(allocationId: number): void {
    this.updateAllocationId = allocationId;
    ($('#employeeBookingModal') as any).modal('hide');
    ($('#allocationDeleteModal') as any).modal('show');
  }

  removeAllocation(): void {
    ($('#allocationDeleteModal') as any).modal('hide');

    this.calendarService.removeAllocation(this.updateAllocationId).subscribe(
      (status: boolean) => {
        const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
        scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
        scheduler.refresh();
        this.setNotificationData('remove', status);
        this.signalRService.broadcastCalendarUpdates();
      },
      (error: any) => this.errorMessage = error as any
    );
  }


  getEmployeeList(allocationId: number, allocation): void {
    this.swapUserForm.patchValue({
      allocationId
    });
    var dataObj = {
      clientId: allocation.clientId,
      employeeId: null,
      endDate: allocation.end,
      isInquiry: true,
      resourceTypeId: allocation.resourceTypeClass == 'F' ? 1 : 2,
      startDate: allocation.start,
      weekdayEnd: allocation.eDate.slice(11, 16),
      weekdayStart: allocation.sDate.slice(11, 16),
      weekdayLunch: allocation.lunch,
      weekendEnd: null,
      weekendLunch: null,
      weekendStart: null
    }
    this.userservice.getInquiryAssignUserList(dataObj).subscribe(
      (userList: IUserInfo[]) => {
        this.swapUserList = userList;
        this.isSwapEmployeeSelected = true;
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  updateSwap(isInquiry: boolean): void {
    if (this.swapUserForm.get('swapUserId').value) {
      if (this.swapUserForm.get('swapType').value == 1 || isInquiry) {
        this.swapUserForm.patchValue({
          isInquiry: isInquiry
        });
        if (isInquiry) {
          this.swapUserForm.patchValue({
            swapType: '1'
          });
        }

        this.calendarService.swapAllocationEmployee(this.swapUserForm.value).subscribe(
          (status: boolean) => {
            const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
            scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
            scheduler.refresh();

            this.swapUserForm.patchValue({
              swapUserId: [],
              isInquiry: []
            });

            ($('#employeeBookingModal') as any).modal('hide');
            this.setNotificationData('swap', status);
            this.signalRService.broadcastCalendarUpdates();
          },
          (error: any) => this.errorMessage = error as any
        );
      } else if (this.swapUserForm.get('swapType').value == 2) {

        var dataObject
        this.swapUserList.find((obj) => {
          if (obj.value == this.swapUserForm.get('swapUserId').value) {
            dataObject = {
              alliocationId: this.allocation.id,
              employeeId: this.allocation.empName,
              swapWithAllocationId: this.swapUserForm.get('swapUserId').value,
              swapWithEmployeeId: obj.employeeId,
            }
          }
        })
        this.calendarService.onSameDaySwapEmployees(dataObject).subscribe(
          (status: boolean) => {
            const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
            scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
            scheduler.refresh();

            this.swapUserForm.patchValue({
              swapUserId: [],
              isInquiry: []
            });

            ($('#employeeBookingModal') as any).modal('hide');
            this.setNotificationData('swap', status);
            this.signalRService.broadcastCalendarUpdates();
          },
          (error: any) => this.errorMessage = error as any
        );
      }
    }
  }
  updateAllocationTimeLogs(): void {
    ($('#employeeBookingModal') as any).modal('hide');

    const validator = ($('#allocationTimeLogsFormContent') as any).kendoValidator().data('kendoValidator');
    if (validator.validate()) {
      const timeLogsForm = this.allocationTimeLogsForm;
      const timeLogModel = {} as TimeLogModel;
      timeLogModel.allocationId = timeLogsForm.get('allocationId').value;
      timeLogModel.startTime = timeLogsForm.get('startTime').value;
      timeLogModel.endTime = timeLogsForm.get('endTime').value;
      timeLogModel.lunch = timeLogsForm.get('lunchTime').value;

      var day = 60 * 60 * 24 * 1000;
      var isAllocationDateHoliday = this.calendarService.isHoliday(this.allocation.start);
      var nextDate = new Date(this.allocation.start.getTime() + day);
      var prevDate = new Date(this.allocation.start.getTime() - day);
      var isNextDateHoliday = this.calendarService.isHoliday(nextDate);
      var isPrevDateHoliday = this.calendarService.isHoliday(prevDate);

      //var holidayStr:string = isAllocationDateHoliday? 'Holiday':((isNextDateHoliday && isPrevDateHoliday)? 'InBetween': (isPrevDateHoliday? 'AfterHoliday':(isNextDateHoliday? 'BeforeHoliday':'NotHoliday')));
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var holidayStr: string = isAllocationDateHoliday ? 'Holiday' :
        ((isNextDateHoliday && !isAllocationDateHoliday) ? 'DayBeforeHoliday' : (
          (isPrevDateHoliday && !isAllocationDateHoliday) ? 'DayAfterHoliday' : days[this.allocation.start.getDay()])); // 0 = Sunday, 6 = Saturday

      timeLogModel.holidays = holidayStr;

      this.calendarService.updateTimeLogs(timeLogModel).subscribe(
        (status: boolean) => {
          const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
          scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
          this.setNotificationData('timelog', status);
          this.signalRService.broadcastCalendarUpdates();
        },
        (error: any) => this.errorMessage = error as any
      );
    }
  }

  requestAllocation(): void {
    ($('#requestAllocationModal') as any).modal('hide');
    this.isBookingFromPartialAvailability = false;

    const validator = ($('#allocationFormContent') as any).kendoValidator().data('kendoValidator');
    if (validator.validate()) {
      this.allocationForm.patchValue({
        clientId: this.userId,
        isInquiry: !this.isBookingSelected
      });

      this.calendarService.requestAdminAllocations(this.allocationForm.value).subscribe(
        (allocationInfo: BookingInfo) => {
          $('.k-scheduler-table td').removeClass('cell-celected');
          this.onAllocationRequestComplete(allocationInfo, this.allocationForm.value);
        },
        (error: any) => this.errorMessage = error as any
      );
    }
  }

  onAllocationRequestComplete(allocationInfo: BookingInfo, allocationForm: AlliocationModel): void {

    $('#kendo-end-date').data('kendoDatePicker').value('');
    $('#kendo-wd-start').data('kendoTimePicker').value('');
    $('#kendo-wd-end').data('kendoTimePicker').value('');

    this.allocationForm.patchValue({
      startDate: '',
      endDate: '',
      weekdayStart: '',
      weekdayEnd: '',
      weekdayLunch: 0,
      employeeId: ''
    });

    if (allocationInfo != null) {
      const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
      scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
      this.allocation = allocationInfo;
      this.isSwapEmployeeSelected = false;
      ($('#employeeBookingModal') as any).modal('show');
      this.signalRService.broadcastCalendarUpdates();
    } else {
      this.calendarService.getPartialAvailabilityList(allocationForm).subscribe(
        (partialList: PartialAvailabilityInfo[]) => {
          if (partialList != null && partialList.length > 0) {
            this.partialAvailableListForDay = partialList;
            this.allocationTryDate = allocationForm.startDate;
            this.allocationTryLunch = allocationForm.weekdayLunch;
            this.allocationTryTime = allocationForm.weekdayStart + '-' + allocationForm.weekdayEnd;
            ($('#partialAvailabilityListModal') as any).modal('show');
          } else {
            this.setNotificationData('booking', false);
          }
        },
        (error: any) => this.errorMessage = error as any
      );
    }
  }

  updateAvailability(): void {
    ($('#updateAvailabilityModal') as any).modal('hide');

    const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
    const container = scheduler.view().element;
    const cells = container.find('td[role=gridcell]');
    this.availabilityCheckDates = [];

    for (let i = 0; i < cells.length; i++) {
      const cell = $(cells[i]);
      const slot = scheduler.slotByElement(cell);
      const avl = {} as AvailabilityModel;
      avl.category = scheduler.view().name;
      avl.startTime = slot.startDate;
      avl.endTime = slot.endDate;
      avl.startTimeString = avl.startTime.getFullYear() + '-' + (avl.startTime.getMonth() + 1) + '-' + avl.startTime.getDate();
      avl.endTimeString = avl.endTime.getFullYear() + '-' + (avl.endTime.getMonth() + 1) + '-' + avl.endTime.getDate();
      avl.resourceTypeId = this.selectedResourceTypeId;
      this.availabilityCheckDates.push(avl);
    }

    const avlForm = this.availabilityForm;

    this.availabilityUpdateModel = {} as AvailabilityUpdateModel;
    this.availabilityUpdateModel.changeType = avlForm.get('changeTypeId').value;
    this.availabilityUpdateModel.month = avlForm.get('month').value;
    this.availabilityUpdateModel.fromDate = avlForm.get('startDate').value;
    this.availabilityUpdateModel.toDate = avlForm.get('endDate').value;
    this.availabilityUpdateModel.availability = avlForm.get('availabilityId').value;
    this.availabilityUpdateModel.dateList = this.availabilityCheckDates;
    this.availabilityUpdateModel.employeeId = this.userId;

    if (this.availabilityUpdateModel.changeType === '1') {
      const month = this.availabilityUpdateModel.month;
      this.availabilityUpdateModel.monthString = month.getFullYear() + '-' + (month.getMonth() + 1) + '-' + month.getDate();
    } else if (this.availabilityUpdateModel.changeType === '2') {
      const fromDate = this.availabilityUpdateModel.fromDate;
      const toDate = this.availabilityUpdateModel.toDate;
      this.availabilityUpdateModel.fromDateString = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
      this.availabilityUpdateModel.toDateString = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate();
    } else {
      const fromDate = this.availabilityUpdateModel.fromDate;
      this.availabilityUpdateModel.fromDateString = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
    }

    this.calendarService.updateAvailability(this.availabilityUpdateModel).subscribe(
      (avlData: AvailabilityInfo[]) => {
        //this.initializeForms();
        //this.setCustomVallidations();
        $('#kendo-avl-month').data('kendoDatePicker').value('');
        $('#kendo-avl-start-date').data('kendoDatePicker').value('');
        $('#kendo-avl-end-date').data('kendoDatePicker').value('');
        scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
        this.setNotificationData('availability', true);
        this.signalRService.broadcastCalendarUpdates();
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  setNotificationData(flow, status): void {
    if (flow === 'booking') {
      this.notificationModal.header = 'Förfrågan misslyckades';
      this.notificationModal.description = 'Tyvärr så kunde vi inte hitta en tillgänglig resurs för det valda datumet';
      this.notificationModal.type = 'danger';
    } else if (flow === 'swap') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Byte av resurs genomfördes';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Byte av resurs misslyckades! Kontrollera anställdas tillgänglighet';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'remove') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Förfrågan raderades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Borttaggning av förfrågan misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'availability') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tillgänglighet uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'timelog') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tidrapportering uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'TUppdatering av tidrapportering misslyckades! Var god och försök igen';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'partialavl') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Tillgänglighet uppdaterades';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'assign') {
      if (status) {
        this.notificationModal.header = 'Lyckades';// Success
        this.notificationModal.description = 'Den anställde tilldelades förfrågan och e-post skickades. '; //'Employee was assigned successfully for inquiry and email sent.';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades'; //'Failed';
        this.notificationModal.description = 'Tilldelning av passet till den anställda misslyckades. Vänligen försök igen. '; //'Employee assign process failed! Please try again.';
        this.notificationModal.type = 'danger';
      }
    } else if (flow === 'timeupdate') {
      if (status) {
        this.notificationModal.header = 'Lyckades';// Success
        this.notificationModal.description = 'Förfrågan tider har uppdaterats.'; //'Booking time slot has been updated';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades'; //'Failed';
        this.notificationModal.description = 'Uppdateringen av förfrågan  misslyckades! Var god försök igen.'; //'Booking time slot updating failed! Please try again.';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#notificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#notificationModal') as any).modal('hide');
    }, 3000);
  }


  scrollToHour(hour): void {
    const time = new Date();
    time.setHours(hour);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);

    const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
    const contentDiv = scheduler.element.find('div.k-scheduler-content');
    const rows = contentDiv.find('tr');

    for (let i = 0; i < rows.length; i++) {
      const element = $(rows[i]);
      const slot = scheduler.slotByElement(element);

      const slotTime = kendo.toString(slot.startDate, 'HH:mm');
      const targetTime = kendo.toString(time, 'HH:mm');

      if (targetTime === slotTime) {
        contentDiv.scrollTop(0);

        const elementTop = element.offset().top;
        const containerTop = contentDiv.offset().top;

        contentDiv.scrollTop(elementTop - containerTop);
      }
    }
  }


  bookFromPartialAvailability(partialAvailabilityData: PartialAvailabilityInfo): void {
    ($('#partialAvailabilityListModal') as any).modal('hide');
    this.allocationForm.patchValue({
      startDate: this.allocationTryDate,
      endDate: this.allocationTryDate,
      weekdayStart: partialAvailabilityData.fromTime,
      weekdayEnd: partialAvailabilityData.toTime,
      weekdayLunch: this.allocationTryLunch,
    });

    $('#kendo-end-date').data('kendoDatePicker').value(this.allocationTryDate);
    $('#kendo-end-date').data('kendoDatePicker').enable(false);
    $('#kendo-wd-start').data('kendoTimePicker').value(partialAvailabilityData.fromTime);
    $('#kendo-wd-start').data('kendoTimePicker').enable(false);
    $('#kendo-wd-end').data('kendoTimePicker').value(partialAvailabilityData.toTime);
    $('#kendo-wd-end').data('kendoTimePicker').enable(false);

    this.isBookingFromPartialAvailability = true;
    ($('#requestAllocationModal') as any).modal('show');
  }

  onResourceTypeChange(resourceTypeId: number): void {
    this.selectedResourceTypeId = resourceTypeId;
    this.allocationForm.patchValue({
      resourceTypeId: resourceTypeId
    });
    this.availabilityCheckDates.forEach(x => x.resourceTypeId = resourceTypeId);
    const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
    scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
  }

  onSelectSwapType(swapType: number): void {
    // this.swapUserForm.reset()
    if (swapType == 1) {
      this.getAvailableSwapEmployees(this.allocation.id)
    } else if (swapType == 2) {
      this.getSameDaySwapEmployees()
    }
  }

  getAvailableSwapEmployees(allocationId: number): void {
    this.swapUserForm.patchValue({
      swapUserId: null,
      allocationId: this.allocation.id
    });
    this.calendarService.getAllocationSwapUserList(allocationId).subscribe(
      (userList: IUserInfo[]) => {
        this.swapUserList = userList;
        for (let [key, value] of Object.entries(this.swapUserList)) {
          value['value'] = value.userId;
          value['label'] = value.fullName;
        }
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  getSameDaySwapEmployees(): void {
    this.swapUserForm.patchValue({
      swapUserId: null,
      allocationId: this.allocation.id
    })
    var dataset = {
      allocationId: this.allocation.id,
      startDate: this.allocation.sDate,
      endDate: this.allocation.eDate,
      clientId: this.allocation.clientId
    }
    this.calendarService.getSameDaySwapEmployeesList(dataset).subscribe(
      (userList: IUserInfo[]) => {
        this.swapUserList = userList;

      },
      (error: any) => this.errorMessage = error as any
    );
  }

  onSwapBtnClick() {
    this.isSwapEmployeeSelected = true;
  }
  onEditAllocationTimes(): void {
    var startTimeStr = this.allocation.sDate.substring(11, 16);
    var endTimeStr = this.allocation.eDate.substring(11, 16);

    var startTLTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
    var endTLTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);
    this.setEndTimeMin(startTLTime, 'tu');
    this.setStartTimeMax(endTLTime, 'tu');

    this.allocationTimeUpdateForm.patchValue({
      allocationId: this.allocation.id,
      startDate: startTimeStr,
      endDate: endTimeStr,
      lunchTime: this.allocation.lunch
    })
    this.isAllocationTimeEdit = true

    $('#kendo-tu-start').data('kendoTimePicker').value(startTimeStr);
    $('#kendo-tu-end').data('kendoTimePicker').value(endTimeStr);
  }

  onUpdateAllocationTimes(): void {

    const validator = ($('#allocationTimeUpdateFormContent') as any).kendoValidator({

      rules: {
        matches: function (input) {
          if (input.data('greaterthan') == 'kendo-tu-start') {
            var startTimeStr = $('#kendo-tu-start').val() as string;
            var endTimeStr = $('#kendo-tu-end').val() as string;
            var startTUTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
            var endTUTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);

            if (startTUTime < endTUTime) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        }
      },
    }).data('kendoValidator');

    if (validator.validate()) {

      var allocationDate = this.allocation.sDate
      var newStartDate = allocationDate.substring(0, 11) + this.allocationTimeUpdateForm.get('startDate').value + allocationDate.substring(16, allocationDate.length);
      var newEndDate = allocationDate.substring(0, 11) + this.allocationTimeUpdateForm.get('endDate').value + allocationDate.substring(16, allocationDate.length);

      var dataObj = {
        allocationId: this.allocation.id,
        startDate: newStartDate,
        endDate: newEndDate,
        lunch: this.allocationTimeUpdateForm.get('lunchTime').value,
        weekdayStart: this.allocationTimeUpdateForm.get('startDate').value,
        weekdayEnd: this.allocationTimeUpdateForm.get('endDate').value,
        holidays: ''
      };

      var day = 60 * 60 * 24 * 1000;
      var isAllocationDateHoliday = this.calendarService.isHoliday(this.allocation.start);
      var nextDate = new Date(this.allocation.start.getTime() + day);
      var prevDate = new Date(this.allocation.start.getTime() - day);
      var isNextDateHoliday = this.calendarService.isHoliday(nextDate);
      var isPrevDateHoliday = this.calendarService.isHoliday(prevDate);

      //var holidayStr: string = isAllocationDateHoliday ? 'Holiday' : ((isNextDateHoliday && isPrevDateHoliday) ? 'InBetween' : (isPrevDateHoliday ? 'AfterHoliday' : (isNextDateHoliday ? 'BeforeHoliday' : 'NotHoliday')));

      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var holidayStr: string = isAllocationDateHoliday ? 'Holiday' :
        ((isNextDateHoliday && !isAllocationDateHoliday) ? 'DayBeforeHoliday' : (
          (isPrevDateHoliday && !isAllocationDateHoliday) ? 'DayAfterHoliday' : days[this.allocation.start.getDay()])); // 0 = Sunday, 6 = Saturday

      dataObj.holidays = holidayStr;

      ($('#employeeBookingModal') as any).modal('hide');
      this.calendarService.updateAllocationTime(dataObj).subscribe(
        (status: boolean) => {
          const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
          scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
          this.setNotificationData('timeupdate', status);
          this.signalRService.broadcastCalendarUpdates();
        },
        (error: any) => this.errorMessage = error as any
      )
    }
  }

  setCommonTimes(item): void {
    var startTimeStr = item.startDate.substring(11, 16);
    var endTimeStr = item.endDate.substring(11, 16);
    this.allocationForm.patchValue({
      weekdayStart: startTimeStr,
      weekdayEnd: endTimeStr,
      weekdayLunch: item.lunch,
    });
    // $('#kendo-start-date').data('kendoDatePicker').value(eventStartDate);
    // $('#kendo-end-date').data('kendoDatePicker').value(item.endDate);
    $('#kendo-wd-start').data('kendoTimePicker').value(startTimeStr);
    $('#kendo-wd-end').data('kendoTimePicker').value(endTimeStr);
  }

  onViewHistoryBtnClick(allocationId: number) {
    if (this.showHistory) {
      this.showHistory = false
    } else {
      this.calendarService.getAllocationHistory(allocationId).subscribe(
        (res) => {
          this.allocationHistory = res;
          // ($('#viewHistoryModal') as any).modal('show');
          this.showHistory = true
        },
        (error: any) => this.errorMessage = error as any
      );
    }
  }

  onCalendarChange(calendar: string): void {
    if (calendar === 'inquiry') {
      $('.btn-calendar-booking').removeClass('btn-calendar-active')
      $('.btn-calendar-inquiry').addClass('btn-calendar-active')
      this.isInquiryCalendarSelected = true
    } else {
      $('.btn-calendar-inquiry').removeClass('btn-calendar-active')
      $('.btn-calendar-booking').addClass('btn-calendar-active')
      const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
      scheduler.refresh();
      this.schedulerDataBound(scheduler);
      setTimeout(() => {
        this.schedulerDataBound(scheduler);
      }, 700);
      this.isInquiryCalendarSelected = false
    }
  }

  setEndTimeMin(dt, option?) {
    if (option == 'tl') {
      var endTimepicker = $("#kendo-tl-end").data("kendoTimePicker");
    } else if (option == 'tu') {
      var endTimepicker = $("#kendo-tu-end").data("kendoTimePicker");
    } else {
      var endTimepicker = $("#kendo-wd-end").data("kendoTimePicker");
    }
    endTimepicker.min(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), (dt.getMinutes() == 45 ? dt.getHours() + 1 : dt.getHours()), (dt.getMinutes() == 45 ? 0 : dt.getMinutes() + 15), 0));
  }
  setStartTimeMax(dt, option?) {
    if (option == 'tl') {
      var startTimepicker = $("#kendo-tl-start").data("kendoTimePicker");
    } else if (option == 'tu') {
      var startTimepicker = $("#kendo-tu-start").data("kendoTimePicker");
    } else {
      var startTimepicker = $("#kendo-wd-start").data("kendoTimePicker");
    }
    startTimepicker.max(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), (dt.getMinutes() == 0 ? dt.getHours() - 1 : dt.getHours()), (dt.getMinutes() == 0 ? 45 : dt.getMinutes() - 15), 0));
  }

  onTLstartChange(option?: string) {
    var picker: any
    if (option == 'tu') {
      picker = $('#kendo-tu-start')
    } else {
      picker = $('#kendo-tl-start')
    }
    if (picker.val().toString().length == 2 || picker.val().toString().length == 1) {

      if (picker.val().toString().length == 2) {
        let newVal = picker.val() + ':00'
        picker.val(newVal)
        picker.trigger('change')
      }
      if (picker.val().toString().length == 1) {
        let newVal = '0' + picker.val() + ':00'
        picker.val(newVal)
        picker.trigger('change')
      }
    }
  }

  onTLEndChange(option?: string) {
    var picker: any
    if (option == 'tu') {
      picker = $('#kendo-tu-end')
    } else {
      picker = $('#kendo-tl-end')
    }
    if (picker.val().toString().length == 2 || picker.val().toString().length == 1) {

      if (picker.val().toString().length == 2) {
        let newVal = picker.val() + ':00'
        picker.val(newVal)
        picker.trigger('change')
      }
      if (picker.val().toString().length == 1) {
        let newVal = '0' + picker.val() + ':00'
        picker.val(newVal)
        picker.trigger('change')
      }
    }


  }

  // setViewDateRange(e) {
  //   var view = e.sender.view();

  //   switch (e.view) {
  //     case 'month':
  //       this.viewStartDate = new Date(e.date.getFullYear(), e.date.getMonth(), 1);
  //       this.viewEndDate = new Date(e.date.getFullYear(), e.date.getMonth() + 1, 0);

  //       break;
  //     case 'day':
  //       this.viewStartDate = new Date(e.date.getFullYear(), e.date.getMonth(), 1);
  //       this.viewEndDate = new Date(e.date.getFullYear(), e.date.getMonth() + 1, 0);
  //       break;
  //     case 'Agenda':
  //       this.viewStartDate = new Date(e.date.getFullYear(), e.date.getMonth(), e.date.getDate());
  //       this.viewEndDate = new Date(e.date.getFullYear(), e.date.getMonth() + 1, e.date.getDate());
  //       break;
  //     default:
  //       break;
  //   }

  //   const scheduler = $('#admin-inquiry-scheduler').data('kendoScheduler');
  //   scheduler.dataSource.read({ userId: this.userId, userType: this.userType, viewStartDate: this.viewStartDate, viewEndDate: this.viewEndDate });

  // }
}
