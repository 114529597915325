import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
const API_URL = environment.signalRhub;

declare var $: any;

@Injectable({
  providedIn: 'root'
})



export class SignalrService {

  private connection: any;
  private proxy: any;
  private subject = new Subject<any>();

  constructor() { }


  public initializeSignalRConnection(): void {
    const signalRServerEndPoint = API_URL;
    this.connection = $.hubConnection(signalRServerEndPoint);
    this.proxy = this.connection.createHubProxy('calendarHub');

    this.proxy.on('updateCalendar', (status) => this.onMessageReceived(status));

    this.connection.start().done((data: any) => {
      //console.log('Connected to Notification Hub');
      //this.broadcastMessage();
    }).catch((error: any) => {
      //console.log('Notification Hub error -> ' + error);
    });
  }

  broadcastCalendarUpdates(): void {
    this.proxy.invoke('updateOtherCalendars', 'update')
      .catch((error: any) => {
        console.log('broadcastMessage error -> ' + error);
      });
  }

  onMessageReceived(status: boolean) {
    console.log('New message received from Server');
    this.subject.next({ st: status });
  }

  receiveCalendarUpdates(): Observable<any> {
    return this.subject.asObservable();
  }

}
