import { Component, OnInit, AfterViewInit, ElementRef, ViewChildren, OnDestroy } from '@angular/core';
import * as jQuery from 'jquery';
import { CalendarService } from './calendar.service';
import { AllocationInfo } from './allocation-info';
import { AvailabilityModel } from './availability-model';
import { AvailabilityInfo } from './availability-info';
import { BookingInfo } from './booking-info';
import { IUserInfo } from '../user/user-info';
import { FormBuilder, FormGroup, Validators, FormControlName } from '@angular/forms';
import { TimeLogModel } from './time-log-model';
import { ClientBookingInfo } from './client-booking-info';
import { AvailabilityUpdateModel } from './availability-update-model';
import { GenericValidator } from '../shared/generic-validator';
import { Observable, fromEvent, merge, Subscription, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { PartialAvailabilityModel } from './partial-availability-model';
import { PartialAvailabilityInfo } from './partial-availability-info';
import { AlliocationModel } from './alliocation-model';
import { UserService } from '../user/user.service';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SignalrService } from '../shared/services/common/signalr.service';
declare var kendo: any;

@Component({
    selector: 'wfm-admin-calendar',
    templateUrl: './admin-calendar.component.html',
    styleUrls: ['./admin-calendar.component.css']
})
export class AdminCalendarComponent implements OnInit, OnDestroy {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
    userId: string;
    userType: string;
    availabilityCheckDates: AvailabilityModel[];
    errorMessage: string;
    allocation: BookingInfo;
    visibleTimeLogs = false;
    updateAllocationId: number;
    isSwapEmployeeSelected: boolean;
    swapUserList: any[];
    swapUserForm: FormGroup;
    allocationTimeLogsForm: FormGroup;
    allocationForm: FormGroup;
    allocationTimeUpdateForm: FormGroup;
    selectedUserFullName: string;
    selectedUserAllocationTimes: string;
    notificationModal: any;
    availabilityForm: FormGroup;
    availabilityUpdateModel: AvailabilityUpdateModel;
    currentDate = new Date();
    isWeekend = true;
    displayValidationMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    subscription: Subscription;
    partialAvailabilityForm: FormGroup;
    selectedPartialAvlDate: string;
    partialAvailableListForDay: PartialAvailabilityInfo[];
    allocationTryDate: Date;
    allocationTryTime: string;
    allocationTryLunch: number;
    selectedResourceTypeId = 1;
    resourceTypeForAvalReq = 1;
    resourceTypes: any[];
    selectedCalendarMonth: Subject<Date> = new Subject<Date>();
    availabilityData: AvailabilityInfo[];
    isBookingSelected = true;
    isBookingFromPartialAvailability: boolean = false;
    swedishHolidays: any
    holidayLastMonth: string
    availableEmployees: any[] = []
    preSystemSelectedEmployeeId: Subject<string> = new Subject<string>();
    clientCommonTimes: any[] = []
    isAllocationTimeEdit: boolean;
    allocationHistory: any
    showHistory: boolean = false
    swapAccessed: boolean = false
    isInquiryCalendarSelected: boolean = false
    employeeProfileId: Subject<string> = new Subject<string>();
    isPartialBooking: boolean = true;
    IsOnChangeEvent: boolean = false;
    isLoadOnSubscription: boolean = false;
    sendRequest: any
    selectedUserIdSub: Subscription
    newSelectedUserSub: Subscription
    routeSubscription: Subscription
    currentURL: any
    Infinity = Infinity;

    deviceWidth: number = window.innerWidth;
    deviceHight: number = window.innerHeight;

    selectedUserId: Subject<any> = new Subject<any>()
    selectedUserType: Subject<string> = new Subject<string>()
    userListAction: Subject<string> = new Subject<string>()
    selectedDate: Date = new Date(new Date().setHours(0, 0, 0, 0));

    timePickerTimeList: any[] = [
        { value: '00:00', label: '00:00', intval: 0 },
        { value: '00:15', label: '00:15', intval: 15 },
        { value: '00:30', label: '00:30', intval: 30 },
        { value: '00:45', label: '00:45', intval: 45 },
        { value: '01:00', label: '01:00', intval: 100 },
        { value: '01:15', label: '01:15', intval: 115 },
        { value: '01:30', label: '01:30', intval: 130 },
        { value: '01:45', label: '01:45', intval: 145 },
        { value: '02:00', label: '02:00', intval: 200 },
        { value: '02:15', label: '02:15', intval: 215 },
        { value: '02:30', label: '02:30', intval: 230 },
        { value: '02:45', label: '02:45', intval: 245 },
        { value: '03:00', label: '03:00', intval: 300 },
        { value: '03:15', label: '03:15', intval: 315 },
        { value: '03:30', label: '03:30', intval: 330 },
        { value: '03:45', label: '03:45', intval: 345 },
        { value: '04:00', label: '04:00', intval: 400 },
        { value: '04:15', label: '04:15', intval: 415 },
        { value: '04:30', label: '04:30', intval: 430 },
        { value: '04:45', label: '04:45', intval: 445 },
        { value: '05:00', label: '05:00', intval: 500 },
        { value: '05:15', label: '05:15', intval: 515 },
        { value: '05:30', label: '05:30', intval: 530 },
        { value: '05:45', label: '05:45', intval: 545 },
        { value: '06:00', label: '06:00', intval: 600 },
        { value: '06:15', label: '06:15', intval: 615 },
        { value: '06:30', label: '06:30', intval: 630 },
        { value: '06:45', label: '06:45', intval: 645 },
        { value: '07:00', label: '07:00', intval: 700 },
        { value: '07:15', label: '07:15', intval: 715 },
        { value: '07:30', label: '07:30', intval: 730 },
        { value: '07:45', label: '07:45', intval: 745 },
        { value: '08:00', label: '08:00', intval: 800 },
        { value: '08:15', label: '08:15', intval: 815 },
        { value: '08:30', label: '08:30', intval: 830 },
        { value: '08:45', label: '08:45', intval: 845 },
        { value: '09:00', label: '09:00', intval: 900 },
        { value: '09:15', label: '09:15', intval: 915 },
        { value: '09:30', label: '09:30', intval: 930 },
        { value: '09:45', label: '09:45', intval: 945 },
        { value: '10:00', label: '10:00', intval: 1000 },
        { value: '10:15', label: '10:15', intval: 1015 },
        { value: '10:30', label: '10:30', intval: 1030 },
        { value: '10:45', label: '10:45', intval: 1045 },
        { value: '11:00', label: '11:00', intval: 1100 },
        { value: '11:15', label: '11:15', intval: 1115 },
        { value: '11:30', label: '11:30', intval: 1130 },
        { value: '11:45', label: '11:45', intval: 1145 },
        { value: '12:00', label: '12:00', intval: 1200 },
        { value: '12:15', label: '12:15', intval: 1215 },
        { value: '12:30', label: '12:30', intval: 1230 },
        { value: '12:45', label: '12:45', intval: 1245 },
        { value: '13:00', label: '13:00', intval: 1300 },
        { value: '13:15', label: '13:15', intval: 1315 },
        { value: '13:30', label: '13:30', intval: 1330 },
        { value: '13:45', label: '13:45', intval: 1345 },
        { value: '14:00', label: '14:00', intval: 1400 },
        { value: '14:15', label: '14:15', intval: 1415 },
        { value: '14:30', label: '14:30', intval: 1430 },
        { value: '14:45', label: '14:45', intval: 1445 },
        { value: '15:00', label: '15:00', intval: 1500 },
        { value: '15:15', label: '15:15', intval: 1515 },
        { value: '15:30', label: '15:30', intval: 1530 },
        { value: '15:45', label: '15:45', intval: 1545 },
        { value: '16:00', label: '16:00', intval: 1600 },
        { value: '16:15', label: '16:15', intval: 1615 },
        { value: '16:30', label: '16:30', intval: 1630 },
        { value: '16:45', label: '16:45', intval: 1645 },
        { value: '17:00', label: '17:00', intval: 1700 },
        { value: '17:15', label: '17:15', intval: 1715 },
        { value: '17:30', label: '17:30', intval: 1730 },
        { value: '17:45', label: '17:45', intval: 1745 },
        { value: '18:00', label: '18:00', intval: 1800 },
        { value: '18:15', label: '18:15', intval: 1815 },
        { value: '18:30', label: '18:30', intval: 1830 },
        { value: '18:45', label: '18:45', intval: 1845 },
        { value: '19:00', label: '19:00', intval: 1900 },
        { value: '19:15', label: '19:15', intval: 1915 },
        { value: '19:30', label: '19:30', intval: 1930 },
        { value: '19:45', label: '19:45', intval: 1945 },
        { value: '20:00', label: '20:00', intval: 2000 },
        { value: '20:15', label: '20:15', intval: 2015 },
        { value: '20:30', label: '20:30', intval: 2030 },
        { value: '20:45', label: '20:45', intval: 2045 },
        { value: '21:00', label: '21:00', intval: 2100 },
        { value: '21:15', label: '21:15', intval: 2115 },
        { value: '21:30', label: '21:30', intval: 2130 },
        { value: '21:45', label: '21:45', intval: 2145 },
        { value: '22:00', label: '22:00', intval: 2200 },
        { value: '22:15', label: '22:15', intval: 2215 },
        { value: '22:30', label: '22:30', intval: 2230 },
        { value: '22:45', label: '22:45', intval: 2245 },
        { value: '23:00', label: '23:00', intval: 2300 },
        { value: '23:15', label: '23:15', intval: 2315 },
        { value: '23:30', label: '23:30', intval: 2330 },
        { value: '23:45', label: '23:45', intval: 2345 },
    ]

    timePickerTimeListFrom: any[] = [
        { value: '00:00', label: '00:00', intval: 0 },
        { value: '00:15', label: '00:15', intval: 15 },
        { value: '00:30', label: '00:30', intval: 30 },
        { value: '00:45', label: '00:45', intval: 45 },
        { value: '01:00', label: '01:00', intval: 100 },
        { value: '01:15', label: '01:15', intval: 115 },
        { value: '01:30', label: '01:30', intval: 130 },
        { value: '01:45', label: '01:45', intval: 145 },
        { value: '02:00', label: '02:00', intval: 200 },
        { value: '02:15', label: '02:15', intval: 215 },
        { value: '02:30', label: '02:30', intval: 230 },
        { value: '02:45', label: '02:45', intval: 245 },
        { value: '03:00', label: '03:00', intval: 300 },
        { value: '03:15', label: '03:15', intval: 315 },
        { value: '03:30', label: '03:30', intval: 330 },
        { value: '03:45', label: '03:45', intval: 345 },
        { value: '04:00', label: '04:00', intval: 400 },
        { value: '04:15', label: '04:15', intval: 415 },
        { value: '04:30', label: '04:30', intval: 430 },
        { value: '04:45', label: '04:45', intval: 445 },
        { value: '05:00', label: '05:00', intval: 500 },
        { value: '05:15', label: '05:15', intval: 515 },
        { value: '05:30', label: '05:30', intval: 530 },
        { value: '05:45', label: '05:45', intval: 545 },
        { value: '06:00', label: '06:00', intval: 600 },
        { value: '06:15', label: '06:15', intval: 615 },
        { value: '06:30', label: '06:30', intval: 630 },
        { value: '06:45', label: '06:45', intval: 645 },
        { value: '07:00', label: '07:00', intval: 700 },
        { value: '07:15', label: '07:15', intval: 715 },
        { value: '07:30', label: '07:30', intval: 730 },
        { value: '07:45', label: '07:45', intval: 745 },
        { value: '08:00', label: '08:00', intval: 800 },
        { value: '08:15', label: '08:15', intval: 815 },
        { value: '08:30', label: '08:30', intval: 830 },
        { value: '08:45', label: '08:45', intval: 845 },
        { value: '09:00', label: '09:00', intval: 900 },
        { value: '09:15', label: '09:15', intval: 915 },
        { value: '09:30', label: '09:30', intval: 930 },
        { value: '09:45', label: '09:45', intval: 945 },
        { value: '10:00', label: '10:00', intval: 1000 },
        { value: '10:15', label: '10:15', intval: 1015 },
        { value: '10:30', label: '10:30', intval: 1030 },
        { value: '10:45', label: '10:45', intval: 1045 },
        { value: '11:00', label: '11:00', intval: 1100 },
        { value: '11:15', label: '11:15', intval: 1115 },
        { value: '11:30', label: '11:30', intval: 1130 },
        { value: '11:45', label: '11:45', intval: 1145 },
        { value: '12:00', label: '12:00', intval: 1200 },
        { value: '12:15', label: '12:15', intval: 1215 },
        { value: '12:30', label: '12:30', intval: 1230 },
        { value: '12:45', label: '12:45', intval: 1245 },
        { value: '13:00', label: '13:00', intval: 1300 },
        { value: '13:15', label: '13:15', intval: 1315 },
        { value: '13:30', label: '13:30', intval: 1330 },
        { value: '13:45', label: '13:45', intval: 1345 },
        { value: '14:00', label: '14:00', intval: 1400 },
        { value: '14:15', label: '14:15', intval: 1415 },
        { value: '14:30', label: '14:30', intval: 1430 },
        { value: '14:45', label: '14:45', intval: 1445 },
        { value: '15:00', label: '15:00', intval: 1500 },
        { value: '15:15', label: '15:15', intval: 1515 },
        { value: '15:30', label: '15:30', intval: 1530 },
        { value: '15:45', label: '15:45', intval: 1545 },
        { value: '16:00', label: '16:00', intval: 1600 },
        { value: '16:15', label: '16:15', intval: 1615 },
        { value: '16:30', label: '16:30', intval: 1630 },
        { value: '16:45', label: '16:45', intval: 1645 },
        { value: '17:00', label: '17:00', intval: 1700 },
        { value: '17:15', label: '17:15', intval: 1715 },
        { value: '17:30', label: '17:30', intval: 1730 },
        { value: '17:45', label: '17:45', intval: 1745 },
        { value: '18:00', label: '18:00', intval: 1800 },
        { value: '18:15', label: '18:15', intval: 1815 },
        { value: '18:30', label: '18:30', intval: 1830 },
        { value: '18:45', label: '18:45', intval: 1845 },
        { value: '19:00', label: '19:00', intval: 1900 },
        { value: '19:15', label: '19:15', intval: 1915 },
        { value: '19:30', label: '19:30', intval: 1930 },
        { value: '19:45', label: '19:45', intval: 1945 },
        { value: '20:00', label: '20:00', intval: 2000 },
        { value: '20:15', label: '20:15', intval: 2015 },
        { value: '20:30', label: '20:30', intval: 2030 },
        { value: '20:45', label: '20:45', intval: 2045 },
        { value: '21:00', label: '21:00', intval: 2100 },
        { value: '21:15', label: '21:15', intval: 2115 },
        { value: '21:30', label: '21:30', intval: 2130 },
        { value: '21:45', label: '21:45', intval: 2145 },
        { value: '22:00', label: '22:00', intval: 2200 },
        { value: '22:15', label: '22:15', intval: 2215 },
        { value: '22:30', label: '22:30', intval: 2230 },
        { value: '22:45', label: '22:45', intval: 2245 },
        { value: '23:00', label: '23:00', intval: 2300 },
        { value: '23:15', label: '23:15', intval: 2315 },
        { value: '23:30', label: '23:30', intval: 2330 },
        { value: '23:45', label: '23:45', intval: 2345 },
    ]

    timePickerTimeListTo: any[] = [
        { value: '00:00', label: '00:00', intval: 0 },
        { value: '00:15', label: '00:15', intval: 15 },
        { value: '00:30', label: '00:30', intval: 30 },
        { value: '00:45', label: '00:45', intval: 45 },
        { value: '01:00', label: '01:00', intval: 100 },
        { value: '01:15', label: '01:15', intval: 115 },
        { value: '01:30', label: '01:30', intval: 130 },
        { value: '01:45', label: '01:45', intval: 145 },
        { value: '02:00', label: '02:00', intval: 200 },
        { value: '02:15', label: '02:15', intval: 215 },
        { value: '02:30', label: '02:30', intval: 230 },
        { value: '02:45', label: '02:45', intval: 245 },
        { value: '03:00', label: '03:00', intval: 300 },
        { value: '03:15', label: '03:15', intval: 315 },
        { value: '03:30', label: '03:30', intval: 330 },
        { value: '03:45', label: '03:45', intval: 345 },
        { value: '04:00', label: '04:00', intval: 400 },
        { value: '04:15', label: '04:15', intval: 415 },
        { value: '04:30', label: '04:30', intval: 430 },
        { value: '04:45', label: '04:45', intval: 445 },
        { value: '05:00', label: '05:00', intval: 500 },
        { value: '05:15', label: '05:15', intval: 515 },
        { value: '05:30', label: '05:30', intval: 530 },
        { value: '05:45', label: '05:45', intval: 545 },
        { value: '06:00', label: '06:00', intval: 600 },
        { value: '06:15', label: '06:15', intval: 615 },
        { value: '06:30', label: '06:30', intval: 630 },
        { value: '06:45', label: '06:45', intval: 645 },
        { value: '07:00', label: '07:00', intval: 700 },
        { value: '07:15', label: '07:15', intval: 715 },
        { value: '07:30', label: '07:30', intval: 730 },
        { value: '07:45', label: '07:45', intval: 745 },
        { value: '08:00', label: '08:00', intval: 800 },
        { value: '08:15', label: '08:15', intval: 815 },
        { value: '08:30', label: '08:30', intval: 830 },
        { value: '08:45', label: '08:45', intval: 845 },
        { value: '09:00', label: '09:00', intval: 900 },
        { value: '09:15', label: '09:15', intval: 915 },
        { value: '09:30', label: '09:30', intval: 930 },
        { value: '09:45', label: '09:45', intval: 945 },
        { value: '10:00', label: '10:00', intval: 1000 },
        { value: '10:15', label: '10:15', intval: 1015 },
        { value: '10:30', label: '10:30', intval: 1030 },
        { value: '10:45', label: '10:45', intval: 1045 },
        { value: '11:00', label: '11:00', intval: 1100 },
        { value: '11:15', label: '11:15', intval: 1115 },
        { value: '11:30', label: '11:30', intval: 1130 },
        { value: '11:45', label: '11:45', intval: 1145 },
        { value: '12:00', label: '12:00', intval: 1200 },
        { value: '12:15', label: '12:15', intval: 1215 },
        { value: '12:30', label: '12:30', intval: 1230 },
        { value: '12:45', label: '12:45', intval: 1245 },
        { value: '13:00', label: '13:00', intval: 1300 },
        { value: '13:15', label: '13:15', intval: 1315 },
        { value: '13:30', label: '13:30', intval: 1330 },
        { value: '13:45', label: '13:45', intval: 1345 },
        { value: '14:00', label: '14:00', intval: 1400 },
        { value: '14:15', label: '14:15', intval: 1415 },
        { value: '14:30', label: '14:30', intval: 1430 },
        { value: '14:45', label: '14:45', intval: 1445 },
        { value: '15:00', label: '15:00', intval: 1500 },
        { value: '15:15', label: '15:15', intval: 1515 },
        { value: '15:30', label: '15:30', intval: 1530 },
        { value: '15:45', label: '15:45', intval: 1545 },
        { value: '16:00', label: '16:00', intval: 1600 },
        { value: '16:15', label: '16:15', intval: 1615 },
        { value: '16:30', label: '16:30', intval: 1630 },
        { value: '16:45', label: '16:45', intval: 1645 },
        { value: '17:00', label: '17:00', intval: 1700 },
        { value: '17:15', label: '17:15', intval: 1715 },
        { value: '17:30', label: '17:30', intval: 1730 },
        { value: '17:45', label: '17:45', intval: 1745 },
        { value: '18:00', label: '18:00', intval: 1800 },
        { value: '18:15', label: '18:15', intval: 1815 },
        { value: '18:30', label: '18:30', intval: 1830 },
        { value: '18:45', label: '18:45', intval: 1845 },
        { value: '19:00', label: '19:00', intval: 1900 },
        { value: '19:15', label: '19:15', intval: 1915 },
        { value: '19:30', label: '19:30', intval: 1930 },
        { value: '19:45', label: '19:45', intval: 1945 },
        { value: '20:00', label: '20:00', intval: 2000 },
        { value: '20:15', label: '20:15', intval: 2015 },
        { value: '20:30', label: '20:30', intval: 2030 },
        { value: '20:45', label: '20:45', intval: 2045 },
        { value: '21:00', label: '21:00', intval: 2100 },
        { value: '21:15', label: '21:15', intval: 2115 },
        { value: '21:30', label: '21:30', intval: 2130 },
        { value: '21:45', label: '21:45', intval: 2145 },
        { value: '22:00', label: '22:00', intval: 2200 },
        { value: '22:15', label: '22:15', intval: 2215 },
        { value: '22:30', label: '22:30', intval: 2230 },
        { value: '22:45', label: '22:45', intval: 2245 },
        { value: '23:00', label: '23:00', intval: 2300 },
        { value: '23:15', label: '23:15', intval: 2315 },
        { value: '23:30', label: '23:30', intval: 2330 },
        { value: '23:45', label: '23:45', intval: 2345 },
    ]

    lunchTimeList: any[] = [
        { value: 0, label: '0' },
        { value: 15, label: '15' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
        { value: 45, label: '45' },
        { value: 60, label: '60' },
    ]

    constructor(private fb: FormBuilder, private calendarService: CalendarService, private signalRService: SignalrService, private userservice: UserService, private router: Router,
        private elementRef: ElementRef) {
        this.validationMessages = {
            month: {
                required: 'month is required'
            },
            startDate: {
                required: 'start is required'
            },
            endDate: {
                required: 'end is required'
            }
        };
        this.genericValidator = new GenericValidator(this.validationMessages);

        this.subscription = this.signalRService.receiveCalendarUpdates().subscribe(st => {
            if (st) {
                const scheduler = $('#scheduler').data('kendoScheduler');
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                scheduler.refresh();
            }
        });

        this.resourceTypes = [];

        this.routeSubscription = this.router.events.subscribe((event: Event) => {
            this.currentURL = ''
            if (event instanceof NavigationStart) {
                this.currentURL = ''
            }
            if (event instanceof NavigationEnd) {
                this.currentURL = event.url.split('?')[0]

                if (
                    (this.currentURL == '/users/calendar/inquiries') ||
                    (this.currentURL == '/clients/calendar/inquiries') ||
                    (this.currentURL == '/bookings/inquiries')
                ) {
                    this.isInquiryCalendarSelected = true
                } else {
                    this.isInquiryCalendarSelected = false
                }
            }
        })

    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
        this.newSelectedUserSub.unsubscribe()
        this.routeSubscription.unsubscribe();
    }

    onClickMakeInquiry():void{
        this.calendarService.emmitOnClickMakeInquiry();

    }

    setCustomVallidations() {
        const month = this.availabilityForm.get('month');
        const start = this.availabilityForm.get('startDate');
        const end = this.availabilityForm.get('endDate');

        this.availabilityForm.get('changeTypeId').valueChanges
            .subscribe(changeType => {
                if (changeType === '1') {
                    month.setValidators([Validators.required]);
                    start.clearValidators();
                    end.clearValidators();
                } else if (changeType === '2') {
                    start.setValidators([Validators.required]);
                    end.setValidators([Validators.required]);
                    month.clearValidators();
                } else if (changeType === '3') {
                    start.setValidators([Validators.required]);
                    month.clearValidators();
                    end.clearValidators();
                }
                month.updateValueAndValidity();
                start.updateValueAndValidity();
                end.updateValueAndValidity();
            });

    }

    ngOnInit() {
        kendo.culture('sv-SE');
        this.currentDate.setHours(0, 0, 0, 0);
        this.allocation = {
            isExpAreaValidationFail: null,
            id: 0,
            title: '',
            sDate: '',
            eDate: '',
            lunch: 0,
            isPastDate: false,
            bookingTotal: 0,
            start: new Date(),
            end: new Date(),
            empName: '',
            empImage: '',
            empJobCategoryId: 0,
            empJobCategory: '',
            clientName: '',
            clientContactName: '',
            clientAddress: '',
            clientPhone: '',
            clientEmail: '',
            isTimeLogged: false,
            timeLogStartTime: '',
            timeLogEndTime: '',
            timeLogLunch: 0,
            timeLogTotal: 0,
            colorClass: 'slot-blue',
            timeLoggedClass: 'slot-no-check',
            empImagePath: '',
            clientImagePath: '',
            resourceTypeId: 1,
            resourceTypeClass: '',
            isInquiry: null,
            clientId: '',
            resourceTypeIconLetter: ''
        };

        this.notificationModal = {
            header: '',
            description: '',
            type: ''
        };

        this.newSelectedUserSub = this.userservice.newSelectedUser.subscribe((data) => {
            this.loadCalendar(data);
        })
        this.getCurrentConfigData();
    }


    getJsonDateFormat(date: Date): string {
        return date.getFullYear() + '' + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
    }

    loadCalendar(data: any): void {
        // Values when tab select
        // 1 - Phamacists
        // 2 - Cashiers
        // null - Clients

        // Userdata when user selects
        // { 
        //     userId,
        //     userType,
        //     fullName,
        //     actualHoursBooked,
        //     totalCalculatedHours
        // }
        var reloadRequired = true;
        if (data != null) {
            // if (data == 1 || data == 2 || this.userId === data.userId) {
            if (typeof (data) == "number" || this.userId === data.userId) {
                this.userId = 'all';
                this.selectedUserFullName = null;
                // $('.employee-wrapper').removeClass('employee-wrapper-active');
                // $('.k-scheduler-table td').removeClass('cell-celected');

                // Set resource type for availability request according to the tab selected
                // if (data == 1) {
                //      reloadRequired = false
                //     this.onResourceTypeChange('1');
                // } else if (data == 2) {
                //     reloadRequired = false
                //     this.onResourceTypeChange('2');
                // } else {
                //     // Code will reach here when user deselect - set resource type to null(Same as Client tab selected)
                //     this.resourceTypeForAvalReq = null;
                // }
                // this.userservice.emmitSelectedUserId({ id: this.userId, type: 'all' })
                this.selectedUserType.next('all')
            } else {
                // Uncomment following block to reset resource type to 1st resource on user select
                if (data.userType == "Client" && this.userId == 'all') {
                    this.selectedResourceTypeId = 1;
                    // this.resourceTypeForAvalReq = '1';
                }

                this.userId = data.userId;
                this.selectedUserFullName = data.fullName;
                this.selectedUserAllocationTimes = data.totalCalculatedHours + '/' + data.actualHoursBooked;
                this.userType = data.userType;

                //
                // this.userservice.emmitSelectedUserId({ id: this.userId, type: this.userType })
                this.selectedUserType.next(data.userType)
            }
        } else {
            this.userId = 'all';
            this.selectedUserFullName = null;
            // $('.employee-wrapper').removeClass('employee-wrapper-active');
            // $('.k-scheduler-table td').removeClass('cell-celected');
            // this.userservice.emmitSelectedUserId({ id: this.userId, type: 'all' })
            this.selectedUserType.next('all')

            // reloadRequired = false
            // this.onResourceTypeChange('3');
        }

        // this.userId = data.userId;
        // if (!this.isInquiryCalendarSelected && reloadRequired) {
        //     const scheduler = $('#scheduler').data('kendoScheduler');
        //     scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
        //     scheduler.refresh();
        // }
    }

    openDeleteConfirmModal(allocationId: number): void {
        this.updateAllocationId = allocationId;
        ($('#employeeBookingModal') as any).modal('hide');
        ($('#allocationDeleteModal') as any).modal('show');
    }

    removeAllocation(): void {
        ($('#allocationDeleteModal') as any).modal('hide');

        this.calendarService.removeAllocation(this.updateAllocationId).subscribe(
            (status: boolean) => {
                const scheduler = $('#scheduler').data('kendoScheduler');
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                scheduler.refresh();
                this.setNotificationData('remove', status, this.allocation.isInquiry ? 'inquiry' : 'booking');
                this.signalRService.broadcastCalendarUpdates();
                this.calendarService.emmitUserListAction('refresh');
            },
            (error: any) => this.errorMessage = error as any
        );
    }

    getEmployeeList(allocationId: number, allocation): void {
        this.swapUserForm.patchValue({
            allocationId
        });
        var dataObj = {
            clientId: allocation.clientId,
            employeeId: null,
            endDate: allocation.end,
            isInquiry: true,
            resourceTypeId: this.selectedResourceTypeId,
            startDate: allocation.start,
            weekdayEnd: allocation.eDate.slice(11, 16),
            weekdayStart: allocation.sDate.slice(11, 16),
            weekdayLunch: allocation.lunch,
            weekendEnd: null,
            weekendLunch: null,
            weekendStart: null
        }
        this.userservice.getInquiryAssignUserList(dataObj).subscribe(
            (userList: IUserInfo[]) => {
                this.swapUserList = userList;
                this.isSwapEmployeeSelected = true;
            },
            (error: any) => this.errorMessage = error as any
        );
    }

    updateSwap(isInquiry: boolean): void {
        if (this.swapUserForm.get('swapUserId').value) {
            if (this.swapUserForm.get('swapType').value == 1 || isInquiry) {
                this.swapUserForm.patchValue({
                    isInquiry: isInquiry
                });
                if (isInquiry) {
                    this.swapUserForm.patchValue({
                        swapType: '1'
                    });
                }

                this.calendarService.swapAllocationEmployee(this.swapUserForm.value).subscribe(
                    (status: boolean) => {
                        const scheduler = $('#scheduler').data('kendoScheduler');
                        scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                        scheduler.refresh();

                        this.swapUserForm.patchValue({
                            swapUserId: [],
                            isInquiry: []
                        });

                        ($('#employeeBookingModal') as any).modal('hide');
                        this.setNotificationData('swap', status);
                        this.signalRService.broadcastCalendarUpdates();
                        this.calendarService.emmitUserListAction('refresh');;
                    },
                    (error: any) => this.errorMessage = error as any
                );
            } else if (this.swapUserForm.get('swapType').value == 2) {

                var dataObject
                this.swapUserList.find((obj) => {
                    if (obj.value == this.swapUserForm.get('swapUserId').value) {
                        dataObject = {
                            alliocationId: this.allocation.id,
                            employeeId: this.allocation.empName,
                            swapWithAllocationId: this.swapUserForm.get('swapUserId').value,
                            swapWithEmployeeId: obj.employeeId,
                        }
                    }
                })
                this.calendarService.onSameDaySwapEmployees(dataObject).subscribe(
                    (status: boolean) => {
                        const scheduler = $('#scheduler').data('kendoScheduler');
                        scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                        scheduler.refresh();

                        this.swapUserForm.patchValue({
                            swapUserId: [],
                            isInquiry: []
                        });

                        ($('#employeeBookingModal') as any).modal('hide');
                        this.setNotificationData('swap', status);
                        this.signalRService.broadcastCalendarUpdates();
                        this.calendarService.emmitUserListAction('refresh');;
                    },
                    (error: any) => this.errorMessage = error as any
                );
            }
        }
    }
    updateAllocationTimeLogs(): void {


        const validator = ($('#allocationTimeLogsFormContent') as any).kendoValidator().data('kendoValidator');
        if (validator.validate()) {
            const timeLogsForm = this.allocationTimeLogsForm;
            const timeLogModel = {} as TimeLogModel;
            timeLogModel.allocationId = timeLogsForm.get('allocationId').value;
            timeLogModel.startTime = timeLogsForm.get('startTime').value;
            timeLogModel.endTime = timeLogsForm.get('endTime').value;
            timeLogModel.lunch = timeLogsForm.get('lunchTime').value;

            var day = 60 * 60 * 24 * 1000;
            var isAllocationDateHoliday = this.calendarService.isHoliday(this.allocation.start);
            var nextDate = new Date(this.allocation.start.getTime() + day);
            var prevDate = new Date(this.allocation.start.getTime() - day);
            var isNextDateHoliday = this.calendarService.isHoliday(nextDate);
            var isPrevDateHoliday = this.calendarService.isHoliday(prevDate);

            //var holidayStr: string = isAllocationDateHoliday ? 'Holiday' : ((isNextDateHoliday && isPrevDateHoliday) ? 'InBetween' : (isPrevDateHoliday ? 'AfterHoliday' : (isNextDateHoliday ? 'BeforeHoliday' : 'NotHoliday')));

            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var holidayStr: string = isAllocationDateHoliday ? 'Holiday' :
                ((isNextDateHoliday && !isAllocationDateHoliday) ? 'DayBeforeHoliday' : (
                    (isPrevDateHoliday && !isAllocationDateHoliday) ? 'DayAfterHoliday' : days[this.allocation.start.getDay()])); // 0 = Sunday, 6 = Saturday

            timeLogModel.holidays = holidayStr;

            this.calendarService.updateTimeLogs(timeLogModel).subscribe(
                (status: boolean) => {
                    const scheduler = $('#scheduler').data('kendoScheduler');
                    scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                    this.setNotificationData('timelog', status);
                    this.signalRService.broadcastCalendarUpdates();
                    this.calendarService.emmitUserListAction('refresh');;
                    ($('#employeeBookingModal') as any).modal('hide');
                },
                (error: any) => this.errorMessage = error as any
            );
        }
    }

    requestAllocation(): void {

        this.isBookingFromPartialAvailability = false;

        const validator = ($('#allocationFormContent') as any).kendoValidator().data('kendoValidator');
        if (validator.validate()) {
            this.allocationForm.patchValue({
                clientId: this.userId,
                isInquiry: !this.isBookingSelected
            });

            this.calendarService.requestAdminAllocations(this.allocationForm.value).subscribe(
                (allocationInfo: BookingInfo) => {
                    $('.k-scheduler-table td').removeClass('cell-celected');
                    ($('#requestAllocationModal') as any).modal('hide');
                    this.onAllocationRequestComplete(allocationInfo, this.allocationForm.value);
                },
                (error: any) => this.errorMessage = error as any
            );
        }
    }

    onAllocationRequestComplete(allocationInfo: BookingInfo, allocationForm: AlliocationModel): void {

        $('#kendo-end-date').data('kendoDatePicker').value('');
        $('#kendo-wd-start').data('kendoTimePicker').value('');
        $('#kendo-wd-end').data('kendoTimePicker').value('');



        if (allocationInfo != null) {
            const scheduler = $('#scheduler').data('kendoScheduler');
            scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
            this.allocation = allocationInfo;

            this.isSwapEmployeeSelected = false;
            ($('#employeeBookingModal') as any).modal('show');
            this.signalRService.broadcastCalendarUpdates();

            this.IsOnChangeEvent = true
            this.allocationForm.patchValue({
                startDate: '',
                endDate: '',
                weekdayStart: '',
                weekdayEnd: '',
                weekdayLunch: 0,
                employeeId: ''
            });

        } else {
            this.calendarService.getPartialAvailabilityList(allocationForm).subscribe(
                (partialList: PartialAvailabilityInfo[]) => {
                    if (partialList != null && partialList.length > 0) {
                        this.partialAvailableListForDay = partialList;
                        this.allocationTryDate = allocationForm.startDate;
                        this.allocationTryLunch = allocationForm.weekdayLunch;
                        this.allocationTryTime = allocationForm.weekdayStart + '-' + allocationForm.weekdayEnd;
                        ($('#partialAvailabilityListModal') as any).modal('show');
                    } else {
                        this.setNotificationData('booking', false);
                    }
                },
                (error: any) => this.errorMessage = error as any
            );
        }
        this.calendarService.emmitUserListAction('refresh');;
    }

    updateAvailability(): void {
        ($('#updateAvailabilityModal') as any).modal('hide');

        const scheduler = $('#scheduler').data('kendoScheduler');
        const container = scheduler.view().element;
        const cells = container.find('td[role=gridcell]');
        this.availabilityCheckDates = [];

        for (let i = 0; i < cells.length; i++) {
            const cell = $(cells[i]);
            const slot = scheduler.slotByElement(cell);
            const avl = {} as AvailabilityModel;
            avl.category = scheduler.view().name;
            avl.startTime = slot.startDate;
            avl.endTime = slot.endDate;
            avl.startTimeString = avl.startTime.getFullYear() + '-' + (avl.startTime.getMonth() + 1) + '-' + avl.startTime.getDate();
            avl.endTimeString = avl.endTime.getFullYear() + '-' + (avl.endTime.getMonth() + 1) + '-' + avl.endTime.getDate();
            avl.resourceTypeId = this.selectedResourceTypeId;
            this.availabilityCheckDates.push(avl);
        }

        const avlForm = this.availabilityForm;

        this.availabilityUpdateModel = {} as AvailabilityUpdateModel;
        this.availabilityUpdateModel.changeType = avlForm.get('changeTypeId').value;
        this.availabilityUpdateModel.month = avlForm.get('month').value;
        this.availabilityUpdateModel.fromDate = avlForm.get('startDate').value;
        this.availabilityUpdateModel.toDate = avlForm.get('endDate').value;
        this.availabilityUpdateModel.availability = avlForm.get('availabilityId').value;
        this.availabilityUpdateModel.dateList = this.availabilityCheckDates;
        this.availabilityUpdateModel.employeeId = this.userId;

        if (this.availabilityUpdateModel.changeType === '1') {
            const month = this.availabilityUpdateModel.month;
            this.availabilityUpdateModel.monthString = month.getFullYear() + '-' + (month.getMonth() + 1) + '-' + month.getDate();
        } else if (this.availabilityUpdateModel.changeType === '2') {
            const fromDate = this.availabilityUpdateModel.fromDate;
            const toDate = this.availabilityUpdateModel.toDate;
            this.availabilityUpdateModel.fromDateString = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
            this.availabilityUpdateModel.toDateString = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate();
        } else {
            const fromDate = this.availabilityUpdateModel.fromDate;
            this.availabilityUpdateModel.fromDateString = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
        }

        this.calendarService.updateAvailability(this.availabilityUpdateModel).subscribe(
            (avlData: AvailabilityInfo[]) => {
                //this.initializeForms();
                //this.setCustomVallidations();
                $('#kendo-avl-month').data('kendoDatePicker').value('');
                $('#kendo-avl-start-date').data('kendoDatePicker').value('');
                $('#kendo-avl-end-date').data('kendoDatePicker').value('');
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                this.setNotificationData('availability', true);
                this.signalRService.broadcastCalendarUpdates();
                this.calendarService.emmitUserListAction('refresh');;
            },
            (error: any) => this.errorMessage = error as any
        );

    }

    setNotificationData(flow, status, type?): void {
        if (flow === 'booking') {
            this.notificationModal.header = 'Bokning misslyckades';
            this.notificationModal.description = 'Tyvärr så kunde vi inte hitta en tillgänglig resurs för det valda datumet';
            this.notificationModal.type = 'danger';
        } else if (flow === 'swap') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = 'Byte av resurs genomfördes';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'Byte av resurs misslyckades! Kontrollera anställdas tillgänglighet';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'remove') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = type == 'inquiry' ? 'Förfrågan raderades' : 'Bokningen raderades';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'Borttaggning av ' + (type == 'inquiry' ? 'förfrågan ' : 'bokningen') + ' misslyckades! Var god försök igen';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'availability') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = 'Tillgänglighet uppdaterades';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'timelog') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = 'Tidrapportering uppdaterades';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'TUppdatering av tidrapportering misslyckades! Var god och försök igen';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'partialavl') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = 'Tillgänglighet uppdaterades';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'assign') {
            if (status) {
                this.notificationModal.header = 'Lyckades';// Success
                this.notificationModal.description = 'Den anställde tilldelades förfrågan och e-post skickades. '; //'Employee was assigned successfully for inquiry and email sent.';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades'; //'Failed';
                this.notificationModal.description = 'Tilldelning av passet till den anställda misslyckades. Vänligen försök igen. '; //'Employee assign process failed! Please try again.';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'timeupdate') {
            if (status) {
                this.notificationModal.header = 'Lyckades';// Success
                this.notificationModal.description = 'Booking time slot has been updated';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades'; //'Failed';
                this.notificationModal.description = 'Booking time slot updating failed! Please try again.';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'inquiry') {
            if (status) {
                this.notificationModal.header = 'Lyckades';// Success
                this.notificationModal.description = 'Inquiry added successfully!';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades'; //'Failed';
                this.notificationModal.description = 'Inquiry adding failed! Please try again.';
                this.notificationModal.type = 'danger';
            }
        }
        ($('#notificationModal') as any).modal('show');
        setTimeout(() => {
            ($('#notificationModal') as any).modal('hide');
        }, 3000);
    }


    scrollToHour(hour): void {
        const time = new Date();
        time.setHours(hour);
        time.setMinutes(0);
        time.setSeconds(0);
        time.setMilliseconds(0);

        const scheduler = $('#scheduler').data('kendoScheduler');
        const contentDiv = scheduler.element.find('div.k-scheduler-content');
        const rows = contentDiv.find('tr');

        for (let i = 0; i < rows.length; i++) {
            const element = $(rows[i]);
            const slot = scheduler.slotByElement(element);

            const slotTime = kendo.toString(slot.startDate, 'HH:mm');
            const targetTime = kendo.toString(time, 'HH:mm');

            if (targetTime === slotTime) {
                contentDiv.scrollTop(0);

                const elementTop = element.offset().top;
                const containerTop = contentDiv.offset().top;

                contentDiv.scrollTop(elementTop - containerTop);
            }
        }
    }


    bookFromPartialAvailability(partialAvailabilityData: PartialAvailabilityInfo): void {
        ($('#partialAvailabilityListModal') as any).modal('hide');
        this.allocationForm.patchValue({
            startDate: this.allocationTryDate,
            endDate: this.allocationTryDate,
            weekdayStart: partialAvailabilityData.fromTime,
            weekdayEnd: partialAvailabilityData.toTime,
            weekdayLunch: this.allocationTryLunch,
            isPartialBooking: true
        });

        $('#kendo-end-date').data('kendoDatePicker').value(this.allocationTryDate);
        $('#kendo-end-date').data('kendoDatePicker').enable(false);
        $('#kendo-wd-start').data('kendoTimePicker').value(partialAvailabilityData.fromTime);
        $('#kendo-wd-start').data('kendoTimePicker').enable(false);
        $('#kendo-wd-end').data('kendoTimePicker').value(partialAvailabilityData.toTime);
        $('#kendo-wd-end').data('kendoTimePicker').enable(false);

        this.isBookingFromPartialAvailability = true;
        ($('#requestAllocationModal') as any).modal('show');
    }

    onResourceTypeChange(resourceTypeId: number): void {
        this.calendarService.emmitOnResourceTypeChange(resourceTypeId);
        // if (resourceTypeId!=='3') {
        //     this.selectedResourceTypeId = resourceTypeId;
        // } else {
        //     this.selectedResourceTypeId = '1';
        // }

        this.selectedResourceTypeId = resourceTypeId;

        // if (resourceTypeId!=='3') {
        //     this.resourceTypeForAvalReq = null;
        //     this.availabilityCheckDates.forEach(x => x.resourceTypeId = this.resourceTypeForAvalReq);
        //     const scheduler = $('#scheduler').data('kendoScheduler');
        //     scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
        // } else {
        //     this.selectedResourceTypeId = resourceTypeId;
        //     this.resourceTypeForAvalReq = resourceTypeId;
        //     this.allocationForm.patchValue({
        //         resourceTypeId: resourceTypeId
        //     });
        //     this.availabilityCheckDates.forEach(x => x.resourceTypeId = this.resourceTypeForAvalReq);
        //     const scheduler = $('#scheduler').data('kendoScheduler');
        //     scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
        // }
    }

    onSelectSwapType(swapType: number): void {
        // this.swapUserForm.reset()
        if (swapType == 1) {
            $('.availBtn').removeClass('btn-secondary')
            $('.availBtn').addClass('btn-primary')
            $('.sameDayBtn').removeClass('btn-primary')
            $('.sameDayBtn').addClass('btn-secondary')
            this.swapUserForm.patchValue({
                swapType: 1
            })
            this.getAvailableSwapEmployees(this.allocation.id)
        } else if (swapType == 2) {
            $('.sameDayBtn').removeClass('btn-secondary')
            $('.sameDayBtn').addClass('btn-primary')
            $('.availBtn').removeClass('btn-primary')
            $('.availBtn').addClass('btn-secondary')
            this.swapUserForm.patchValue({
                swapType: 2
            })
            this.getSameDaySwapEmployees()
        }
        this.swapAccessed = true
    }

    getAvailableSwapEmployees(allocationId: number): void {
        this.swapUserForm.patchValue({
            swapUserId: null,
            allocationId: this.allocation.id
        });
        this.calendarService.getAllocationSwapUserList(allocationId).subscribe(
            (userList: IUserInfo[]) => {
                this.swapUserList = userList;
                for (let [key, value] of Object.entries(this.swapUserList)) {
                    value['value'] = value.userId;
                    value['label'] = value.fullName;
                }
                // let firstUserId = this.swapUserList.length>0? this.swapUserList[0].value:''
                // this.swapUserForm.patchValue({
                //   swapUserId:firstUserId
                // })
            },
            (error: any) => this.errorMessage = error as any
        );
    }

    getSameDaySwapEmployees(): void {
        this.swapUserForm.patchValue({
            swapUserId: null,
            allocationId: this.allocation.id
        })
        var dataset = {
            allocationId: this.allocation.id,
            startDate: this.allocation.sDate,
            endDate: this.allocation.eDate,
            clientId: this.allocation.clientId
        }
        this.calendarService.getSameDaySwapEmployeesList(dataset).subscribe(
            (userList: IUserInfo[]) => {
                this.swapUserList = userList;
                // let firstUserId = this.swapUserList.length>0? this.swapUserList[0].value:''
                // this.swapUserForm.patchValue({
                //   swapUserId:firstUserId
                // })
            },
            (error: any) => this.errorMessage = error as any
        );
    }

    onSwapBtnClick() {
        this.isSwapEmployeeSelected = true;
    }
    onEditAllocationTimes(): void {
        if ($('#partialTimeUpdateNotifyModal')) {
            ($('#partialTimeUpdateNotifyModal') as any).modal('hide');
        }
        if (this.isPartialBooking) {
            ($('#employeeBookingModal') as any).modal('show');
        }
        var startTimeStr = this.allocation.sDate.substring(11, 16);
        var endTimeStr = this.allocation.eDate.substring(11, 16);

        this.allocationTimeUpdateForm.patchValue({
            allocationId: this.allocation.id,
            startDate: startTimeStr,
            endDate: endTimeStr,
            lunchTime: this.allocation.lunch
        })
        this.isAllocationTimeEdit = true
    }

    onUpdateAllocationTimes(): void {
        var allocationDate = this.allocation.sDate
        var newStartDate = allocationDate.substring(0, 11) + this.allocationTimeUpdateForm.get('startDate').value + allocationDate.substring(16, allocationDate.length);
        var newEndDate = allocationDate.substring(0, 11) + this.allocationTimeUpdateForm.get('endDate').value + allocationDate.substring(16, allocationDate.length);

        var dataObj = {
            allocationId: this.allocation.id,
            startDate: newStartDate,
            endDate: newEndDate,
            lunch: this.allocationTimeUpdateForm.get('lunchTime').value,
            weekdayStart: this.allocationTimeUpdateForm.get('startDate').value,
            weekdayEnd: this.allocationTimeUpdateForm.get('endDate').value,
        };

        ($('#employeeBookingModal') as any).modal('hide');
        this.calendarService.updateAllocationTime(dataObj).subscribe(
            (status: boolean) => {
                const scheduler = $('#scheduler').data('kendoScheduler');
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                this.setNotificationData('timeupdate', status);
                this.signalRService.broadcastCalendarUpdates();
                this.calendarService.emmitUserListAction('refresh');;
            },
            (error: any) => this.errorMessage = error as any
        )
    }

    setCommonTimes(item): void {
        this.allocationForm.patchValue({
            weekdayStart: item.startTime,
            weekdayEnd: item.endTime,
            weekdayLunch: item.lunch,
        });
        // $('#kendo-start-date').data('kendoDatePicker').value(eventStartDate);
        // $('#kendo-end-date').data('kendoDatePicker').value(item.endDate);
        $('#kendo-wd-start').data('kendoTimePicker').value(item.startTime);
        $('#kendo-wd-end').data('kendoTimePicker').value(item.endTime);
    }

    onViewHistoryBtnClick(allocationId: number) {
        this.toggleSendRequest();
        if (this.sendRequest) {
            this.calendarService.getAllocationHistory(allocationId).subscribe(
                (res) => {
                    this.allocationHistory = res;
                },
                (error: any) => this.errorMessage = error as any
            );
        }

    }
    toggleSendRequest() {
        this.sendRequest = !this.sendRequest
    }

    onCalendarChange(calendar: string): void {
        if (calendar === 'inquiry') {
            this.isInquiryCalendarSelected = true
        } else {
            this.isInquiryCalendarSelected = false;
            this.selectedResourceTypeId = 1;
        }
    }
    onMobileTabSelect(event) {
        if (event == 'userlist') {
            $('#myTab').css('display', 'flex')
            $('#myTabContent').css('display', 'block')
        } else if (event == 'bookings') {
            $('#myTab').css('display', 'none')
            $('#myTabContent').css('display', 'none')
            $('.btn-calendar-booking').click()
        } else if (event == 'inquiry') {
            $('#myTab').css('display', 'none')
            $('#myTabContent').css('display', 'none')
            $('.btn-calendar-inquiry').click()
        }
    }

    addInquiryOnpartial(allocationData) {
        this.allocationForm.patchValue({
            clientId: this.userId,
            isInquiry: true
        });
        this.calendarService.requestAdminAllocations(this.allocationForm.value).subscribe(
            (allocationInfo: BookingInfo) => {
                if (allocationInfo != null) {
                    ($('#partialAvailabilityListModal') as any).modal('hide');
                    this.signalRService.broadcastCalendarUpdates();
                    this.setNotificationData('inquiry', true);
                    this.allocationForm.patchValue({
                        startDate: '',
                        endDate: '',
                        weekdayStart: '',
                        weekdayEnd: '',
                        weekdayLunch: 0,
                        employeeId: ''
                    });
                    this.calendarService.emmitUserListAction('refresh');;
                } else {
                    this.setNotificationData('inquiry', false);
                }
                $('.k-scheduler-table td').removeClass('cell-celected');
                // this.onAllocationRequestComplete(allocationInfo, this.allocationForm.value);
            },
            (error: any) => this.errorMessage = error as any
        );
    }

    openProfileViewModal(event) {
        this.employeeProfileId.next(event);

    }

    onClickMakeBooking() {
        this.calendarService.emmitOnClickMakeBooking();

        // this.clientCommonTimes = []
        // if (this.isBookingSelected) {
        //     this.calendarService.getClientCommonTimes(this.userId).subscribe((response) => {
        //         this.clientCommonTimes = response;
        //         this.setAvailableEmployees();      
        //     })
        // }else{
        //     this.setAvailableEmployees();
        // }
    }

    onClickMakeLongBooking() {
        this.calendarService.emmitOnClickMakeLongBooking(true);
    }

    setAvailableEmployees() {
        if (!this.IsOnChangeEvent) {
            if (this.userType && this.userType === 'Client' && this.userId != 'all' && this.isBookingSelected) {
                this.allocationForm.patchValue({
                    clientId: this.userId,
                    isInquiry: !this.isBookingSelected
                });
                this.calendarService.getAvailableEmployees(this.allocationForm.value).subscribe((availableEmpList) => {
                    if (availableEmpList) {
                        this.availableEmployees = availableEmpList
                        if (availableEmpList.length > 0) {
                            var preSystemSelectedEmployee = availableEmpList.find(element => {
                                return element.isDefaultMatched == true
                            });
                        }
                        if (preSystemSelectedEmployee) {
                            setTimeout(() => {
                                this.allocationForm.get("employeeId").setValue(preSystemSelectedEmployee.value);
                            }, 200);
                        }
                    } else {
                        this.allocationForm.patchValue({
                            employeeId: '',
                        });
                        this.availableEmployees = []
                    }
                    ($('#requestAllocationModal') as any).modal('show');
                })
            } else {
                ($('#requestAllocationModal') as any).modal('show');
            }
        }
        this.IsOnChangeEvent = false;
    }
    showEditAllocationTime() {
        if (this.isPartialBooking) {
            ($('#employeeBookingModal') as any).modal('hide');
            ($('#partialTimeUpdateNotifyModal') as any).modal('show');
        } else {
            this.onEditAllocationTimes();
        }
    }
    rejectTimeEditModal() {
        ($('#partialTimeUpdateNotifyModal') as any).modal('hide');
        ($('#employeeBookingModal') as any).modal('show');
    }

    setMinToTime() {
        var currentFromTime = this.partialAvailabilityForm.get('avlStart').value
        this.timePickerTimeList.forEach((item) => {
            if (item.value == currentFromTime) {
                this.disableLessThan(item.intval);
            }
        })
    }

    setMaxFromTime() {
        var currentToTime = this.partialAvailabilityForm.get('avlEnd').value
        this.timePickerTimeListFrom.forEach((item) => {
            if (item.value == currentToTime) {
                this.disableGreaterThan(item.intval);
            }
        })
    }

    disableLessThan(CurrentFrom) {
        this.timePickerTimeListTo.forEach((item) => {
            if (item.intval <= CurrentFrom) {
                item.disabled = true;
            } else {
                item.disabled = false;
            }
        })
    }
    disableGreaterThan(CurrentTo) {
        this.timePickerTimeListFrom.forEach((item) => {
            if (item.intval >= CurrentTo) {
                item.disabled = true;
            } else {
                item.disabled = false;
            }
        })
    }

    getCurrentConfigData(): void {
        this.userservice.getConfigData().subscribe((res) => {
            this.resourceTypes = res.resourceTypes

        })
    }
}
