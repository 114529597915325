import { DateDto } from "./date.model";
import { LongBookingEmployeeModel } from "./long-booking-employee-model";
import { LongBookingShift } from "./long-booking-shift.model";

export class LongBooking {
    id: number;
    clientId: string;
    startDate: Date;
    endDate: Date;
    resourceTypeId: number;
    isInquiry: boolean;
    isPartialBooking: boolean;
    isActive: boolean;
    experienceAreas: number[];
    excludedDays: Date[];
    devientDates: DateDto[];
    shifts: LongBookingShift[];
    createBookingsWithInquiries :boolean
    employees: LongBookingEmployeeModel[];
}

export class EmployeeAvailbleDates {
    employee: any;
    dates: Date[]
}