import { Component, OnInit,ViewChild, ElementRef, Input } from '@angular/core';
import { FileUploadService } from 'src/app/config/file-upload/file-upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../shared/services/config.service';

const API_URL = environment.apiEndpoint;

@Component({
  selector: 'wfm-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})

export class FileUploadComponent implements OnInit {
  @Input() imageType : string;

  selectedFiles: File[] = [];
  selectedFile : File;
  progressInfos = [];
  errorMessage: string;
  fileInfos : Observable<any>;

  imageurls =[];
  imageUrl ='';
  base64String: string;
  name: string;
  imagePath: string;
  uploadBtnEnabled : boolean;
  displayImage : any;
  notificationModal :any;

  @ViewChild('fileInput', {static: false})
  myFileInput: ElementRef;

  constructor(private uploadService : FileUploadService, private configService : ConfigService,  private sanitizer: DomSanitizer) {

   }

  ngOnInit() {
    console.log(this.imageType);
     this.getImageUrl(this.imageType);

     this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };
  }

  sanitizeImageUrl(url : string) : SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  } 

  getImageUrl(imageType)
  {
      if(imageType === 'logo')
      {
          this.configService.getLogoUrl().subscribe((res) => {
            console.log(res);
            this.imageUrl = res;
        })
      }
    else if(imageType === 'favicon') {
        this.configService.getFaviconUrl().subscribe((res) => {
          console.log(res);
          this.imageUrl = res;
      })
    }

    //console.log(this.imageUrl)
  }

  selectFiles(event)
  {
      const files = event.target.files;
      let isImage = true;

      for (let i = 0; i < files.length; i++) {

        var mimeType = files[i].type;
      
      if (mimeType.match(/image\/*/) == null) {
        console.log("Only images are supported");
        return;
      }

        if (files.item(i).type.match('image.*')) {
          this.selectedFiles = [];
          this.selectedFiles.push(files.item(i));
          console.log(files.item(i));
          console.log("This file is an image file");
          continue;
        } else {
          //isImage = false;
          console.log(files.item(i).fileName + ' is not a invalid format!');
          break;
        }
      }
      console.log(this.selectedFiles);
      if (this.selectedFiles.length > 0) {
        // this.selectedFiles = event.target.files;
        console.log("Locally processing the image");
          this.uploadBtnEnabled = true;
          var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageurls =[];
          this.imageurls.push({ base64String: event.target.result, });
        }
        console.log(this.imageurls);
        console.log(event.target.files[0]);
        reader.readAsDataURL(event.target.files[0]); 
      } 
      else {
        this.selectedFiles = undefined;
        event.srcElement.percentage = null;
      }
  }


  uploadFiles() {
      this.errorMessage = '';
      this.upload(0, this.selectedFiles)

    }

  upload(idx, files : File[]) {
      if (files.length === 0) {
        return;
      }
    
      const formData : FormData = new FormData();
      let filesToUpload : File[] = files;
        
      Array.from(files).map((file, index) => {
        console.log(file.name);
        return formData.append('file'+index, file, file.name);
      });

      formData.append('imageType', this.imageType);

      this.uploadService.upload(formData).subscribe(
        (imagePath: string) => {
          if (imagePath != null && imagePath != '') {
            localStorage.removeItem(this.imageType);
            localStorage.setItem(this.imageType, imagePath);
            this.imageUrl = imagePath;
            //this.imageUrl = event.body.base64;
            var imageData = btoa(imagePath);
            //console.log("Base64 Image: ",imageData);
            this.displayImage = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64,"+ imageData);
            //console.log(this.displayImage);
            //this.setNotificationData('update', true);
          }
        },
        (error: any) => this.errorMessage = error as any
        );
  }

  removeImage(i, element) 
    {
      if( i >= 0)
      {
        this.imageurls.splice(i, 1);
      }
      else
      {
        this.imageUrl = "";
      }

      this.myFileInput.nativeElement.value = "";
  }

  setNotificationData(flow, status): void {
    if (flow === 'update') {
      if (status) {
        console.log("Upadate Successfull !.");
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Uppdatering av konfiguration har lyckats!';
        this.notificationModal.type = 'success';
      } else {
        console.log("Upadate Failed !.");
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av konfiguration misslyckades! Vänligen försök igen.';
        this.notificationModal.type = 'danger';
      }
      console.log(this.notificationModal);
    }
    ($('#fileUpNotificationModal') as any).modal('show');

    setTimeout(() => {
      ($('#fileUpNotificationModal') as any).modal('hide');
    }, 3000);
  }
}
