import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders, HttpResponse, HttpEventType } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SecurityService } from './security.service';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  private totalRequests: number = 0

  constructor(private router: Router, private spinner: NgxSpinnerService, private securityService: SecurityService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    let lastResponse: HttpEvent<any>;
    let error: HttpErrorResponse;
    this.totalRequests++;
    if (!req.url.includes('longbooking/templates/update')) {
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();

      }, 4000);
    }

    const token = localStorage.getItem('bearerToken');

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    });

    if (token) {
      const newReq = req.clone({ headers });
      return next.handle(newReq)
        .pipe(
          tap(
            (res) => {
              lastResponse = res;
              if (res instanceof HttpResponse) {
                this.decreaseRequests();
              }
            },
            (err: any) => {
              this.decreaseRequests();
              if (err instanceof HttpErrorResponse) {
                if (err.status !== 401) {
                  return;
                }
                this.securityService.logout()
                this.router.navigate(['login']);
              }
            }
          ),
          finalize(() => {
            if ((lastResponse.type === HttpEventType.Sent) && !error) {
              // last response type was 0, and haven't received an error
              // So this is a canceled request, Requests conut need to be decreased in order to hide the loader on correct request count.
              this.decreaseRequests();
            }
          })
        );

    } else {
      this.decreaseRequests();
      return next.handle(req);
    }
  }

  private decreaseRequests() {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.spinner.hide();
    }
  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ]
})

export class HttpInterceptorModule {
}
