import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators, FormControlName, FormControl, AbstractControl, 
  NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, ValidationErrors } from '@angular/forms';
import { escapeSelector } from 'jquery';
import { ConfigService } from '../../shared/services/config.service';
import { Observable, merge, fromEvent, Subscription, timer } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'wfm-legitimations',
  templateUrl: './legitimations.component.html',
  styleUrls: ['./legitimations.component.css'],
  providers : [
    {
      provide:NG_VALUE_ACCESSOR,
      useExisting : forwardRef (() => LegitimationsComponent),
      multi : true
    },
    {
      provide : NG_VALIDATORS,
      useExisting : forwardRef(() => LegitimationsComponent),
      multi: true
    }
  ]
})
export class LegitimationsComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  public legitimationsForm: FormGroup;

  notificationModal :any;
  isSubmitInvalid:boolean = false;
  itemToRemove: any;
  constructor(private fbp: FormBuilder, private configService: ConfigService ) { }

  ngOnInit() {

    this.legitimationsForm = this.fbp.group({
      legitimations: this.fbp.array([this.createLegitimation()]),
    })

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.getLegitimations();
  }
  
  createLegitimation(id=0, code = null)
  {
    return this.fbp.group({
      id:[id==null ? 0 : id],
      code: [code ? code : '', Validators.required]
    })
  }
  get legitimations() : FormArray {
    return this.legitimationsForm.get('legitimations') as FormArray;
  }

  addLegitimation(resource) {
    this.legitimations.push(this.createLegitimation(resource));
  }

  removeLegitimation(index) {
    
    let deletingItem = this.legitimations.at(index);
    this.itemToRemove = index;  
     if(deletingItem.value.id == 0) 
     {
      this.legitimations.removeAt(this.itemToRemove);
     }
     else{
      ($('#deleteLegitimationsModal') as any).modal('show');
     }
  }

  deleteLegitimation()
  {
    this.legitimations.removeAt(this.itemToRemove);
    ($('#deleteLegitimationsModal') as any).modal('hide');
    this.itemToRemove = -1;
  }

  cancelDeletionLegitimation()
  {  
    this.itemToRemove = -1;
    ($('#deleteLegitimationsModal') as any).modal('hide');
  }
  
  validateFormFields() {
    const legitimationsArray = this.legitimationsForm.get('legitimations') as FormArray;
   
    Object.keys(legitimationsArray['controls']).forEach(element => {
      const control = legitimationsArray.get(element);
      Object.keys(control['controls']).forEach(field => {
        const controlItem = control.get(field);
        //console.log(controlItem);
        if (controlItem instanceof FormControl) {
          controlItem.markAsTouched({ onlySelf: true });
        }
      });
    });
  }

    save() {
      this.validateFormFields();
      if (this.legitimationsForm.valid) {
        this.isSubmitInvalid = false;
        this.configService.saveLegitimations(this.legitimationsForm.value).subscribe(
          //() => this.onSaveComplete('update', true, '0'),
          (res) => {
          // console.log(res);
          this.setNotificationData('update',res);
          this.getLegitimations();
        });
      }else{

        this.isSubmitInvalid = true
      }   
    }

    cancel()
    {
      const legitimationsArray = this.legitimationsForm.get('legitimations') as FormArray;
   
      Object.keys(legitimationsArray['controls']).forEach(element => {
        const control = legitimationsArray.get(element);
        Object.keys(control['controls']).forEach(field => {
          const controlItem = control.get(field);
          if (controlItem instanceof FormControl) {
            controlItem.markAsUntouched({ onlySelf: false });
          }
        });
      });
    }

    getLegitimations() {
      this.configService.getLegitimations().subscribe((res) => {
         //console.log(res);

         const legitimationsArray = this.fbp.array([]);
         res.legitimations.forEach(item => {
           var objt = this.createLegitimation(item.id, item.code)
           legitimationsArray.push(objt);
         });
   
         this.legitimationsForm.setControl('legitimations', legitimationsArray);
      })
    }

    setNotificationData(flow, status): void {
    //console.log(flow);
    //console.log(status);
      if (flow === 'update') {
        if (status) {
          this.notificationModal.header = 'Lyckades';
          this.notificationModal.description = 'Legitimationer Uppdatering lyckades!';
          this.notificationModal.type = 'success';
        } else {
          this.notificationModal.header = 'Misslyckades';
          this.notificationModal.description = 'Legitimationer uppdateringen misslyckades! Var god försök igen.';
          this.notificationModal.type = 'danger';
        }
        console.log(this.notificationModal);
      }
      ($('#legitNotificationModal') as any).modal('show');

      setTimeout(() => {
        ($('#legitNotificationModal') as any).modal('hide');
      }, 1000);
    }
}
