export enum ApiActionName {
    Customer = "customer",
    GetMatchingAlgorithmCriterias = "config/matchingAlgorithmCriterias",
    SaveLongBooking = "longbooking/add",
    SaveLongBookingTemplate = "longbooking/templates/add",
    GetLongBookingTemplates = "longbooking/templates",
    GetLongBookings = "longbooking/getLongBookings",
    GetShortBookings = "allocations/getshortbookings",
    GetLongBookingById = "longbooking/getLongBookingById",
    UpdateLongBooking = "longbooking/update",
    UpdateLongBookingTemplate = "longbooking/templates/update",
    UpdateLongBookingTemplateName = "longbooking/templates/updateByName",
    GetAvailabilities = "longbooking/availabilities",
    DeleteLongBooking = "longbooking/delete",
    DeleteLongBookingRange = "longbooking/deleterange",
    DeleteTemplate = "longbooking/templates/delete",
    UpdateAdminConfiguration = "config/updateadminconfiguration",
    GetAdminConfiguration = "config/getadminConfigurations",
    Updateuserwiseadminconfiguration ="config/updateuserwiseadminconfiguration",
    Getuserwiseadminconfiguration ="config/userwisegetadminConfigurations",
    GetEmployeeHourlyRate ="user/hourlyrate",
    UpdateEmployeeHourlyRate ="user/hourlyrate"
}