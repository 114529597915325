import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { IUserSearchCriteria } from './user-search-criteria';
import { IUserInfo } from './user-info';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfigService } from '../shared/services/config.service';

@Component({
  selector: 'wfm-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  errorMessage: string;
  searchCritera: IUserSearchCriteria = {} as IUserSearchCriteria;
  userInfoList: IUserInfo[] = [];
  searchForm: FormGroup;
  config: any;
  responseData: any;
  selectedUser: IUserInfo = {} as IUserInfo;
  removeUserId: string;
  notificationModal: any;
  bookingUnitName : any = '';
  
  constructor(private fb: FormBuilder, private userService: UserService , private configService : ConfigService) {
    this.searchForm = this.fb.group({
      searchText: '',
      userType: '',
      status: 'true'
    });
  }

  ngOnInit() {
    this.searchCritera.pageNumber = 1;
    this.searchCritera.pageSize = 10;
    this.searchCritera.orderBy = 'FirstName';
    this.searchCritera.isAscending = true;
    this.searchCritera.isActive = true;

    this.config = {
      itemsPerPage: this.searchCritera.pageSize,
      currentPage: this.searchCritera.pageNumber,
      totalItems: 0
    };

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.getBookingUnit();

    this.getUsers();
    if (history.state.flow) {
      this.setNotificationData(history.state.flow, history.state.status);
    }
  }

  private getUsers(): void {
    this.userService.getAll(this.searchCritera).subscribe(
      pagedResult => {
        this.config.totalItems = pagedResult.totalNumberOfRecords;
        this.userInfoList = pagedResult.results;
      },
      error => this.errorMessage = error as any
    );
  }

  filterData(): void {
    this.searchCritera.pageNumber = 1;
    this.searchCritera.searchText = this.searchForm.get('searchText').value;
    this.searchCritera.userTypeId = this.searchForm.get('userType').value;
    this.searchCritera.isActive = this.searchForm.get('status').value;

    this.userService.getAll(this.searchCritera).subscribe(
      pagedResult => {
        this.config.currentPage = 1;
        this.config.totalItems = pagedResult.totalNumberOfRecords;
        this.userInfoList = pagedResult.results;
      },
      error => this.errorMessage = error as any
    );
  }

  updateUserStatus(status: number): void {
    this.userService.updateUserStatus(this.selectedUser.userId, status).subscribe(
      data => {
        this.filterData();
        if (status == 0) {
          ($('#userDeactivateModal') as any).modal('hide');
          this.setNotificationData('remove', data);
        } else{
          ($('#userActivateModal') as any).modal('hide');
          this.setNotificationData('activate', data);
        }

      },
      error => this.errorMessage = error as any
    );
  }

  getBookingUnit()
  {
      this.configService.getBookingUnits().subscribe(res => {
        console.log(res);
        if(res)
        {
          this.bookingUnitName = (res.bookingUnits.length > 0) ? res.bookingUnits[0].name : '';
        }
    });
  }

  pageChanged(event): void {
    this.searchCritera.pageNumber = event;
    this.getUsers();
    this.config.currentPage = event;
  }

  setNotificationData(flow, status): void {
    if (flow === 'remove') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Användaren inaktiverad';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Inaktivering av användare misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    if (flow === 'activate') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Användare aktiverad';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Aktivering av användare misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    if (flow === 'add') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Användaren har skapats';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Skapande av användare misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    if (flow === 'update') {
      if (status) {
        this.notificationModal.header = 'Lyckades';
        this.notificationModal.description = 'Användaren har uppdaterats';
        this.notificationModal.type = 'success';
      } else {
        this.notificationModal.header = 'Misslyckades';
        this.notificationModal.description = 'Uppdatering av användare misslyckades! Var god försök igen';
        this.notificationModal.type = 'danger';
      }
    }
    ($('#notificationModal') as any).modal('show');
    setTimeout(() => {
      ($('#notificationModal') as any).modal('hide');
    }, 3000);
  }

}
