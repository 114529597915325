import { Component, OnInit } from '@angular/core';
import { HostService } from './shared/services/common/host.service';
import { ConfigService } from './shared/services/config.service';
import { Title } from "@angular/platform-browser";
import { SignalrService } from './shared/services/common/signalr.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wfm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  hostName: string;
  faviconUrl: string;
  logoUrl: string;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(private translate: TranslateService, private signalRService: SignalrService, private hostService: HostService, private configService: ConfigService, private title: Title) {
    this.signalRService.initializeSignalRConnection();
    let isSwLanguage = localStorage.getItem("isSwLanguage");
    if (isSwLanguage == null || isSwLanguage == undefined) {
      localStorage.setItem("isSwLanguage", "true");
      translate.setDefaultLang('sw')
    } else if (isSwLanguage == "true") {
      localStorage.setItem("isSwLanguage", "true");
      translate.setDefaultLang('sw')
    } else {
      localStorage.setItem("isSwLanguage", "false");
      translate.setDefaultLang('en')
    }

  }

  ngOnInit() {
    this.hostName = this.hostService.getHostname();
    this.title.setTitle(this.hostName);
    //this.favIcon.href = "https://teamplanstorage.blob.core.windows.net/24transport/favicon.ico"; 

      // this.faviconUrl = "https://teamplanstorage.blob.core.windows.net/prodimages/logo.png"// res.configuration.faviconImagePath;
       this.favIcon.href = "https://teamplanstorage.blob.core.windows.net/prodimages/pharmatch favico icon.ico"; 

    this.configService.getConfigData().subscribe((res) => {

      // this.faviconUrl = "https://teamplanstorage.blob.core.windows.net/24transport/logo.png"// res.configuration.faviconImagePath;
      // this.favIcon.href = "https://teamplanstorage.blob.core.windows.net/24transport/favicon.ico"; 
      // this.faviconUrl = res.configuration.faviconImagePath;
       this.logoUrl = res.configuration.logoImagePath;
      localStorage.setItem("logoImageUrl", this.logoUrl);
      localStorage.setItem("faviconImageUrl", this.faviconUrl);
      // this.favIcon.href = this.faviconUrl; 

    });
  }
}
