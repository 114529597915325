import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { LongBookingTemplate } from 'src/app/shared/models/long-booking/long-booking-template.model';
import { LongBookingService } from 'src/app/shared/services/domain/booking/long-booking.service';

@Component({
  selector: 'wfm-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css']
})
export class TemplateListComponent implements OnInit, OnChanges,OnDestroy {

  @Input() templateList: LongBookingTemplate[] = [];
  @Output() deleteTemplateFunc: EventEmitter<any> = new EventEmitter();

  showEdit: boolean;
  selectedIndex: number;
  clicked: boolean;
  changedValue:boolean;
  updatedInProgress:boolean;

  constructor(private longBookingService: LongBookingService) { }
  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.templateList.forEach(item=> {
      item.showEdit = false;
    })
  }

  onFormClick()
  {
    this.templateList.forEach(item=> {
      item.showEdit = false;
    })
  }

  saveTemplate(template:any) {
    this.updatedInProgress = true;
    let selectedTemplate = new LongBookingTemplate();
    selectedTemplate.Id = template.id;
    selectedTemplate.Name = template.name;

    this.templateList[this.selectedIndex].updatedInProgress = true;
    this.longBookingService.updateLongBookingTemplateName(selectedTemplate).subscribe(data => {
      this.changedValue = false;
      this.updatedInProgress = false;
      this.templateList[this.selectedIndex].updatedInProgress = false;
      this.templateList[this.selectedIndex].showEdit = false;
    });
  }

  focusOutFunction(template:LongBookingTemplate) {
    if (!this.clicked) {
      this.templateList.forEach(el => {
        el.showEdit = false;
      })
    }
    this.clicked = false;
    if(this.changedValue)
    {
      this.saveTemplate(template);
    }
  }

  setDefault()
  {
    if (!this.clicked) {
      this.templateList[this.selectedIndex].showEdit = false;
    }
  }

  close() {
    ($('#editTemplateForLongBookinModal') as any).modal('hide');
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  deleteTemplate(template: LongBookingTemplate) {
    this.deleteTemplateFunc.emit(template);
  }

  onTextBoxClicked()
  {
    this.clicked = true;
  }

  validateTemplateName(value)
  {
    this.changedValue = true;
  }

  showEditTemplate(index: any) {
    this.clicked = true;
    this.templateList.forEach(el => {
      el.showEdit = false;
      el.updatedInProgress = false;
    })
    this.templateList[index].showEdit = true;
    this.selectedIndex = index;
  }

}
