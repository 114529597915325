import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeHTml'
})
export class SafeHTmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, ...args: any[]): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

}
