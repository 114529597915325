
import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators, FormControlName, FormControl, AbstractControl, 
  NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, ValidationErrors } from '@angular/forms';
import { escapeSelector } from 'jquery';
// import { UserService } from '../user/user.service';
// import { FileUploadService } from '../config/file-upload/file-upload.service';
import { ConfigService } from '../../shared/services/config.service';
import { Observable, merge, fromEvent, Subscription, timer } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'wfm-pharmacy-system',
  templateUrl: './pharmacy-system.component.html',
  styleUrls: ['./pharmacy-system.component.css'],
  providers : [
    {
      provide:NG_VALUE_ACCESSOR,
      useExisting : forwardRef (() => PharmacySystemComponent),
      multi : true
    },
    {
      provide : NG_VALIDATORS,
      useExisting : forwardRef(() => PharmacySystemComponent),
      multi: true
    }
  ]
})
export class PharmacySystemComponent implements OnInit {
  
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  public pharmacySystemsForm: FormGroup;

  notificationModal :any;
  isSubmitInvalid:boolean = false;
  itemToRemove: any;
 
  constructor( private fbp: FormBuilder, private configService: ConfigService ) { 

  }

  ngOnInit() {
    this.pharmacySystemsForm = this.fbp.group({
      pharmacySystems: this.fbp.array([this.createPharmacySystem()]),
    })

    this.notificationModal = {
      header: '',
      description: '',
      type: ''
    };

    this.getPharmacySystems();
  }

 createPharmacySystem(id=0, name = null)
 {
   return this.fbp.group({
     id:[id==null ? 0 : id],
     name: [name ? name : '', Validators.required]
   })
 }
  get pharmacySystems() : FormArray {
    return this.pharmacySystemsForm.get('pharmacySystems') as FormArray;
  }

  addPharmacySystem(pharmSys)
  { 
    this.pharmacySystems.push(this.createPharmacySystem(pharmSys));
  }

  removePharmacySystem(index)
  { 
    let deletingItem = this.pharmacySystems.at(index);
    this.itemToRemove = index;  
     if(deletingItem.value.id == 0) 
     {
      this.pharmacySystems.removeAt(this.itemToRemove);
     }
     else{
      ($('#deletePharmcySystemModal') as any).modal('show');
     }
  }

  DeletePharmacySystem()
  {
    this.pharmacySystems.removeAt(this.itemToRemove);
    ($('#deletePharmcySystemModal') as any).modal('hide');
    this.itemToRemove = -1;
  }
  cancelDeletionPharmacySystem()
  {  
    this.itemToRemove = -1;
    ($('#deletePharmcySystemModal') as any).modal('hide');
  }


  validateFormFields() {
    const pharmacySystemsArray = this.pharmacySystemsForm.get('pharmacySystems') as FormArray;
   
    Object.keys(pharmacySystemsArray['controls']).forEach(element => {
      const control = pharmacySystemsArray.get(element);
      Object.keys(control['controls']).forEach(field => {
        const controlItem = control.get(field);
        if (controlItem instanceof FormControl) {
          controlItem.markAsTouched({ onlySelf: true });
        }
      });
    });
  }

    save() {
      this.validateFormFields();
      if (this.pharmacySystemsForm.valid) {
        this.configService.savePharmacySystems(this.pharmacySystemsForm.value).subscribe(
          //() => this.onSaveComplete('update', true, '0'),
          (res) => {
           //console.log(res);
          this.setNotificationData('update', res);
          this.getPharmacySystems();
        });
      }else{
        this.isSubmitInvalid = true
      }   
    }

    cancel()
    {
      const resourceArray = this.pharmacySystemsForm.get('pharmacySystems') as FormArray;
   
      Object.keys(resourceArray['controls']).forEach(element => {
        const control = resourceArray.get(element);
        Object.keys(control['controls']).forEach(field => {
          const controlItem = control.get(field);
          if (controlItem instanceof FormControl) {
            controlItem.markAsUntouched({ onlySelf: false });
          }
        });
      });
    }

    getPharmacySystems() {
      this.configService.getPharmacySystems().subscribe((res) => {
         //console.log(res);

         const pharmacySystemsArray = this.fbp.array([]);
         res.pharmacySystems.forEach(item => {
           var pharmacySystem = this.createPharmacySystem(item.id, item.name)
           pharmacySystemsArray.push(pharmacySystem);
         });
   
         this.pharmacySystemsForm.setControl('pharmacySystems', pharmacySystemsArray);
      })
    }

    setNotificationData(flow, status): void {
      //console.log(flow);
      //console.log(status);

      if (flow === 'update') {
        if (status) {
          console.log("Upadate Successfull !.");
          this.notificationModal.header = 'Lyckades';
          this.notificationModal.description = 'IT -systemu ppdatering lyckades!';
          this.notificationModal.type = 'success';
        } else {
          console.log("Upadate Failed !.");
          this.notificationModal.header = 'Misslyckades';
          this.notificationModal.description = 'IT-systemuppdatering misslyckades! Var god försök igen.';
          this.notificationModal.type = 'danger';
        }

        //console.log(this.notificationModal);
      }
      ($('#pharmNotificationModal') as any).modal('show');

      setTimeout(() => {
        ($('#pharmNotificationModal') as any).modal('hide');
      }, 1000);
    }
}
