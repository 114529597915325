import { UserService } from './../user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { IUserInfo } from './../user-info';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wfm-superclient-user-view',
  templateUrl: './superclient-user-view.component.html',
  styleUrls: ['./superclient-user-view.component.css']
})
export class SuperclientUserViewComponent implements OnInit {

  
  @Input('employeeNo') employeeProfileId: Subject<string>
  @Input() isViewModal:boolean = false

  pageTitle: string;
  errorMessage: string;
  userTypes: any[];
  jobTypes: any[];
  userInfo: IUserInfo = {} as IUserInfo;
  previewUrl: any;
  userImage = '';
  fileData: File = null;
  private sub: Subscription;
  userForm: FormGroup;
  selectedEmployeeId: string
  empResourceType:any
  resourceTypes: any[]

  countyMunicipalities: any[] = [];
  counties: any[] = [];
  municipalities: any[] = [];
  pharmacySystems: any[] = [];

  jsonCountyMunicipalities: any[] = [];
  legitimations: any[] = [];
  experienceAreas: any[] = [];
  usersList: IUserInfo[] = [];
  skillLevels:any[]=[];

  languages: any[] = [
    { value: 1, label: 'English' },
    { value: 2, label: 'Swedish' }
  ];

  yearsOfExperiences: any[] = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '10+' },
  ];

  deviceWidth:number = window.innerWidth;
  deviceHight:number = window.innerHeight;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService) {

    this.userTypes = [
      { id: '3', name: 'Enhet' }
    ];
  }

  ngOnInit() {

    this.userForm = this.fb.group({
      userId: [''],
      userTypeId: [],
      jobCategoryId: [],
      firstName: [''],
      lastName: [],
      clientName: [],
      pharmacyId: [],
      address: [],
      city: [],
      zip: [],
      email: [],
      phoneNumber: [],
      userName: [],
      password: [],
      conpassword: [],
      isActive: [true],
      county: [{ disabled: true }, []],
      municipality: [[]],
      pharmacySystems: [],
      isStaredEmployeesOnly: [Boolean],
      employeeNo: [''],
      countyMunicipalities: [[]],
      legitimationId: [],
      experienceAreas: [],
      yearsOfExperience: [],
      isStdProceduresRead: [Boolean],
      aboutMe: [''],
      languages: [],
      cmJson: '',
      favouriteEmployees: []
    });

    if (this.isViewModal) {
      this.employeeProfileId.subscribe((userId)=>{
        this.selectedEmployeeId = userId
        if (userId) {
        this.getUser(this.selectedEmployeeId);
        }
      })
    } else {
      this.sub = this.route.paramMap.subscribe(
        params => {
          const id = params.get('userid');
          this.getUser(id);
        }
      );
    }

    
    this.userService.getInnitialMasterData().subscribe((data) => {

      this.pharmacySystems = data.pharmacySystems
      this.counties = data.counties
      this.experienceAreas = data.experienceAreas
      this.legitimations = data.legitimations
      this.skillLevels = data.skillLevels
      this.languages = data.languages

      for (let [key, value] of Object.entries(this.pharmacySystems)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.counties)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.experienceAreas)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.legitimations)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.skillLevels)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }
      for (let [key, value] of Object.entries(this.languages)) {
        // value['value'] = value.id;
        value['label'] = value.name;
      }

    })
  }

  getUser(id: string): void {
    this.userService.getById(id)
      .subscribe(
        (user: IUserInfo) => {
          if (user.countyMunicipalities && user.countyMunicipalities.length>0) {
            this.userService.getMunicipalitiesByCountyId(user.countyMunicipalities[0].countyId).subscribe((data: []) => {
              this.municipalities = data
              for (let [key, value] of Object.entries(this.municipalities)) {
                value['value'] = value.id;
                value['label'] = value.name;
              }
              this.displayUser(user)
            });
          } else {
            this.displayUser(user)
          }
          
        },
        (error: any) => this.errorMessage = error as any
      );
  }

  displayUser(user: IUserInfo): void {
    this.userInfo = user;

    this.userImage = this.userInfo.imagePath;
    this.pageTitle = `Edit User Data: ${this.userInfo.firstName}`;
    const userTypeControl = this.userForm.get('userTypeId');

    if (this.userInfo.cmJson) {
      this.jsonCountyMunicipalities = JSON.parse(this.userInfo.cmJson)
    }

    this.userForm.patchValue({
      userId: this.userInfo.userId,
      userTypeId: this.userInfo.userTypeId,
      jobCategoryId: this.userInfo.jobCategoryId ? this.userInfo.jobCategoryId.toString() : null,
      firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName,
      clientName: this.userInfo.clientName,
      pharmacyId: this.userInfo.pharmacyId,
      address: this.userInfo.address,
      city: this.userInfo.city,
      zip: this.userInfo.zip,
      email: this.userInfo.email,
      phoneNumber: this.userInfo.phoneNumber,
      isActive: this.userInfo.isActive.toString()
    });

    if (this.userInfo.userTypeId == '2') {
      this.userForm.patchValue({
        county: (this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities.length>0)? this.userInfo.countyMunicipalities[0].countyId:null,
        municipality:(this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities.length>0)? this.userInfo.countyMunicipalities[0].municipalityId:null,
        pharmacySystems: this.userInfo.pharmacySystems,
        legitimationId: this.userInfo.legitimationId,
        experienceAreas: this.userInfo.experienceAreas,
        yearsOfExperience: this.userInfo.yearsOfExperience,
        isStdProceduresRead: this.userInfo.isStdProceduresRead,
        aboutMe: this.userInfo.aboutMe,
        languages: this.userInfo.languages,
        employeeNo: this.userInfo.employeeNo
      });
      this.countyMunicipalities = this.userInfo.countyMunicipalities
      this.empResourceType = this.resourceTypes.find(x=>x.id==this.userInfo.resourceTypeId);
    }

    if (this.userInfo.userTypeId == '3') {
      this.userForm.patchValue({
        county: this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities[0]? this.userInfo.countyMunicipalities[0].countyId:null,
        municipality: this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities[0]?  this.userInfo.countyMunicipalities[0].municipalityId:null,
        pharmacySystems:  this.userInfo.pharmacySystems,
        isStaredEmployeesOnly:   this.userInfo.isStaredEmployeesOnly,
        // favouriteEmployees: this.userInfo.favouriteEmployees
      });
      this.userForm.value.municipality = this.userInfo.countyMunicipalities && this.userInfo.countyMunicipalities[0]? this.userInfo.countyMunicipalities[0].municipalityId:null

      // this.userForm.value.favouriteEmployees = this.userInfo.favouriteEmployees
      // this.userService.getUserList('Employee').subscribe(
      //   (res: IUserInfo[]) => {
      //     this.usersList = res;
      //     for (let [key, value] of Object.entries(this.usersList)) {
      //       value['value'] = value.userId;
      //       value['label'] = value.firstName + ' ' + value.lastName;
      //     }
      //     this.userForm.patchValue({
      //       county: this.userInfo.countyMunicipalities? this.userInfo.countyMunicipalities[0].countyId:'',
      //       municipality: this.userInfo.countyMunicipalities? this.userInfo.countyMunicipalities[0].municipalityId:'',
      //       pharmacySystems: this.userInfo.pharmacySystems,
      //       isStaredEmployeesOnly: this.userInfo.isStaredEmployeesOnly,
      //       // favouriteEmployees: this.userInfo.favouriteEmployees
      //     });
          
      //     this.userForm.value.municipality = this.userInfo.countyMunicipalities? this.userInfo.countyMunicipalities[0].municipalityId:''
      //     this.userForm.value.favouriteEmployees = this.userInfo.favouriteEmployees
      //   },
      //   (error: any) => this.errorMessage = error as any
      // );

    }
  }

  getPharmacySystems(){
    let str:string='';
    if (this.userInfo.pharmacySystems) {
      this.userInfo.pharmacySystems.forEach(element => {
        let obj = this.pharmacySystems.find((e)=>e.value==element)
        str = str+obj.label+', '
      });
      var newStr = str.slice(0, -2);
      return newStr
    } else {
      return '-'
    }
    
  }
  getUserType(id){
    var element = this.userTypes.find((e)=>e.id==id)
    return element? element.label:'-'
  }

  getCountyName(){
    var element = this.counties.find((e)=>e.value==this.userForm.value.county)
    return element? element.label:'-'
  }
  getMunicipalityName(){
    var element = this.municipalities.find((e)=>e.value==this.userForm.value.municipality)
    return element? element.label:'-'
  }
  getLeditimationName(){
    if (this.legitimations) {
      var element = this.legitimations.find((e)=>e.value==this.userForm.value.legitimationId)
    }
    return element? element.label:'-'
  }
  getExperience(){
    if (this.yearsOfExperiences) {
      var element = this.yearsOfExperiences.find((e)=>e.value==this.userForm.value.yearsOfExperience)
    }
    return element? element.label:'-'
  }
  getExperienceAreas(){
    if (this.userInfo.experienceAreas) { 
      let str:string='';
      this.userInfo.experienceAreas.forEach(element => {
        let obj = this.experienceAreas.find((e)=>e.value==element)
        str = str+obj.label+', '
      });
      var newStr = str.slice(0, -2);
      return newStr
    }
  }
  getLanguageById(langId:number){
    if (this.languages) {
      var element = this.languages.find((e)=>e.value==langId)
      return element? element.label:'-'
    }
  }
  getSkillLevelById(skillLevelId:number){
    if (this.skillLevels) {
    var element = this.skillLevels.find((e)=>e.value==skillLevelId)
    }
    return element? element.label:'-'
  }
  getFavouriteEmployees(){
    let str:string='';
    let newStr:string='';
    if ( this.userInfo.favouriteEmployees) {
      this.userInfo.favouriteEmployees.forEach(element => {
        let obj = this.usersList.find((e)=>e.userId==element)
        if (obj) {
          str = str+obj.fullName+', '
        }
      });
    }
    newStr = str.slice(0, -2);
    return newStr||'-'
  }
  getMunicipalities(mArray){
    let str:string='';
    let newStr:string='';
    if ( mArray) {
      mArray.forEach(element => {
        str += element.name+', '
      });
    }
    newStr = str.slice(0, -2);
    return newStr||'-'
  }

  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
      // console.log(res);
      this.resourceTypes = res.resourceTypes
      
    })
  }

}
