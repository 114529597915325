import { Component, OnInit, AfterViewInit, ElementRef, ViewChildren, OnDestroy, Input } from '@angular/core';
import * as jQuery from 'jquery';
import { CalendarService } from './../calendar.service';
import { AllocationInfo } from './../allocation-info';
import { AvailabilityModel } from './../availability-model';
import { AvailabilityInfo } from './../availability-info';
import { BookingInfo } from './../booking-info';
import { IUserInfo } from '../../user/user-info';
import { FormBuilder, FormGroup, Validators, FormControlName } from '@angular/forms';
import { TimeLogModel } from './../time-log-model';
import { ClientBookingInfo } from './../client-booking-info';
import { AvailabilityUpdateModel } from './../availability-update-model';
import { GenericValidator } from '../../shared/generic-validator';
import { Observable, fromEvent, merge, Subscription, Subject, EMPTY } from 'rxjs';
import { catchError, debounceTime, filter } from 'rxjs/operators';
import { PartialAvailabilityModel } from './../partial-availability-model';
import { PartialAvailabilityInfo } from './../partial-availability-info';
import { AlliocationModel } from './../alliocation-model';
import { UserService } from '../../user/user.service';
import { ActivatedRoute } from '@angular/router';
import { SignalrService } from 'src/app/shared/services/common/signalr.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { data } from 'jquery';
import SwedishHolidays from 'src/app/shared/constants/swedishHolidays';
import { LongBookingService } from 'src/app/shared/services/domain/booking/long-booking.service';
declare var kendo: any;

@Component({
    selector: 'wfm-booking-calendar',
    templateUrl: './booking-calendar.component.html',
    styleUrls: ['./booking-calendar.component.css']
})
export class BookingCalendarComponent implements OnInit {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];


    //   @Input() selectedUserId: Subject<any>
    //   @Input() selectedUserType: Subject<string>
    //   @Input() role: string
    showExpAreaDetails: boolean;
    bookingModalHeader = "Make Long Bookings";
    longBookingSelected: boolean;
    selectedExpAreas: any[] = [];
    swedishHolidays: typeof SwedishHolidays;
    role: string;
    userId: string;
    userType: string;
    availabilityCheckDates: AvailabilityModel[];
    errorMessage: string;
    allocation: BookingInfo;
    visibleTimeLogs = false;
    updateAllocationId: number;
    isSwapEmployeeSelected: boolean;
    swapUserList: any[];
    swapUserForm: FormGroup;
    allocationTimeLogsForm: FormGroup;
    allocationForm: FormGroup;
    allocationFormLongBooking: FormGroup;
    allocationTimeUpdateForm: FormGroup;
    selectedUserFullName: string;
    selectedUserAllocationTimes: string;
    notificationModal: any;
    xnotificationModal: any;

    availabilityForm: FormGroup;
    leaveForm: FormGroup;
    commentForm: FormGroup;
    selfBookingForm: FormGroup;

    startTime: any;
    endTime: any;

    availabilityUpdateModel: AvailabilityUpdateModel;
    currentDate = new Date();
    isWeekend = true;
    displayValidationMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    subscription: Subscription;

    partialAvailabilityForm: FormGroup;
    selectedPartialAvlDate: string;
    partialAvailableListForDay: PartialAvailabilityInfo[];
    allocationTryDate: Date;
    allocationTryTime: string;
    allocationTryLunch: number;
    selectedResourceTypeId = 1;
    resourceTypeForAvalReq = 1;
    resourceTypes: any[];
    selectedCalendarMonth: Subject<Date> = new Subject<Date>();
    availabilityData: AvailabilityInfo[];
    isBookingSelected = true;
    isBookingFromPartialAvailability: boolean = false;
    holidayLastMonth: string
    availableEmployees: any[] = []
    preSystemSelectedEmployeeId: Subject<string> = new Subject<string>();
    clientCommonTimes: any[] = []
    isAllocationTimeEdit: boolean;
    allocationHistory: any
    showHistory: boolean = false
    swapAccessed: boolean = false
    isInquiryCalendarSelected: boolean = false
    employeeProfileId: Subject<string> = new Subject<string>();
    isPartialBooking: boolean = true;
    IsOnChangeEvent: boolean = false;
    deviceWidth: number = window.innerWidth;
    deviceHight: number = window.innerHeight;
    Infinity = Infinity;
    isMobile: boolean = window.innerWidth < 768 ? true : false
    sendRequest: any;
    experienceAreas: any[];
    maxCallCOunt = 0;
    showExperienceAreas: boolean;
    disableExpAreasDropdown: boolean;

    isLoadOnSubscription: boolean = false
    selecteUserSubscription: Subscription;
    routeParamSubscription: Subscription;
    newSelectedUserSub: Subscription;
    onClickMakeBookingSub: Subscription;
    onClickMakeLongBookingSub: Subscription;
    onClickMakeInquirySub: Subscription;
    OnClickAvailabilityUpdateSub: Subscription;
    onResourceTypeChangeSub: Subscription;

    defaultSchedulerView: string = 'month'

    userListAction: Subject<string> = new Subject<string>()
    selectedDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
    viewStartDate: Date
    viewEndDate: Date
    configClientIsAbleToSelectTheEmployeesInBooking: boolean = false;
    configDisableUpdatingEmpAvailability: boolean;
    configClientEditBookingTime: boolean;
    disableGetAvailableEmployees: boolean;
    timeSlots: any[] = [];
    clients: any[] = [];
    selfBookingStartDate: any;

    timePickerTimeList: any[] = [
        { value: '00:00', label: '00:00' },
        { value: '00:15', label: '00:15' },
        { value: '00:30', label: '00:30' },
        { value: '00:45', label: '00:45' },
        { value: '01:00', label: '01:00' },
        { value: '01:15', label: '01:15' },
        { value: '01:30', label: '01:30' },
        { value: '01:45', label: '01:45' },
        { value: '02:00', label: '02:00' },
        { value: '02:15', label: '02:15' },
        { value: '02:30', label: '02:30' },
        { value: '02:45', label: '02:45' },
        { value: '03:00', label: '03:00' },
        { value: '03:15', label: '03:15' },
        { value: '03:30', label: '03:30' },
        { value: '03:45', label: '03:45' },
        { value: '04:00', label: '04:00' },
        { value: '04:15', label: '04:15' },
        { value: '04:30', label: '04:30' },
        { value: '04:45', label: '04:45' },
        { value: '05:00', label: '05:00' },
        { value: '05:15', label: '05:15' },
        { value: '05:30', label: '05:30' },
        { value: '05:45', label: '05:45' },
        { value: '06:00', label: '06:00' },
        { value: '06:15', label: '06:15' },
        { value: '06:30', label: '06:30' },
        { value: '06:45', label: '06:45' },
        { value: '07:00', label: '07:00' },
        { value: '07:15', label: '07:15' },
        { value: '07:30', label: '07:30' },
        { value: '07:45', label: '07:45' },
        { value: '08:00', label: '08:00' },
        { value: '08:15', label: '08:15' },
        { value: '08:30', label: '08:30' },
        { value: '08:45', label: '08:45' },
        { value: '09:00', label: '09:00' },
        { value: '09:15', label: '09:15' },
        { value: '09:30', label: '09:30' },
        { value: '09:45', label: '09:45' },
        { value: '10:00', label: '10:00' },
        { value: '10:15', label: '10:15' },
        { value: '10:30', label: '10:30' },
        { value: '10:45', label: '10:45' },
        { value: '11:00', label: '11:00' },
        { value: '11:15', label: '11:15' },
        { value: '11:30', label: '11:30' },
        { value: '11:45', label: '11:45' },
        { value: '12:00', label: '12:00' },
        { value: '12:15', label: '12:15' },
        { value: '12:30', label: '12:30' },
        { value: '12:45', label: '12:45' },
        { value: '13:00', label: '13:00' },
        { value: '13:15', label: '13:15' },
        { value: '13:30', label: '13:30' },
        { value: '13:45', label: '13:45' },
        { value: '14:00', label: '14:00' },
        { value: '14:15', label: '14:15' },
        { value: '14:30', label: '14:30' },
        { value: '14:45', label: '14:45' },
        { value: '15:00', label: '15:00' },
        { value: '15:15', label: '15:15' },
        { value: '15:30', label: '15:30' },
        { value: '15:45', label: '15:45' },
        { value: '16:00', label: '16:00' },
        { value: '16:15', label: '16:15' },
        { value: '16:30', label: '16:30' },
        { value: '16:45', label: '16:45' },
        { value: '17:00', label: '17:00' },
        { value: '17:15', label: '17:15' },
        { value: '17:30', label: '17:30' },
        { value: '17:45', label: '17:45' },
        { value: '18:00', label: '18:00' },
        { value: '18:15', label: '18:15' },
        { value: '18:30', label: '18:30' },
        { value: '18:45', label: '18:45' },
        { value: '19:00', label: '19:00' },
        { value: '19:15', label: '19:15' },
        { value: '19:30', label: '19:30' },
        { value: '19:45', label: '19:45' },
        { value: '20:00', label: '20:00' },
        { value: '20:15', label: '20:15' },
        { value: '20:30', label: '20:30' },
        { value: '20:45', label: '20:45' },
        { value: '21:00', label: '21:00' },
        { value: '21:15', label: '21:15' },
        { value: '21:30', label: '21:30' },
        { value: '21:45', label: '21:45' },
        { value: '22:00', label: '22:00' },
        { value: '22:15', label: '22:15' },
        { value: '22:30', label: '22:30' },
        { value: '22:45', label: '22:45' },
        { value: '23:00', label: '23:00' },
        { value: '23:15', label: '23:15' },
        { value: '23:30', label: '23:30' },
        { value: '23:45', label: '23:45' },
    ]

    lunchTimeList: any[] = [
        { value: 0, label: '0' },
        { value: 15, label: '15' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
        { value: 45, label: '45' },
        { value: 60, label: '60' },
    ]

    @Input() bookingComponent: any
    constructor(
        private longBookingService: LongBookingService,
        private configService: ConfigService, private fb: FormBuilder, private userService: UserService,
         private calendarService: CalendarService, private signalRService: SignalrService, 
         private userservice: UserService,
         private route: ActivatedRoute,
        private elementRef: ElementRef) {
        this.validationMessages = {
            month: {
                required: 'month is required'
            },
            startDate: {
                required: 'start is required'
            },
            endDate: {
                required: 'end is required'
            }
        };
        this.genericValidator = new GenericValidator(this.validationMessages);

        this.subscription = this.signalRService.receiveCalendarUpdates().subscribe(st => {
            if (st) {
                const scheduler = $('#scheduler').data('kendoScheduler');
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                //   scheduler.refresh();
            }
        });

        this.route.queryParams.subscribe(params => {
            if (params['view']) {
                this.defaultSchedulerView = params['view'];
            }
        });

        this.resourceTypes = [];
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
        this.newSelectedUserSub.unsubscribe();
        this.routeParamSubscription.unsubscribe();
        this.onClickMakeBookingSub.unsubscribe();
        this.onClickMakeLongBookingSub.unsubscribe();
        this.OnClickAvailabilityUpdateSub.unsubscribe();
        this.onResourceTypeChangeSub.unsubscribe();
    }

    closeLongBookingModal() {
        setTimeout(() => {
            ($('#longBookingModal') as any).modal('show');

        }, 100);
    }

    closeDeleteLongBookingModal(response: boolean) {
        if (response) {
            ($('#requestAllocationForLongBookinModal') as any).modal('hide');
            // location.reload();
        }

        ($('#longBookingModal') as any).modal('hide');
        $('.modal-backdrop').remove();

    }

    setCustomVallidations() {
        const month = this.availabilityForm.get('month');
        const start = this.availabilityForm.get('startDate');
        const end = this.availabilityForm.get('endDate');

        this.availabilityForm.get('changeTypeId').valueChanges
            .subscribe(changeType => {
                if (changeType === '1') {
                    month.setValidators([Validators.required]);
                    start.clearValidators();
                    end.clearValidators();
                } else if (changeType === '2') {
                    start.setValidators([Validators.required]);
                    end.setValidators([Validators.required]);
                    month.clearValidators();
                } else if (changeType === '3') {
                    start.setValidators([Validators.required]);
                    month.clearValidators();
                    end.clearValidators();
                }
                month.updateValueAndValidity();
                start.updateValueAndValidity();
                end.updateValueAndValidity();
            });

    }

    ngOnInit() {
        this.getClientList();

        this.timeSlots = [
            { id: '1', name: '00:00' },
            { id: '2', name: '00:15' },
            { id: '3', name: '00:30' },
            { id: '4', name: '00:45' },
            { id: '5', name: '01:00' },
            { id: '6', name: '01:15' },
            { id: '7', name: '01:30' },
            { id: '8', name: '01:45' },
            { id: '9', name: '02:00' },
            { id: '10', name: '02:15' },
            { id: '11', name: '02:30' },
            { id: '12', name: '02:45' },
            { id: '13', name: '03:00' },
            { id: '14', name: '03:15' },
            { id: '15', name: '03:30' },
            { id: '16', name: '03:45' },
            { id: '17', name: '04:00' },
            { id: '18', name: '04:15' },
            { id: '19', name: '04:30' },
            { id: '20', name: '04:45' },
            { id: '21', name: '05:00' },
            { id: '22', name: '05:15' },
            { id: '23', name: '05:30' },
            { id: '24', name: '05:45' },
            { id: '25', name: '06:00' },
            { id: '26', name: '06:15' },
            { id: '27', name: '06:30' },
            { id: '28', name: '06:45' },
            { id: '29', name: '07:00' },
            { id: '30', name: '07:15' },
            { id: '31', name: '07:30' },
            { id: '32', name: '07:45' },
            { id: '33', name: '08:00' },
            { id: '34', name: '08:15' },
            { id: '35', name: '08:30' },
            { id: '36', name: '08:45' },
            { id: '37', name: '09:00' },
            { id: '38', name: '09:15' },
            { id: '39', name: '09:30' },
            { id: '40', name: '09:45' },
            { id: '41', name: '10:00' },
            { id: '42', name: '10:15' },
            { id: '43', name: '10:30' },
            { id: '44', name: '10:45' },
            { id: '45', name: '11:00' },
            { id: '46', name: '11:15' },
            { id: '47', name: '11:30' },
            { id: '48', name: '11:45' },
            { id: '49', name: '12:00' },
            { id: '50', name: '12:15' },
            { id: '51', name: '12:30' },
            { id: '52', name: '12:45' },
            { id: '53', name: '13:00' },
            { id: '54', name: '13:15' },
            { id: '55', name: '13:30' },
            { id: '56', name: '13:45' },
            { id: '57', name: '14:00' },
            { id: '58', name: '14:15' },
            { id: '59', name: '14:30' },
            { id: '60', name: '14:45' },
            { id: '61', name: '15:00' },
            { id: '62', name: '15:15' },
            { id: '63', name: '15:30' },
            { id: '64', name: '15:45' },
            { id: '65', name: '16:00' },
            { id: '66', name: '16:15' },
            { id: '67', name: '16:30' },
            { id: '68', name: '16:45' },
            { id: '69', name: '17:00' },
            { id: '70', name: '17:15' },
            { id: '71', name: '17:30' },
            { id: '72', name: '17:45' },
            { id: '73', name: '18:00' },
            { id: '74', name: '18:15' },
            { id: '75', name: '18:30' },
            { id: '76', name: '18:45' },
            { id: '77', name: '19:00' },
            { id: '78', name: '19:15' },
            { id: '79', name: '19:30' },
            { id: '80', name: '19:45' },
            { id: '81', name: '20:00' },
            { id: '82', name: '20:15' },
            { id: '83', name: '20:30' },
            { id: '84', name: '20:45' },
            { id: '85', name: '21:00' },
            { id: '86', name: '21:15' },
            { id: '87', name: '21:30' },
            { id: '88', name: '21:45' },
            { id: '89', name: '22:00' },
            { id: '90', name: '22:15' },
            { id: '91', name: '22:30' },
            { id: '92', name: '22:45' },
            { id: '93', name: '23:00' },
            { id: '94', name: '23:15' },
            { id: '95', name: '23:30' },
            { id: '96', name: '23:45' }
        ];
        this.getAdminConfigurationDetails();
        this.getUserWiseAdminConfigurationDetails(null);
        this.onClickLongBooking();
        this.swedishHolidays = SwedishHolidays;
        kendo.culture('sv-SE');
        this.currentDate.setHours(0, 0, 0, 0);
        this.allocation = {
            isExpAreaValidationFail: null,
            id: 0,
            title: '',
            sDate: '',
            eDate: '',
            lunch: 0,
            isPastDate: false,
            bookingTotal: 0,
            start: new Date(),
            end: new Date(),
            empName: '',
            empImage: '',
            empJobCategoryId: 0,
            employeeId: '',
            empJobCategory: '',
            clientName: '',
            clientContactName: '',
            clientAddress: '',
            clientPhone: '',
            clientEmail: '',
            isTimeLogged: false,
            timeLogStartTime: '',
            timeLogEndTime: '',
            timeLogLunch: 0,
            timeLogTotal: 0,
            colorClass: 'slot-blue',
            timeLoggedClass: 'slot-no-check',
            empImagePath: '',
            clientImagePath: '',
            resourceTypeId: 1,
            resourceTypeClass: '',
            isInquiry: null,
            clientId: '',
            longBookingId: null,
            distance: null,
            travelTime: null,
            resourceTypeIconLetter: '',
            message: null
        };

        this.notificationModal = {
            header: '',
            description: '',
            type: ''
        };
        this.initializeForms();

        this.setCustomVallidations();

        // Time auto fill on change allocation form
        $('#kendo-wd-start').on('change', () => {
            let loadData: boolean = true
            if ($('#kendo-wd-start').val().toString().length == 2 || $('#kendo-wd-start').val().toString().length == 1) {
                loadData = false
                if ($('#kendo-wd-start').val().toString().length == 2) {
                    let newVal = $('#kendo-wd-start').val() + ':00'
                    $('#kendo-wd-start').val(newVal)
                    $('#kendo-wd-start').trigger('change')
                }
                if ($('#kendo-wd-start').val().toString().length == 1) {
                    let newVal = '0' + $('#kendo-wd-start').val() + ':00'
                    $('#kendo-wd-start').val(newVal)
                    $('#kendo-wd-start').trigger('change')
                }
            }
            setTimeout(() => {
                if (loadData) {
                    this.setAvailableEmployees()
                }
            }, 100);
        })

        $('#kendo-wd-end').on('change', () => {
            let loadData: boolean = true
            if ($('#kendo-wd-end').val().toString().length == 2 || $('#kendo-wd-end').val().toString().length == 1) {
                loadData = false
                if ($('#kendo-wd-end').val().toString().length == 2) {
                    let newVal = $('#kendo-wd-end').val() + ':00'
                    $('#kendo-wd-end').val(newVal)
                    $('#kendo-wd-end').trigger('change')
                }
                if ($('#kendo-wd-end').val().toString().length == 1) {
                    let newVal = '0' + $('#kendo-wd-end').val() + ':00'
                    $('#kendo-wd-end').val(newVal)
                    $('#kendo-wd-end').trigger('change')
                }
            }
            setTimeout(() => {
                if (loadData) {
                    this.setAvailableEmployees()
                }
            }, 100);
        })

        $('#kendo-end-date').on('change', (event) => {

            let date = new Date((<HTMLInputElement>event.target).value);

            const filterData = this.availabilityData.find((data) => {
                return (data.startTime.toDateString() === date.toDateString());
            });

            // this.isBookingSelected = true;
            if (!filterData || !filterData.isAvailable) {
                this.isBookingSelected = false;
            }

            let scheduler = $("#scheduler").data("kendoScheduler");
            scheduler.select(null, null);

            if (!this.disableGetAvailableEmployees) {
                setTimeout(() => {
                    this.setAvailableEmployees()
                }, 100);
            }
        })

        $(window).resize(() => {
            const scheduler = $('#scheduler').data('kendoScheduler');
            //   scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
            if (scheduler) {
                scheduler.refresh();
            }
        })

        this.routeParamSubscription = this.route.data.subscribe(data => {
            this.role = data.role
            if (this.role == 'Employee') {
                this.userType = 'Employee'
            }
        });

        this.newSelectedUserSub = this.userservice.newSelectedUser.subscribe((data) => {
            if (this.isLoadOnSubscription) {
                setTimeout(() => {
                    this.loadCalendar(data);
                }, 100);
            } else if (data && data.userId) {
                this.userId = data.userId
                this.userType = data.userType
            }
        })

        this.onClickMakeBookingSub = this.calendarService.onClickMakeBooking.subscribe((res) => {
            if (res) {
                this.disableGetAvailableEmployees = false;
                this.onClickMakeBooking()
            }
        })

        this.onClickMakeLongBookingSub = this.calendarService.onClickMakeLongBooking.subscribe((res) => {
            if (res) {
                this.disableGetAvailableEmployees = false;
                this.onClickMakeLongBooking()
            }
        })

        this.onClickMakeInquirySub = this.calendarService.onClickMakeInquiry.subscribe((res) => {
            if (res) {
                this.disableGetAvailableEmployees = true;
                this.directCreateInquiry()
            }
        })

        this.OnClickAvailabilityUpdateSub = this.calendarService.OnClickAvailabilityUpdate.subscribe((res) => {
            if (res) {
                this.disableGetAvailableEmployees = false;
                ($('#updateAvailabilityModal') as any).modal('show');
            }
        })
        this.onResourceTypeChangeSub = this.calendarService.onResourceTypeChange.subscribe((res: number) => {
            if (res) {
                this.onResourceTypeChange(res)
            }
        })

        var date = new Date();
        this.viewStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.viewEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 1, 1);
        this.getCurrentConfigData();
        this.getMasterData();
        this.getAdminCOnfigurations();
    }

    DeleteLongBooking(id: number) {
        this.longBookingService.deleteLongBooking(id).pipe(catchError(() => {

            this.notificationModal.header = 'Failed'; // Failed
            this.notificationModal.description = 'Long booking delete failed !!!'; //Inquiry adding failed! Please try again.
            this.notificationModal.type = 'danger';
            return EMPTY;
        })).subscribe((data) => {
            ($('#allocationDeleteModal') as any).modal('hide');
            this.longBookingSelected = false;
            this.notificationModal.header = 'Long booking deleted'; // Failed
            this.notificationModal.description = 'Long booking deleted success !!!'; //Inquiry adding failed! Please try again.
            this.notificationModal.type = 'success';
            ($('#xnotificationModal') as any).modal('show');
            setTimeout(() => {
                ($('#xnotificationModal') as any).modal('hide');
            }, 1000);
        });


    }

    onClickLongBooking() {
        this.calendarService.onClickMakeLongBooking.subscribe((data) => {
            this.bookingModalHeader = "Skapa Långtidsbokning";
            this.longBookingSelected = true;
            this.isBookingSelected = false;
        })
    }

    getClientList(): void {
        this.userService.getAdminUserList('client', new Date(), 0).subscribe(
            (res: IUserInfo[]) => {
                this.clients = res;
            },
            (error: any) => {
                this.errorMessage = error as any;
            }
        );
    }

    editLongBooking(allocation: any) {
        this.calendarService.emmitLongBookingDetails(allocation);
        ($('#employeeBookingModal') as any).modal('hide');
        this.bookingModalHeader = "Edit Long Booking";
        this.longBookingSelected = true;
        setTimeout(() => {
            ($('#requestAllocationForLongBookinModal') as any).modal('show');
        }, 500);
    }

    getAdminCOnfigurations() {
        this.configService.getAdminCOnfigurations().subscribe(data => {
            if (data) {
                this.showExperienceAreas = !data.find(a => a.title === 'configDisableExperienceAreas').isActive;
            }
        })
    }


    initializeForms(): void {
        this.allocationForm = this.fb.group({
            startDate: [],
            experienceAreas: [] = [],
            endDate: ['', Validators.required],
            weekdayStart: ['', Validators.required],
            weekdayEnd: ['', Validators.required],
            weekdayLunch: ['', Validators.required],
            weekendStart: [],
            weekendEnd: [],
            weekendLunch: [],
            clientId: [],
            resourceTypeId: ['1'],
            holidays: [''],
            isInquiry: [],
            employeeId: [],
            employeeIds:[],
            message: null,
            isPartialBooking: [false]
        });


        this.swapUserForm = this.fb.group({
            allocationId: [],
            swapUserId: [],
            swapType: []
        });

        this.allocationTimeLogsForm = this.fb.group({
            allocationId: [],
            isTimeLogged: [],
            startTime: [],
            endTime: [],
            lunchTime: [],
            travelTime: 0,
            distance: 0
        });

        this.availabilityForm = this.fb.group({
            changeTypeId: ['1'],
            month: ['', Validators.required],
            startDate: [''],
            endDate: [''],
            availabilityId: ['1']
        });

        this.leaveForm = this.fb.group({
            leaveTypeId: ['1'],
            fromDate: ['', Validators.required],
            toDate: [''],
            comment: ''
        });

        this.commentForm = this.fb.group({
            fromDate: ['', Validators.required],
            toDate: [''],
            comment: ['', Validators.required]
        });

        this.selfBookingForm = this.fb.group({
            startDate: [],
            experienceAreas: [] = [],
            endDate: [''],
            weekdayStart: [''],
            weekdayEnd: [''],
            weekdayLunch: [''],
            weekendStart: [],
            weekendEnd: [],
            weekendLunch: [],
            clientId: [],
            resourceTypeId: ['1'],
            holidays: [''],
            isInquiry: [],
            employeeId: [],
            message: null,
            isPartialBooking: [false]
        });
        this.partialAvailabilityForm = this.fb.group({
            avlTypeId: [],
            avlDate: [],
            avlStart: [],
            avlEnd: []
        });

        this.allocationTimeUpdateForm = this.fb.group({
            allocationId: [],
            startDate: [],
            endDate: [],
            lunchTime: []
        })

    }

    // get f() { return this.swapUserForm.controls; }


    ngAfterViewInit() {


        $(document).ready(function () {
            $(window).scrollTop(0);

            if (!$('.mob-inquiry').hasClass('outside-click')) {
                $('.mob-bookings').removeClass('tab-inactive');
                $('.mob-bookings').addClass('btn-success');
                $('.mob-inquiry').removeClass('outside-click');
            }

        });

        // $('.k-scheduler-header .k-scheduler-table tr  th:nth-child(1)').css('background-color', 'red');
        const calendarService = this.calendarService;
        let userId = this.userId;
        let role = this.role;
        let isInquiryCalendarSelected = this.isInquiryCalendarSelected;
        let viewStartDate = this.viewStartDate
        let viewEndDate = this.viewEndDate
        let patentThis = this


        const customAgenda = kendo.ui.AgendaView.extend({
            endDate() {
                const date = kendo.ui.AgendaView.fn.startDate.call(this);
                return kendo.date.addDays(date, 30);
            }
        });

        // const customMonthView = kendo.ui.MonthView.extend({
        // });
        var eventHeight: number
        var eventsPerDay = 2
        var isAdaptiveSlotHeight: boolean
        if (this.deviceWidth < 576) {
            eventHeight = 60
            isAdaptiveSlotHeight = true
        } else if (this.deviceWidth < 768) {
            eventHeight = 43
            isAdaptiveSlotHeight = true
        } else if (this.deviceWidth < 992) {
            eventHeight = 43
            isAdaptiveSlotHeight = false
        } else if (this.deviceWidth < 1200) {
            eventHeight = 31
            isAdaptiveSlotHeight = false
        } else {
            isAdaptiveSlotHeight = false
            eventHeight = 30
        }

        ($('#scheduler') as any).kendoScheduler({
            views: [
                {
                    type: 'month',
                    eventsPerDay: eventsPerDay,
                    eventHeight: eventHeight,
                    adaptiveSlotHeight: isAdaptiveSlotHeight,
                    selected: this.defaultSchedulerView == 'month' ? true : false

                },
                {
                    type: 'day',
                    allDaySlot: false,
                    eventTemplate: $('#event-day-template').html(),
                    selected: this.defaultSchedulerView == 'day' ? true : false
                },
                {
                    type: customAgenda,
                    title: 'Agenda',
                    eventTemplate: $('#event-agenda-template').html(),
                    selectedDateFormat: "{0:dd/MM/yyyy} - {1:dd/MM/yyyy}",
                    selectedShortDateFormat: "{0:dd/MM} - {1:dd/MM}",
                    selected: this.defaultSchedulerView == 'agenda' ? true : false
                }
            ],
            // { type: 'agenda', eventTemplate: $('#event-agenda-template').html() }],
            eventTemplate: ($('#booking-event-slot-template') as any).html(),
            timezone: 'Europe/Stockholm',
            majorTimeHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'HH:mm\')#</strong>'),
            dateHeaderTemplate: kendo.template("<strong>#=kendo.toString(date, 'ddd dd/M')#</strong>"),
            selectable: true,
            editable: {
                destroy: false,
                move: false,
                resize: false,
                create: false
            },
            dataSource:
            {
                transport: {
                    read(o) {
                        let loadData = false;
                        if (!o.data.userId && !userId) {
                            loadData = true;
                            userId = '';
                        } else if (o.data.userId && !userId) {
                            loadData = true;
                            userId = o.data.userId;
                        } else if (o.data.userId && userId) {
                            loadData = true;
                            userId = o.data.userId;
                        } else if (!o.data.userId && userId) {
                            loadData = true;
                        }
                        viewStartDate = o.data.viewStartDate ? o.data.viewStartDate : viewStartDate
                        viewEndDate = o.data.viewEndDate ? o.data.viewEndDate : viewEndDate

                        if (loadData) {
                            calendarService.getAllocations(userId, role, viewStartDate, viewEndDate)
                                .subscribe(
                                    (allocations: BookingInfo[]) => {
                                        o.success(allocations);
                                    },
                                    (error: any) => this.errorMessage = error as any
                                );
                        } else {
                            o.success([]);
                        }

                    }

                }
            },
            toolbar: ['pdf'],
            pdf: {
                fileName: 'Bookings-Export.pdf',
            }
        });

        const scheduler = $('#scheduler').data('kendoScheduler');


        scheduler.wrapper.on('mouseup', '.k-scheduler-table td, .k-event', function (e) {
            const target = $(e.currentTarget);
            if (target.hasClass('k-event')) {
                const event = scheduler.occurrenceByUid(target.data('uid'));
                scheduler.editEvent(event);
            }
        });

        scheduler.bind('edit', (event) => {
            event.preventDefault();
            $('#kendo-tl-start').data('kendoTimePicker')
            $('#kendo-tl-end').data('kendoTimePicker')
            this.schedulerEdit(event);
        });

        scheduler.bind('navigate', (e) => {
            this.setViewDateRange(e)
            if (e.action == 'next') {
                $('.k-state-default.k-header.k-nav-next').addClass('k-state-hover');
            }
            if (e.action == 'previous') {
                $('.k-state-default.k-header.k-nav-prev').addClass('k-state-hover');
            }
            //   this.selectedCalendarMonth.next(e.date);
            this.calendarService.emmitSelectedMonth(e.date)
            $('.k-scheduler-tools').hide();
            if (e.view === 'day') {
                e.sender.select(null);
                setTimeout(() => {
                    this.scrollToHour(8);
                }, 200);
            }
            if (e.view === 'Agenda') {
                if (!kendo.support.mobileOS) {
                }
            }
        });

        scheduler.bind('dataBound', (e) => {
            if (!this.isInquiryCalendarSelected) {
                this.schedulerDataBound(scheduler);
                setTimeout(() => {
                    $('.k-scheduler-navigation').css('pointer-events', 'all');
                    $('.k-scheduler-navigation').css('opacity', '1');
                    $('.k-state-default.k-header.k-nav-prev').removeClass('k-state-hover');
                    $('.k-state-default.k-header.k-nav-next').removeClass('k-state-hover');
                }, 500);
            }
        });


        scheduler.bind('change', (event) => {
            const eventStartDate = new Date(event.start);
            const eventEndDate = new Date(event.end);
            $('.k-scheduler-table td').removeClass('cell-celected');
            this.selectedDate = eventStartDate
            if (eventStartDate >= this.currentDate && (this.userType === 'Client' && this.userId != 'all') && role != 'Employee') {
                if (event != null && event.slots != null && event.slots.length > 0) {
                    $(event.slots[0].element).addClass('cell-celected');
                }
            }

            const filterData = this.availabilityData.find((data) => {
                return (data.startTime.toDateString() === event.start.toDateString());
            });

            this.isBookingSelected = true;
            if (!filterData || !filterData.isAvailable) {
                this.isBookingSelected = false;
            }

            // Following is required because event end date is set to next day 00:00:00
            const endDate = new Date(eventEndDate.setDate(eventEndDate.getDate() + -1));

            this.isWeekend = (endDate.getDay() === 6) || (endDate.getDay() === 0);
            const startTime = (this.isWeekend ? '10:00' : '09:00');
            const endTime = (this.isWeekend ? '15:00' : '18:00');
            const lunch = (this.isWeekend ? 0 : 45);

            if (this.isWeekend) {
                var startInitTime = new Date(2000, 0, 0, 10, 0, 0);
                var endInitTime = new Date(2000, 0, 0, 15, 0, 0);
                this.setEndTimeMin(startInitTime);
                this.setStartTimeMax(endInitTime);
                this.startTime = startInitTime;
                this.endTime = endInitTime;
            } else {
                var startInitTime = new Date(2000, 0, 0, 9, 0, 0);
                var endInitTime = new Date(2000, 0, 0, 18, 0, 0);
                this.setEndTimeMin(startInitTime);
                this.setStartTimeMax(endInitTime);
                this.startTime = startInitTime;
                this.endTime = endInitTime;
            }

            this.IsOnChangeEvent = true;
            this.allocationForm.patchValue({
                startDate: eventStartDate,
                endDate,
                //  weekdayStart: startTime,
                //  weekdayEnd: endTime,
                weekdayLunch: lunch,
            });

            // $('#kendo-start-date').data('kendoDatePicker').value(eventStartDate);
            $('#kendo-end-date').data('kendoDatePicker').value(endDate);
            // $('#kendo-wd-start').data('kendoTimePicker').value(startTime);
            // $('#kendo-wd-end').data('kendoTimePicker').value(endTime);

        });

        scheduler.element.on('dblclick', '.k-scheduler-content td', (e) => {
            if (this.configDisableUpdatingEmpAvailability) {
                return;
            }
            if ((this.userType && this.userType === 'Employee' && userId != 'all' && role == 'Admin') || role == 'Employee') {
                const slot = scheduler.slotByElement($(e.target));
                if (slot.startDate >= this.currentDate) {
                    if (role == 'Employee') {
                        userId = ''
                    }

                    calendarService.updateSingleAvailability(slot.startDate, userId).subscribe(
                        (data: boolean) => {
                            // scheduler.dataSource.read({ userId: userId });
                            scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                            this.signalRService.broadcastCalendarUpdates();
                            if (role == 'Employee') {
                                scheduler.refresh();
                            }
                        },
                        (error: any) => this.errorMessage = error as any
                    );
                }
            }

            if (this.userType && this.userType === 'Client' && userId != 'all' && role != 'Employee') {
                const slot = scheduler.slotByElement($(e.target));
                if (slot.startDate >= this.currentDate) {
                    $('.k-scheduler-table td').removeClass('cell-celected');
                    $(scheduler.slotByElement($(e.target)).element).addClass('cell-celected');
                    const eventStartDate = slot.startDate;
                    const eventEndDate = slot.endDate;
                    // Following is required because event end date is set to next day 00:00:00
                    const endDate = new Date(eventEndDate.setDate(eventEndDate.getDate() + -1));

                    this.isWeekend = (endDate.getDay() === 6) || (endDate.getDay() === 0);
                    const startTime = (this.isWeekend ? '10:00' : '09:00');
                    const endTime = (this.isWeekend ? '15:00' : '18:00');
                    const lunch = (this.isWeekend ? 0 : 45);

                    if (this.isWeekend) {
                        var startInitTime = new Date(2000, 0, 0, 10, 0, 0);
                        var endInitTime = new Date(2000, 0, 0, 15, 0, 0);
                        this.setEndTimeMin(startInitTime);
                        this.setStartTimeMax(endInitTime);
                    } else {
                        var startInitTime = new Date(2000, 0, 0, 9, 0, 0);
                        var endInitTime = new Date(2000, 0, 0, 18, 0, 0);
                        this.setEndTimeMin(startInitTime);
                        this.setStartTimeMax(endInitTime);
                    }
                    this.IsOnChangeEvent = true;
                    this.allocationForm.patchValue({
                        startDate: eventStartDate,
                        endDate,
                        weekdayStart: startTime,
                        weekdayEnd: endTime,
                        weekdayLunch: lunch,
                        resourceTypeId: this.selectedResourceTypeId,
                        clientId: this.userId,
                        isInquiry: !this.isBookingSelected,
                        employeeId: ''
                    });

                    // $('#kendo-start-date').data('kendoDatePicker').value(eventStartDate);
                    $('#kendo-end-date').data('kendoDatePicker').value(endDate);
                    $('#kendo-end-date').data('kendoDatePicker').enable(true);
                    $('#kendo-wd-start').data('kendoTimePicker').value(startTime);
                    $('#kendo-wd-start').data('kendoTimePicker').enable(true);
                    $('#kendo-wd-end').data('kendoTimePicker').value(endTime);
                    $('#kendo-wd-end').data('kendoTimePicker').enable(true);

                    this.isBookingFromPartialAvailability = false;
                    this.onClickMakeBooking();
                }
            }
        });

        this.initializeKendoComponents();
        $('.k-scheduler-tools').hide();
        $('.admin-booking-scheduler .k-scheduler-views').append('<li class="k-state-default  k-exportpdf" data-name="Export"><a role="button" href="#" class="k-link mobile-export">Skriv ut</a></li>')
        $('.admin-booking-scheduler .mobile-export').click(() => {
            $('.admin-booking-scheduler .k-pdf').click();
        });
        if (kendo.support.mobileOS) {
            $('.k-sm-date-format').css('font-size', '100%');
        }

        $('#employeeBookingModal').on('hide.bs.modal', () => {
            this.allocationTimeUpdateForm.patchValue({
                allocationId: '',
                startDate: '',
                endDate: '',
                lunchTime: '',
            })
            this.allocationTimeLogsForm.patchValue({
                startTime: '',
                endTime: '',
                lunchTime: '',
                travelTime: 0,
                distance: 0


            });
            this.swapUserForm.reset()
            this.isAllocationTimeEdit = false
            this.showHistory = false
            this.swapAccessed = false
            this.sendRequest = false
            $('.collapse').removeClass('show')
            $('.availBtn').removeClass('btn-primary')
            $('.availBtn').addClass('btn-secondary')
            $('.sameDayBtn').removeClass('btn-primary')
            $('.sameDayBtn').addClass('btn-secondary')
        })

        this.isLoadOnSubscription = true;
    }

    getUserWiseAdminConfigurationDetails(userId: string): void {
        this.configService.getUserWiseAdminCOnfigurations(userId).subscribe(data => {
            if (data.length) {
                if (data.find(a => a.title === 'configDisableUpdatingEmpAvailability')) {
                    this.configDisableUpdatingEmpAvailability = data.find(a => a.title === 'configDisableUpdatingEmpAvailability').isActive;
                }
                if (data.find(a => a.title === 'configClientEditBookingTime')) {
                    this.configClientEditBookingTime = data.find(a => a.title === 'configClientEditBookingTime').isActive;
                }
            }
        })
    }

    getMasterData() {
        this.userService.getInnitialMasterData().subscribe((data) => {
            this.experienceAreas = data.experienceAreas;
            for (let [key, value] of Object.entries(this.experienceAreas)) {
                // value['value'] = value.id;
                value['label'] = value.name;
            }
        })
    }

    initializeKendoComponents(): void {
        let loggedInUserRole: string;
        loggedInUserRole = localStorage.getItem('role');

        if (loggedInUserRole == 'Administrator') {
            ($('#kendo-end-date') as any).kendoDatePicker({
                format: 'yyyy-MM-dd',
                change: (val) => {
                    this.isWeekend = (val.sender._value.getDay() === 6) || (val.sender._value.getDay() === 0);
                    const startTime = (this.isWeekend ? '10:00' : '09:00');
                    const endTime = (this.isWeekend ? '15:00' : '18:00');
                    const lunch = (this.isWeekend ? 0 : 45);

                    this.allocationForm.patchValue({
                        startDate: val.sender._value,
                        endDate: val.sender._value,
                        weekdayStart: startTime,
                        weekdayEnd: endTime,
                        weekdayLunch: lunch,
                        experienceAreas: []
                    });
                    this.startTime = startTime;
                    this.endTime = endTime;

                    $('#kendo-wd-start').data('kendoTimePicker').value(startTime);
                    $('#kendo-wd-end').data('kendoTimePicker').value(endTime);
                }
            });
        } else {
            ($('#kendo-end-date') as any).kendoDatePicker({
                format: 'yyyy-MM-dd',
                min: new Date(),
                change: (val) => {
                    this.isWeekend = (val.sender._value.getDay() === 6) || (val.sender._value.getDay() === 0);
                    const startTime = (this.isWeekend ? '10:00' : '09:00');
                    const endTime = (this.isWeekend ? '15:00' : '18:00');
                    const lunch = (this.isWeekend ? 0 : 45);

                    this.allocationForm.patchValue({
                        startDate: val.sender._value,
                        endDate: val.sender._value,
                        weekdayLunch: lunch,
                        experienceAreas: []
                    });
                    this.startTime = startTime;
                    this.endTime = endTime;

                    $('#kendo-wd-start').data('kendoTimePicker').value(startTime);
                    $('#kendo-wd-end').data('kendoTimePicker').value(endTime);
                }
            });
        }
        // Time Picker initialization for allocation form
        ($('.kendo-time-al-start') as any).kendoTimePicker({
            format: 'HH:mm',
            interval: 15,
            change: (val) => {
                //   const dt = new Date(val.sender._value);
                var dt: any
                if (val.sender._value) {
                    dt = new Date(val.sender._value);
                } else {
                    var timeStr = val.sender._oldText
                    dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
                }
                const hours = dt.getHours();
                const minutes = dt.getMinutes();
                const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
                this.setEndTimeMin(dt);
                this.startTime = dt;
                const formControlName = val.sender.element[0].attributes['data-fcname'].value;
                this.allocationForm.get(formControlName).setValue(formattedTime);
            }
        });
        ($('.kendo-time-al-end') as any).kendoTimePicker({
            format: 'HH:mm',
            interval: 15,
            change: (val) => {
                // const dt = new Date(val.sender._value);
                var dt: any
                if (val.sender._value) {
                    dt = new Date(val.sender._value);
                } else {
                    var timeStr = val.sender._oldText
                    dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
                }
                const hours = dt.getHours();
                const minutes = dt.getMinutes();
                const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
                this.setStartTimeMax(dt);
                const formControlName = val.sender.element[0].attributes['data-fcname'].value;
                this.allocationForm.get(formControlName).setValue(formattedTime);
                this.endTime = dt;

            }
        });

        // Time Picker initialization for time log form
        ($('.kendo-time-tl-start') as any).kendoTimePicker({
            format: 'HH:mm',
            interval: 15,
            change: (val) => {
                //   const dt = new Date(val.sender._value);
                var dt: any
                if (val.sender._value) {
                    dt = new Date(val.sender._value);
                } else {
                    var timeStr = val.sender._oldText
                    dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
                }
                const hours = dt.getHours();
                const minutes = dt.getMinutes();
                const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
                this.setEndTimeMin(dt, 'tl');
                const formControlName = val.sender.element[0].attributes['data-fcname'].value;
                this.allocationTimeLogsForm.get(formControlName).setValue(formattedTime);
            }
        });
        ($('.kendo-time-tl-end') as any).kendoTimePicker({
            format: 'HH:mm',
            interval: 15,
            change: (val) => {
                //   const dt = new Date(val.sender._value);
                var dt: any
                if (val.sender._value) {
                    dt = new Date(val.sender._value);
                } else {
                    var timeStr = val.sender._oldText
                    dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
                }
                const hours = dt.getHours();
                const minutes = dt.getMinutes();
                const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
                this.setStartTimeMax(dt, 'tl');
                const formControlName = val.sender.element[0].attributes['data-fcname'].value;
                this.allocationTimeLogsForm.get(formControlName).setValue(formattedTime);
            }
        });

        // Time Picker initialization for time update form
        ($('.kendo-time-tu-start') as any).kendoTimePicker({
            format: 'HH:mm',
            interval: 15,
            change: (val) => {
                //const dt = new Date(val.sender._value);
                var dt: any
                if (val.sender._value) {
                    dt = new Date(val.sender._value);
                } else {
                    var timeStr = val.sender._oldText
                    dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
                }
                const hours = dt.getHours();
                const minutes = dt.getMinutes();
                const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
                this.setEndTimeMin(dt, 'tu');
                const formControlName = val.sender.element[0].attributes['data-fcname'].value;
                this.allocationTimeUpdateForm.get(formControlName).setValue(formattedTime);
            }
        });
        ($('.kendo-time-tu-end') as any).kendoTimePicker({
            format: 'HH:mm',
            interval: 15,
            change: (val) => {
                //const dt = new Date(val.sender._value);
                var dt: any
                if (val.sender._value) {
                    dt = new Date(val.sender._value);
                } else {
                    var timeStr = val.sender._oldText
                    dt = new Date(2000, 0, 0, parseInt(timeStr.slice(0, 2)), parseInt(timeStr.slice(3, 5)), 0);
                }
                const hours = dt.getHours();
                const minutes = dt.getMinutes();
                const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
                this.setStartTimeMax(dt, 'tu');
                const formControlName = val.sender.element[0].attributes['data-fcname'].value;
                this.allocationTimeUpdateForm.get(formControlName).setValue(formattedTime);
            }
        });


        // Time Picker initialization for availability update form
        ($('.kendo-time-au-start') as any).kendoTimePicker({
            format: 'HH:mm',
            interval: 15,
            change: (val) => {
                var dt: any
                if (val.sender._value) {
                    dt = new Date(val.sender._value);
                } else {
                    var startTimeStr = val.sender._oldText
                    dt = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                }
                const hours = dt.getHours();
                const minutes = dt.getMinutes();
                const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
                this.setEndTimeMin(dt, 'au');
                const formControlName = val.sender.element[0].attributes['data-fcname'].value;
                this.partialAvailabilityForm.get(formControlName).setValue(formattedTime);
            }
        });
        ($('.kendo-time-au-end') as any).kendoTimePicker({
            format: 'HH:mm',
            interval: 15,
            change: (val) => {
                var dt: any
                if (val.sender._value) {
                    dt = new Date(val.sender._value);
                } else {
                    var endTimeStr = val.sender._oldText
                    dt = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);
                }
                const hours = dt.getHours();
                const minutes = dt.getMinutes();
                const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
                this.setStartTimeMax(dt, 'au');
                const formControlName = val.sender.element[0].attributes['data-fcname'].value;
                this.partialAvailabilityForm.get(formControlName).setValue(formattedTime);
            }
        });

        ($('#kendo-avl-start-date') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
            min: new Date(),
            change: (val) => {
                this.availabilityForm.patchValue({
                    startDate: val.sender._value
                });
            }
        });

        ($('#kendo-avl-end-date') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
            min: new Date(),
            change: (val) => {
                this.availabilityForm.patchValue({
                    endDate: val.sender._value
                });
            }
        });
        ($('#kendo-avl-month') as any).kendoDatePicker({
            format: 'MMMM yyyy',
            min: new Date(),
            start: 'year',
            depth: 'year',
            change: (val) => {
                this.availabilityForm.patchValue({
                    month: val.sender._value
                });
            }
        });

        ($('.kendo-picker') as any).bind('focus', function () {
            //console.log($(this));
            //console.log($(this).data('kendoDatePicker'));
            $(this).data('kendoDatePicker').open();
        });

        // leave form date pickers 

        ($('#kendo-avl-start-date1') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
            change: (val) => {
                this.leaveForm.patchValue({
                    fromDate: val.sender._value
                });
            }
        });

        ($('#kendo-avl-end-date2') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
            change: (val) => {
                this.leaveForm.patchValue({
                    toDate: val.sender._value
                });
            }
        });

        ($('#kendo-avl-start-date4') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
            change: (val) => {
                this.commentForm.patchValue({
                    fromDate: val.sender._value
                });
            }
        });

        ($('#kendo-avl-end-date3') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
            change: (val) => {
                this.commentForm.patchValue({
                    toDate: val.sender._value
                });
            }
        });

        ($('#kendo-avl-start-date3') as any).kendoDatePicker({
            format: 'yyyy-MM-dd',
            min: new Date(),
            change: (val) => {
                this.selfBookingStartDate = val.sender._value
            }
        });


    }

    setEndTimeMin(dt, option?) {
        if (option == 'tl') {
            var endTimepicker = $("#kendo-tl-end").data("kendoTimePicker");
        } else if (option == 'tu') {
            var endTimepicker = $("#kendo-tu-end").data("kendoTimePicker");
        } else if (option == 'au') {
            var endTimepicker = $("#kendo-au-end").data("kendoTimePicker");
        } else {
            var endTimepicker = $("#kendo-wd-end").data("kendoTimePicker");
        }
        endTimepicker.min(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), (dt.getMinutes() == 45 ? dt.getHours() + 1 : dt.getHours()), (dt.getMinutes() == 45 ? 0 : dt.getMinutes() + 15), 0));
    }

    setStartTimeMax(dt, option?) {
        if (option == 'tl') {
            var startTimepicker = $("#kendo-tl-start").data("kendoTimePicker");
        } else if (option == 'tu') {
            var startTimepicker = $("#kendo-tu-start").data("kendoTimePicker");
        } else if (option == 'au') {
            var startTimepicker = $("#kendo-au-start").data("kendoTimePicker");
        } else {
            var startTimepicker = $("#kendo-wd-start").data("kendoTimePicker");
        }
        startTimepicker.max(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), (dt.getMinutes() == 0 ? dt.getHours() - 1 : dt.getHours()), (dt.getMinutes() == 0 ? 45 : dt.getMinutes() - 15), 0));
    }

    schedulerEdit(event): void {
        this.isSwapEmployeeSelected = false;
        if (event.event.id != 0) {

            this.allocation = event.event;
            this.allocation.emailStatus = '';
            this.allocation.emailStatus = "pågående";

            if (this.allocation.clientEmailSendStatus == 1) {
                this.allocation.emailStatus = "";
                this.allocation.emailStatus = "Kunden har meddelats. "
            }
            if (this.allocation.employeeEmailSendStatus == 1) {
                this.allocation.emailStatus = this.allocation.emailStatus + '  ' + "Arbetare har meddelats om pass. "
            }

            this.isPartialBooking = event.event.isPartialBooking;
            this.allocationTimeLogsForm.patchValue({
                allocationId: event.event.id,
                isTimeLogged: event.event.isTimeLogged,
            });

            if (this.role == 'Admin' || this.role == "Employee") {
                if (this.allocation.start <= new Date()) {
                    this.visibleTimeLogs = true;
                } else {
                    this.visibleTimeLogs = false;
                }
                if (event.event.isTimeLogged) {
                    if (this.role == 'Admin') {
                        $('#allocationTimeLogsFormContent .k-invalid').removeClass('k-invalid');

                        $('#kendo-tl-start').data('kendoTimePicker').value(event.event.timeLogStartTime);
                        $('#kendo-tl-end').data('kendoTimePicker').value(event.event.timeLogEndTime);

                        var startTLTime = new Date(2000, 0, 0, parseInt(event.event.timeLogStartTime.slice(0, 2)), parseInt(event.event.timeLogStartTime.slice(3, 5)), 0);
                        var endTLTime = new Date(2000, 0, 0, parseInt(event.event.timeLogEndTime.slice(0, 2)), parseInt(event.event.timeLogEndTime.slice(3, 5)), 0);
                        this.setEndTimeMin(startTLTime, 'tl');
                        this.setStartTimeMax(endTLTime, 'tl');

                        this.allocationTimeLogsForm.patchValue({
                            startTime: event.event.timeLogStartTime,
                            endTime: event.event.timeLogEndTime,
                            lunchTime: event.event.timeLogLunch,
                            travelTime: event.event.travelTime,
                            distance: event.event.distance
                        });
                    }
                } else if (this.allocation.start <= new Date()) {
                    $('#allocationTimeLogsFormContent .k-invalid').removeClass('k-invalid');
                    var preFillStartTime: string = event.event.sDate.slice(11, 16);
                    var preFillEndTime: string = event.event.eDate.slice(11, 16);

                    var startTLTime = new Date(2000, 0, 0, parseInt(preFillStartTime.slice(0, 2)), parseInt(preFillStartTime.slice(3, 5)), 0);
                    var endTLTime = new Date(2000, 0, 0, parseInt(preFillEndTime.slice(0, 2)), parseInt(preFillEndTime.slice(3, 5)), 0);

                    $('#kendo-tl-start').data('kendoTimePicker').value(preFillStartTime);
                    $('#kendo-tl-end').data('kendoTimePicker').value(preFillEndTime);
                    this.setEndTimeMin(startTLTime, 'tl');
                    this.setStartTimeMax(endTLTime, 'tl');

                    this.allocationTimeLogsForm.patchValue({
                        lunchTime: event.event.lunch,
                        startTime: preFillStartTime,
                        endTime: preFillEndTime,
                        travelTime: 0,
                        distance: 0
                    });
                }
            }
            //   $('#kendo-tl-start').data('kendoTimePicker').value(event.event.timeLogStartTime);
            //   $('#kendo-tl-end').data('kendoTimePicker').value(event.event.timeLogEndTime);
            //   this.allocation = event.event;
            //   this.isPartialBooking = event.event.isPartialBooking;
            //   if (this.allocation.start <= new Date()) {
            //       this.visibleTimeLogs = true;
            //   } else {
            //       this.visibleTimeLogs = false;
            //   }
            ($('#employeeBookingModal') as any).modal('show');
        }
    }

    schedulerDataBound(scheduler): void {
        const schView = scheduler.view().name;

        // $(".k-scheduler-views li").on("click", function () {
        //   if ($(this).hasClass('k-view-agenda')) {
        //     setTimeout(() => {
        //       $('.k-nav-current').addClass('agenda-date-header')
        //     }, 200);
        //   }else {
        //     $('.k-nav-current').removeClass('agenda-date-header')
        //   }
        // });
        if (schView === 'month') {
            $('.k-scheduler-navigation').css('pointer-events', 'none');
            $('.k-scheduler-navigation').css('opacity', '0.5');
            const container = scheduler.view().element;
            const cells = container.find('td[role=gridcell]');

            $('.slot-orange').parent().addClass('dayview-slot-orange');
            $('.slot-yellow').parent().addClass('dayview-slot-yellow');
            $('.slot-blue').parent().addClass('dayview-slot-blue');
            $('.slot-yellow .font-weight-bold').css('border-color', 'red');

            this.availabilityCheckDates = [];
            for (let i = 0; i < cells.length; i++) {
                const cell = $(cells[i]);
                const slot = scheduler.slotByElement(cell);
                const avl = {} as AvailabilityModel;
                avl.category = scheduler.view().name;

                if (slot) {
                    avl.startTime = slot.startDate;
                    avl.endTime = slot.endDate;
                }

                if (avl) {
                    avl.userId = (this.userId && this.userId !== 'all') ? this.userId : '';
                    if (avl.startTime) {
                        avl.startTimeString = avl.startTime.getFullYear() + '-' + (avl.startTime.getMonth() + 1) + '-' + avl.startTime.getDate();
                    }

                    if (avl.endTime) {
                        avl.endTimeString = avl.endTime.getFullYear() + '-' + (avl.endTime.getMonth() + 1) + '-' + avl.endTime.getDate();
                    }

                }
                // avl.resourceTypeId = this.selectedResourceTypeId;
                avl.resourceTypeId = this.resourceTypeForAvalReq;
                this.availabilityCheckDates.push(avl);
            }


            this.calendarService.checkAllAvailability(this.availabilityCheckDates, this.role).subscribe(
                (avlData: AvailabilityInfo[]) => {
                    this.availabilityData = avlData;
                    for (let i = 0; i < cells.length; i++) {
                        const cell = $(cells[i]);
                        const slot = scheduler.slotByElement(cell);

                        if (slot) {
                            const slotStartDate = slot.startDate as Date;
                            const slotEndDate = slot.endDate as Date;


                            const filterData = avlData.find(function (data) {
                                return (data.startTime.toDateString() === slotStartDate.toDateString()
                                    && data.endTime.toDateString() === slotEndDate.toDateString());
                            });
                            if (filterData) {
                                if (filterData.startTime.toDateString() === this.selectedDate.toDateString()) {
                                    if (filterData.isAvailable != this.isBookingSelected) {
                                        this.isBookingSelected = filterData.isAvailable
                                    }
                                }
                                var holidayArr: any[];
                                var slotDateJsonFormat = this.getJsonDateFormat(slot.startDate);
                                if (this.holidayLastMonth !== slotDateJsonFormat) {
                                    this.holidayLastMonth = slotDateJsonFormat
                                    holidayArr = this.swedishHolidays[slotDateJsonFormat]

                                }

                                // Mark Sundays & saturdays with different color using CSS class
                                if (slotStartDate.getDay() == 0) {
                                    cell.addClass('cell-sunday')
                                }
                                if (slotStartDate.getDay() == 6) {
                                    cell.addClass('cell-saturday')
                                }

                                if (filterData && filterData.startTime >= this.currentDate) {
                                    if (filterData.isAvailable) {
                                        cell.css('background-image', 'linear-gradient(225deg, #85BD3C, #85BD3C 30px, transparent 0px, transparent)');
                                        cell.css('position', 'relative');
                                        if ((this.userType && this.userType === 'Employee' && this.userId !== 'all' && this.role != 'Employee') || this.role == 'Employee') {
                                            if (!filterData.hasAllocations) {
                                                if (filterData.isPartial) {
                                                    cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span><button type="button" class="avlful btnpartial" data-type="2" data-sdate="' + slotStartDate.toDateString() + '">' + filterData.partialFrom + '-' + filterData.partialTo + '</button>');
                                                } else {
                                                    let msg = "";
                                                    if (filterData.message) {
                                                        msg = filterData.message;
                                                    }
                                                    const text = "Hel Dag " + msg;
                                                    cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span><button type="button" class="avlful btnpartial" data-type="1" data-sdate="' + slotStartDate.toDateString() + '">' + text + '</button>');
                                                }
                                            } else {
                                                cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                                            }
                                        } else {
                                            //   cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                                            //       '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                                            //       filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                                            //       '</span>');

                                            if (this.role == 'Admin') {
                                                cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                                                    '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                                                    filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                                                    '</span>');
                                            } else {
                                                cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                                            }
                                        }
                                    } else {
                                        cell.css('background-image', 'linear-gradient(225deg, #FF6161, #FF6161 30px, transparent 0px, transparent)');
                                        cell.css('position', 'relative');
                                        if (this.userType && this.userType === 'Employee' && this.userId !== 'all') {
                                            if (filterData.message) {
                                                cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span><button type="button" class="avlful btndanger" data-type="1" data-sdate="' + slotStartDate.toDateString() + '">' + filterData.message + '</button>');
                                            } else {
                                                cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                                            }

                                        } else {
                                            if (this.role == 'Admin') {
                                                cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                                                    '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                                                    filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                                                    '</span>');
                                            } else {
                                                cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                                            }

                                        }
                                    }
                                    if (holidayArr) {
                                        var holidayObj = holidayArr.find(e => e.date == slot.startDate.getDate())
                                        if (holidayObj) {
                                            cell.addClass('holiday')
                                            if (cell.children('.badge-info.holiday-name').length == 0) {
                                                cell.append(`<a class="d-lg-block d-xl-block d-md-block   badge badge-info holiday-name mobile-hide" style="color:white;" >${holidayObj.holiday}</a >`);
                                            }
                                        }
                                    }
                                } else {
                                    cell.css('position', 'relative');
                                    if (this.userType && this.userType === 'Employee' && this.userId !== 'all') {
                                        if (filterData.message) {
                                            cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span><button type="button" class="avlful btndanger" data-type="1" data-sdate="' + '">' + filterData.message + '</button>');
                                        } else {
                                            cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                                        }
                                    } else {
                                        //   cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                                        //       '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                                        //       filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                                        //       '</span>');

                                        if (this.role == 'Admin') {
                                            cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() +
                                                '</span><span class="available-counts" style="position: absolute;color: #969798;left: 1%;top: 1%;font-size: 0.8rem;">' +
                                                filterData.currentAvailabileCount + '/' + filterData.totalAvailableCount +
                                                '</span>');
                                        } else {
                                            cell.html('<span class="date-number ml-1" style="float:right;color:white">' + slotStartDate.getDate() + '</span>');
                                        }
                                    }
                                    cell.css('background-image', 'linear-gradient(225deg, #d1d1d1, #d1d1d1 30px, transparent 0px, transparent)');
                                    if (holidayArr) {
                                        var holidayObj = holidayArr.find(e => e.date == slot.startDate.getDate())
                                        if (holidayObj) {
                                            cell.addClass('holiday')
                                            if (cell.children('.badge-info.holiday-name').length == 0) {
                                                cell.append(`<a class=" d-lg-block d-xl-block d-md-block  badge badge-info holiday-name mobile-hide" style="color:white;" >${holidayObj.holiday}</a >`);
                                            }
                                        }
                                    }
                                }

                                // Check for monthname
                                var isFirstDay: boolean
                                if (slotStartDate.getDate() == 1) {
                                    isFirstDay = true
                                    var month = slotStartDate.toLocaleString('sv-SE', { month: 'long' });
                                    var monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1)
                                } else {
                                    isFirstDay = false
                                }

                                // Display month first day label if not holiday
                                if (!holidayObj && isFirstDay) {
                                    if (cell.children('.badge-info.holiday-name').length == 0) {
                                        cell.append(`<a class=" d-lg-block d-xl-block d-md-block  badge badge-info holiday-name mobile-hide" style="color:white;" >Första ${monthCapitalized}</a>`);
                                    }
                                }

                                // Dispaly week number for every monday
                                if (slotStartDate.getDay() == 1) {
                                    var weekNoArr = this.calendarService.getWeekNumber(slotStartDate)
                                    cell.append(`<span class="badge badge-pill week-number mobile-hide" >V.${weekNoArr[1]}</span >`);
                                }
                            }
                        }
                    }

                    //const el = this.elementRef.nativeElement.querySelector('.avlful');
                    $('.avlful').on("click", (e) => {
                        if (this.role == "Admin" || this.role == "Employee") {
                            const sDate = $(e.target).data('sdate');
                            this.partialAvailabilityClick(sDate);
                        }
                    });
                    // if  (el)  {
                    //   el.addEventListener('click', this.partialAvailabilityClick.bind(this));
                    // }
                },
                (error: any) => this.errorMessage = error as any
            );


        } if (schView === 'day') {
            $('.slot-orange.day-view').parent().addClass('dayview-slot-orange');
            $('.slot-yellow.day-view').parent().addClass('dayview-slot-yellow');
            $('.slot-blue.day-view').parent().addClass('dayview-slot-blue');
        }
        $('.k-scheduler-navigation').css('pointer-events', 'all');
        $('.k-scheduler-navigation').css('opacity', '1');

        if (this.deviceWidth < 768) {
            $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(1)').text('Mån');
            $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(2)').text('Tis');
            $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(3)').text('Ons');
            $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(4)').text('Tor');
            $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(5)').text('Fre');
            $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(6)').text('Lör');
            $('.k-scheduler-monthview .k-scheduler-header .k-scheduler-table tr  th:nth-child(7)').text('Sön');
        }

    }

    getJsonDateFormat(date: Date): string {
        return date.getFullYear() + '' + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
    }

    loadCalendar(data: any): void {

        // Values when tab select
        // 1 - Phamacists
        // 2 - Cashiers
        // null - Clients

        // Userdata when user selects
        // { userId,userType,fullName,actualHoursBooked,totalCalculatedHours}

        var reloadRequired = true
        //   when click on tab or user
        if (data != null) {

            // when click on a tab or same user
            if (typeof (data) == "number" || this.userId === data.userId) {

                this.userId = 'all';
                this.selectedUserFullName = null;
                $('.employee-wrapper').removeClass('employee-wrapper-active');
                $('.k-scheduler-table td').removeClass('cell-celected');

                //   if (data == 1) {
                //        reloadRequired = false
                //       this.onResourceTypeChange('1');
                //   } else if (data == 2) {
                //       reloadRequired = false
                //       this.onResourceTypeChange('2');
                //   } else if (data.userType == 'Client') {
                //     reloadRequired = false
                //     this.onResourceTypeChange('3');
                //   }

                if (typeof (data) == "number") {
                    reloadRequired = false
                    this.onResourceTypeChange(data);
                } else if (data.userType == 'Client') {
                    reloadRequired = false
                    this.onResourceTypeChange(0);
                }


                //   this.selectedUserId.next({ id: this.userId, type: 'all' })
                //   this.selectedUserType.next('all')

                // whne click on different user
            } else {
                // Uncomment following block to reset resource type to phamacists on user select
                if (data.userType == "Client" && this.userId == 'all') {
                    this.selectedResourceTypeId = 1;
                    this.resourceTypeForAvalReq = 1;
                }

                this.userId = data.userId;
                this.selectedUserFullName = data.fullName;
                this.selectedUserAllocationTimes = data.totalCalculatedHours + '/' + data.actualHoursBooked;
                this.userType = data.userType;

                //   this.selectedUserId.next({ id: this.userId, type: this.userType })
                //   this.selectedUserType.next(data.userType)
            }
        } else {
            this.userId = 'all';
            this.selectedUserFullName = null;
            $('.employee-wrapper').removeClass('employee-wrapper-active');
            $('.k-scheduler-table td').removeClass('cell-celected');
            //   this.selectedUserId.next({ id: this.userId, type: 'all' })
            //   this.selectedUserType.next('all')
            reloadRequired = false
            this.onResourceTypeChange(0);
        }

        // this.userId = data.userId;
        if (reloadRequired) {
            const scheduler = $('#scheduler').data('kendoScheduler');
            scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
            //   scheduler.refresh();
        }
    }

    removeAllocationByDateRange():void {

        let request : AlliocationModel = new AlliocationModel();
        this.calendarService.removeAllocationByDateRange(request).subscribe(data=> {

            if(data)
                {
                    
                }

        })
    }

    openDeleteConfirmModal(allocationId: number): void {
        this.updateAllocationId = allocationId;
        ($('#employeeBookingModal') as any).modal('hide');
        ($('#allocationDeleteModal') as any).modal('show');
    }

    removeAllocation(): void {
        ($('#allocationDeleteModal') as any).modal('hide');

        this.calendarService.removeAllocation(this.updateAllocationId).subscribe(
            (status: boolean) => {
                const scheduler = $('#scheduler').data('kendoScheduler');
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                //   scheduler.refresh();
                this.setNotificationData('remove', status, this.allocation.isInquiry ? 'inquiry' : 'booking');
                this.signalRService.broadcastCalendarUpdates();
                this.calendarService.emmitUserListAction('refresh');
            },
            (error: any) => this.errorMessage = error as any
        );
    }



    getEmployeeList(allocationId: number, allocation): void {
        this.swapUserForm.patchValue({
            allocationId
        });
        var dataObj = {
            clientId: allocation.clientId,
            employeeId: null,
            endDate: allocation.end,
            isInquiry: true,
            resourceTypeId: allocation.resourceTypeId,
            startDate: allocation.start,
            weekdayEnd: allocation.eDate.slice(11, 16),
            weekdayStart: allocation.sDate.slice(11, 16),
            weekdayLunch: allocation.lunch,
            weekendEnd: null,
            weekendLunch: null,
            weekendStart: null
        }
        this.userservice.getInquiryAssignUserList(dataObj).subscribe(
            (userList: IUserInfo[]) => {
                this.swapUserList = userList;
                this.isSwapEmployeeSelected = true;
            },
            (error: any) => this.errorMessage = error as any
        );
    }

    updateSwap(isInquiry: boolean): void {
        if (this.swapUserForm.get('swapUserId').value) {
            if (this.swapUserForm.get('swapType').value == 1 || isInquiry) {
                this.swapUserForm.patchValue({
                    isInquiry: isInquiry
                });
                if (isInquiry) {
                    this.swapUserForm.patchValue({
                        swapType: '1'
                    });
                }

                this.calendarService.swapAllocationEmployee(this.swapUserForm.value).subscribe(
                    (status: boolean) => {
                        const scheduler = $('#scheduler').data('kendoScheduler');
                        scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                        //   scheduler.refresh();

                        this.swapUserForm.patchValue({
                            swapUserId: [],
                            isInquiry: []
                        });

                        ($('#employeeBookingModal') as any).modal('hide');
                        this.setNotificationData('swap', status);
                        this.signalRService.broadcastCalendarUpdates();
                        this.calendarService.emmitUserListAction('refresh');
                    },
                    (error: any) => this.errorMessage = error as any
                );
            } else if (this.swapUserForm.get('swapType').value == 2) {

                var dataObject
                this.swapUserList.find((obj) => {
                    if (obj.value == this.swapUserForm.get('swapUserId').value) {
                        dataObject = {
                            alliocationId: this.allocation.id,
                            employeeId: this.allocation.empName,
                            swapWithAllocationId: this.swapUserForm.get('swapUserId').value,
                            swapWithEmployeeId: obj.employeeId,
                        }
                    }
                })
                this.calendarService.onSameDaySwapEmployees(dataObject).subscribe(
                    (status: boolean) => {
                        const scheduler = $('#scheduler').data('kendoScheduler');
                        scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                        //   scheduler.refresh();

                        this.swapUserForm.patchValue({
                            swapUserId: [],
                            isInquiry: []
                        });

                        ($('#employeeBookingModal') as any).modal('hide');
                        this.setNotificationData('swap', status);
                        this.signalRService.broadcastCalendarUpdates();
                        this.calendarService.emmitUserListAction('refresh');
                    },
                    (error: any) => this.errorMessage = error as any
                );
            }
        }
    }
    updateAllocationTimeLogs(): void {

        const validator = ($('#allocationTimeLogsFormContent') as any).kendoValidator({
            rules: {
                matches: function (input) {
                    if (input.data('greaterthan') == 'kendo-tl-start') {
                        var startTimeStr = $('#kendo-tl-start').val() as string;
                        var endTimeStr = $('#kendo-tl-end').val() as string;

                        var startTLTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                        var endTLTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);

                        if (startTLTime < endTLTime) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }


                }
            },
        }).data('kendoValidator');
        if (validator.validate()) {
            const timeLogsForm = this.allocationTimeLogsForm;
            const timeLogModel = {} as TimeLogModel;
            timeLogModel.allocationId = timeLogsForm.get('allocationId').value;
            timeLogModel.startTime = timeLogsForm.get('startTime').value;
            timeLogModel.endTime = timeLogsForm.get('endTime').value;
            timeLogModel.lunch = timeLogsForm.get('lunchTime').value;
            timeLogModel.travelTime = timeLogsForm.get('travelTime').value != null ? timeLogsForm.get('travelTime').value : 0;
            timeLogModel.distance = timeLogsForm.get('distance').value != null ? timeLogsForm.get('distance').value : 0;

            var day = 60 * 60 * 24 * 1000;
            var isAllocationDateHoliday = this.calendarService.isHoliday(this.allocation.start);
            var nextDate = new Date(this.allocation.start.getTime() + day);
            var prevDate = new Date(this.allocation.start.getTime() - day);
            var isNextDateHoliday = this.calendarService.isHoliday(nextDate);
            var isPrevDateHoliday = this.calendarService.isHoliday(prevDate);

            //var holidayStr: string = isAllocationDateHoliday ? 'Holiday' : ((isNextDateHoliday && isPrevDateHoliday) ? 'InBetween' : (isPrevDateHoliday ? 'AfterHoliday' : (isNextDateHoliday ? 'BeforeHoliday' : 'NotHoliday')));

            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var holidayStr: string = isAllocationDateHoliday ? 'Holiday' :
                ((isNextDateHoliday && !isAllocationDateHoliday) ? 'DayBeforeHoliday' : (
                    (isPrevDateHoliday && !isAllocationDateHoliday) ? 'DayAfterHoliday' : days[this.allocation.start.getDay()])); // 0 = Sunday, 6 = Saturday
            timeLogModel.holidays = holidayStr;

            this.calendarService.updateTimeLogs(timeLogModel).subscribe(
                (status: boolean) => {
                    ($('#employeeBookingModal') as any).modal('hide');
                    const scheduler = $('#scheduler').data('kendoScheduler');
                    scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                    this.setNotificationData('timelog', status);
                    this.allocationTimeLogsForm.reset();
                    this.signalRService.broadcastCalendarUpdates();
                    this.calendarService.emmitUserListAction('refresh');
                },
                (error: any) => this.errorMessage = error as any
            );
        }
    }

    getTimeString(date: any): any {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = [(hours > 9 ? '' : '0') + hours.toString(), ':', (minutes > 9 ? '' : '0') + minutes.toString()].join('');
        return formattedTime;
    }

    requestAllocation(): void {

        this.isBookingFromPartialAvailability = false;

        const validator = ($('#allocationFormContent') as any).kendoValidator({
            rules: {
                matches: function (input) {
                    if (input.data('greaterthan') == 'kendo-wd-start') {
                        var startTimeStr = $('#kendo-wd-start').val() as string;
                        var endTimeStr = $('#kendo-wd-end').val() as string;

                        var startWDTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                        var endWDTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);

                        if (startWDTime < endWDTime) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                }
            },
        }).data('kendoValidator');
        if (validator.validate()) {

            var allocationDate = this.allocationForm.get('startDate').value;
            var day = 60 * 60 * 24 * 1000;
            var isAllocationDateHoliday = this.calendarService.isHoliday(allocationDate);
            var nextDate = new Date(allocationDate.getTime() + day);
            var prevDate = new Date(allocationDate.getTime() - day);
            var isNextDateHoliday = this.calendarService.isHoliday(nextDate);
            var isPrevDateHoliday = this.calendarService.isHoliday(prevDate);

            //var holidayStr: string = isAllocationDateHoliday ? 'Holiday' : ((isNextDateHoliday && isPrevDateHoliday) ? 'InBetween' : (isPrevDateHoliday ? 'AfterHoliday' : (isNextDateHoliday ? 'BeforeHoliday' : 'NotHoliday')));
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            var holidayStr: string = isAllocationDateHoliday ? 'Holiday' :
                ((isNextDateHoliday && !isAllocationDateHoliday) ? 'DayBeforeHoliday' : (
                    (isPrevDateHoliday && !isAllocationDateHoliday) ? 'DayAfterHoliday' : days[this.allocation.start.getDay()])); // 0 = Sunday, 6 = Saturday


            this.allocationForm.patchValue({
                clientId: this.userId,
                weekdayStart: this.getTimeString(this.startTime),
                weekdayEnd: this.getTimeString(this.endTime),
                isInquiry: !this.isBookingSelected,
                holidays: holidayStr
            });

            var employees:any[] = this.allocationForm.get('employeeIds').value;
            employees.forEach(item=>{

                this.allocationForm.patchValue({
                    employeeId:item
                });

            if (this.role == 'Admin') {
                this.calendarService.requestAdminAllocations(this.allocationForm.value).subscribe(
                    (allocationInfo: BookingInfo) => {

                        $('.k-scheduler-table td').removeClass('cell-celected');
                        ($('#requestAllocationModal') as any).modal('hide');
                        this.onAllocationRequestComplete(allocationInfo, this.allocationForm.value);
                    },
                    (error: any) => this.errorMessage = error as any
                );
            } else if (this.role == 'SuperClient') {
                this.calendarService.requestSuperClientAllocations(this.allocationForm.value).subscribe(
                    (allocationInfo: BookingInfo) => {
                        $('.k-scheduler-table td').removeClass('cell-celected');
                        ($('#requestAllocationModal') as any).modal('hide');
                        this.onAllocationRequestComplete(allocationInfo, this.allocationForm.value);
                    },
                    (error: any) => this.errorMessage = error as any
                );
            }
        })

        }
    }

    selfBookingrequestAllocation(): void {

        this.isBookingFromPartialAvailability = false;

        const validator = ($('#allocationFormContent') as any).kendoValidator({
            rules: {
                matches: function (input) {
                    if (input.data('greaterthan') == 'kendo-wd-start') {
                        var startTimeStr = $('#kendo-wd-start').val() as string;
                        var endTimeStr = $('#kendo-wd-end').val() as string;

                        var startWDTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                        var endWDTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);

                        if (startWDTime < endWDTime) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                }
            },
        }).data('kendoValidator');
        // if (validator.validate()) {

        var allocationDate = this.selfBookingStartDate;
        var day = 60 * 60 * 24 * 1000;
        var isAllocationDateHoliday = this.calendarService.isHoliday(allocationDate);
        var nextDate = new Date(allocationDate.getTime() + day);
        var prevDate = new Date(allocationDate.getTime() - day);
        var isNextDateHoliday = this.calendarService.isHoliday(nextDate);
        var isPrevDateHoliday = this.calendarService.isHoliday(prevDate);

        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        var holidayStr: string = isAllocationDateHoliday ? 'Holiday' :
            ((isNextDateHoliday && !isAllocationDateHoliday) ? 'DayBeforeHoliday' : (
                (isPrevDateHoliday && !isAllocationDateHoliday) ? 'DayAfterHoliday' : days[this.allocation.start.getDay()])); // 0 = Sunday, 6 = Saturday

        // logged in user's id will be get from API

        this.selfBookingForm.patchValue({
            employeeId: 0,
            startDate: this.selfBookingStartDate,
            endDate: this.selfBookingStartDate,
            isInquiry: false,
            holidays: holidayStr
        });
        console.log(this.selfBookingForm);
        this.calendarService.requestAllocationByEmployee(this.selfBookingForm.value).subscribe(
            (allocationInfo: BookingInfo) => {
                ($('#updateSelfBookingModal') as any).modal('hide');
                $('.k-scheduler-table td').removeClass('cell-celected');
                ($('#requestAllocationModal') as any).modal('hide');
                this.onAllocationRequestComplete(allocationInfo, this.selfBookingForm.value);
            },
            (error: any) => this.errorMessage = error as any
        );
        // }
    }


    onAllocationRequestComplete(allocationInfo: BookingInfo, allocationForm: AlliocationModel): void {

        $('#kendo-end-date').data('kendoDatePicker').value('');
        $('#kendo-wd-start').data('kendoTimePicker').value('');
        $('#kendo-wd-end').data('kendoTimePicker').value('');
        if (allocationInfo != null && allocationInfo.isExpAreaValidationFail == true) {
            let startDate = this.allocationForm.get("startDate").value;
            let weekdayEnd = this.allocationForm.get("weekdayEnd").value;

            //  weekdayEnd weekdayStart weekdayLunch
            $('#kendo-end-date').data('kendoDatePicker').value(startDate);
            this.allocationForm.patchValue({
                weekdayStart: weekdayEnd
            })
            this.allocationForm.patchValue({
                weekdayStart: weekdayEnd,
            });
            // $('#kendo-we-start-hd').data('kendoTextBox').value(weekdayEnd);

            //   kendo-we-start-hd  kendo-we-end-hd weekdayLunch

            ($('#bookingExpAreaFailedViewModal') as any).modal('show');
        }
        else if (allocationInfo != null) {

            const scheduler = $('#scheduler').data('kendoScheduler');

            scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
            this.allocation = allocationInfo;
            this.showExpAreaDetails = false;
            if (this.allocation.inquiryExperienceAreas && this.allocation.inquiryExperienceAreas.length) {
                this.showExpAreaDetails = true;
                let displayExpAreas = '';
                this.allocation.inquiryExperienceAreas.forEach(item => {
                    displayExpAreas = displayExpAreas + ' - ' + item.experienceArea;
                })
                this.allocation.expAreasDisplay = displayExpAreas;
            }

            this.isSwapEmployeeSelected = false;
            ($('#employeeBookingModal') as any).modal('show');
            this.signalRService.broadcastCalendarUpdates();

            this.IsOnChangeEvent = true
            this.allocationForm.patchValue({
                startDate: '',
                endDate: '',
                weekdayStart: '',
                weekdayEnd: '',
                weekdayLunch: 0,
                employeeId: '',
                message: null,
                experienceAreas: []
            });

        } else {

            this.calendarService.getPartialAvailabilityList(allocationForm).subscribe(
                (partialList: PartialAvailabilityInfo[]) => {
                    if (partialList != null && partialList.length > 0) {
                        this.partialAvailableListForDay = partialList;
                        this.allocationTryDate = allocationForm.startDate;
                        this.allocationTryLunch = allocationForm.weekdayLunch;
                        this.allocationTryTime = allocationForm.weekdayStart + '-' + allocationForm.weekdayEnd;
                        ($('#partialAvailabilityListModal') as any).modal('show');
                    } else {
                        this.setNotificationData('booking', false);
                    }
                },
                (error: any) => this.errorMessage = error as any
            );
        }
        this.calendarService.emmitUserListAction('refresh');
    }

    updateAvailability(): void {
        const scheduler = $('#scheduler').data('kendoScheduler');
        const container = scheduler.view().element;
        const cells = container.find('td[role=gridcell]');
        this.availabilityCheckDates = [];

        for (let i = 0; i < cells.length; i++) {
            const cell = $(cells[i]);
            const slot = scheduler.slotByElement(cell);
            const avl = {} as AvailabilityModel;
            avl.category = scheduler.view().name;
            avl.startTime = slot.startDate;
            avl.endTime = slot.endDate;
            avl.startTimeString = avl.startTime.getFullYear() + '-' + (avl.startTime.getMonth() + 1) + '-' + avl.startTime.getDate();
            avl.endTimeString = avl.endTime.getFullYear() + '-' + (avl.endTime.getMonth() + 1) + '-' + avl.endTime.getDate();
            avl.resourceTypeId = this.selectedResourceTypeId;
            this.availabilityCheckDates.push(avl);
        }

        const avlForm = this.availabilityForm;

        this.availabilityUpdateModel = {} as AvailabilityUpdateModel;
        this.availabilityUpdateModel.changeType = avlForm.get('changeTypeId').value;
        this.availabilityUpdateModel.month = avlForm.get('month').value;
        this.availabilityUpdateModel.fromDate = avlForm.get('startDate').value;
        this.availabilityUpdateModel.toDate = avlForm.get('endDate').value;
        this.availabilityUpdateModel.availability = avlForm.get('availabilityId').value;
        this.availabilityUpdateModel.dateList = this.availabilityCheckDates;
        if (this.role != 'Employee') {
            this.availabilityUpdateModel.employeeId = this.userId;
        }

        if (this.availabilityUpdateModel.changeType === '1') {
            const month = this.availabilityUpdateModel.month;
            this.availabilityUpdateModel.monthString = month.getFullYear() + '-' + (month.getMonth() + 1) + '-' + month.getDate();
        } else if (this.availabilityUpdateModel.changeType === '2') {
            const fromDate = this.availabilityUpdateModel.fromDate;
            const toDate = this.availabilityUpdateModel.toDate;
            this.availabilityUpdateModel.fromDateString = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
            this.availabilityUpdateModel.toDateString = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate();
        } else {
            const fromDate = this.availabilityUpdateModel.fromDate;
            this.availabilityUpdateModel.fromDateString = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
        }

        this.calendarService.updateAvailability(this.availabilityUpdateModel).subscribe(
            (avlData: AvailabilityInfo[]) => {
                ($('#updateAvailabilityModal') as any).modal('hide');
                //this.initializeForms();
                //this.setCustomVallidations();
                $('#kendo-avl-month').data('kendoDatePicker').value('');
                $('#kendo-avl-start-date').data('kendoDatePicker').value('');
                $('#kendo-avl-end-date').data('kendoDatePicker').value('');
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                this.setNotificationData('availability', true);
                this.signalRService.broadcastCalendarUpdates();
                this.calendarService.emmitUserListAction('refresh');
            },
            (error: any) => this.errorMessage = error as any
        );

    }


    updateComment(): void {
        const scheduler = $('#scheduler').data('kendoScheduler');
        const container = scheduler.view().element;
        const cells = container.find('td[role=gridcell]');
        this.availabilityCheckDates = [];
        const commentForm = this.commentForm.value;
        commentForm.employeeId = this.userId;
        commentForm.changeType = 3;
        if (commentForm.toDate != "") {
            commentForm.changeType = 2;
        }

        this.availabilityUpdateModel = {} as AvailabilityUpdateModel;

        if (this.role != 'Employee') {
            this.availabilityUpdateModel.employeeId = this.userId;
        }

        this.calendarService.updateComment(commentForm).subscribe(
            (avlData: AvailabilityInfo[]) => {

                ($('#updateCommentModal') as any).modal('hide');
                $('#kendo-avl-start-date3').data('kendoDatePicker').value('');
                if (commentForm.toDate != "") {
                    $('#kendo-avl-end-date4').data('kendoDatePicker').value('');
                }
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                this.commentForm.reset();
                 this.setNotificationData('Comment', true);
                this.signalRService.broadcastCalendarUpdates();
                 this.calendarService.emmitUserListAction('refresh');
            },
            (error: any) => this.errorMessage = error as any
        );

    }

    updateVacation(): void {
        const scheduler = $('#scheduler').data('kendoScheduler');
        const container = scheduler.view().element;
        const cells = container.find('td[role=gridcell]');
        this.availabilityCheckDates = [];
        const leaveForm = this.leaveForm.value;
        leaveForm.employeeId = this.userId;

        this.availabilityUpdateModel = {} as AvailabilityUpdateModel;

        if (this.role != 'Employee') {
            this.availabilityUpdateModel.employeeId = this.userId;
        }

        leaveForm.ChangeType = 3;
        if (leaveForm.toDate) {
            leaveForm.ChangeType = 2;
        }

        this.calendarService.updateVacationAndLeave(leaveForm).subscribe(
            (avlData: AvailabilityInfo[]) => {
                this.leaveForm.reset();
                if (avlData && avlData.length) {
                    if (avlData.filter(item => item.message == 'EMPLOYEE_HAS_BOOKINGS').length > 0) {
                        this.setNotificationData('EMPLOYEE_HAS_BOOKINGS', true);
                    } else if (avlData.filter(item => item.message == 'UPDATED').length > 0) {
                        this.setNotificationData('UPDATED', true);

                    }
                }
                ($('#updateSickVacationModal') as any).modal('hide');
                $('#kendo-avl-start-date1').data('kendoDatePicker').value('');
                $('#kendo-avl-end-date2').data('kendoDatePicker').value('');
                //  $('#kendo-avl-end-date3').data('kendoDatePicker').value('');
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                this.leaveForm.reset();
                // this.setNotificationData('availability', true);
                this.signalRService.broadcastCalendarUpdates();
                // this.calendarService.emmitUserListAction('refresh');
            },
            (error: any) => this.errorMessage = error as any
        );

    }


    setNotificationData(flow, status, type?): void {
        if (flow === 'booking') {
            this.notificationModal.header = 'Bokning misslyckades';
            this.notificationModal.description = 'Tyvärr så kunde vi inte hitta en tillgänglig resurs för det valda datumet';
            this.notificationModal.type = 'danger';
        } else if (flow === 'swap') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = 'Byte av resurs genomfördes';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'Byte av resurs misslyckades! Kontrollera anställdas tillgänglighet';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'remove') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = type == 'inquiry' ? 'Förfrågan raderades' : 'Bokningen raderades';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'Borttaggning av ' + (type == 'inquiry' ? 'förfrågan ' : 'bokningen') + ' misslyckades! Var god försök igen';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'availability') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = 'Tillgänglighet uppdaterades';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'timelog') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = 'Tidrapportering uppdaterades';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'TUppdatering av tidrapportering misslyckades! Var god och försök igen';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'partialavl') {
            if (status) {
                this.notificationModal.header = 'Lyckades';
                this.notificationModal.description = 'Tillgänglighet uppdaterades';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades';
                this.notificationModal.description = 'Uppdatering av tillgänglighet misslyckades! Var god försök igen';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'assign') {
            if (status) {
                this.notificationModal.header = 'Lyckades';// Success
                this.notificationModal.description = 'Den anställde tilldelades förfrågan och e-post skickades. '; //'Employee was assigned successfully for inquiry and email sent.';
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades'; //'Failed';
                this.notificationModal.description = 'Tilldelning av passet till den anställda misslyckades. Vänligen försök igen. '; //'Employee assign process failed! Please try again.';
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'timeupdate') {
            if (status) {
                this.notificationModal.header = 'Lyckades';// Success
                this.notificationModal.description = 'Bokningens tider har uppdaterats.'; //Booking time slot has been updated
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades'; // Failed
                this.notificationModal.description = 'Uppdateringen av bokningstiden misslyckades! Var god försök igen.'; // Booking time slot updating failed! Please try again.
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'inquiry') {
            if (status) {
                this.notificationModal.header = 'Lyckades'; // Success
                this.notificationModal.description = 'Förfrågan har skapats.'; //Inquiry added successfully
                this.notificationModal.type = 'success';
            } else {
                this.notificationModal.header = 'Misslyckades'; // Failed
                this.notificationModal.description = 'Det gick inte att lägga till förfrågan! Var god försök igen.'; //Inquiry adding failed! Please try again.
                this.notificationModal.type = 'danger';
            }
        } else if (flow === 'EMPLOYEE_HAS_BOOKINGS') {
            this.notificationModal.header = 'Misslyckades'; //'Failed';
            this.notificationModal.description = 'This employee has booking(s) on selected date(s)'; //'Employee assign process failed! Please try again.';
            this.notificationModal.type = 'danger';
        } else if (flow === 'UPDATED') {
            this.notificationModal.header = 'Lyckades'; // Success
            this.notificationModal.description = 'Emplyee leave updated'; //Inquiry added successfully
            this.notificationModal.type = 'success';
        } else if("Comment")
            {
                this.notificationModal.header = 'Lyckades'; // Success
                this.notificationModal.description = 'Comment updated'; //Inquiry added successfully
                this.notificationModal.type = 'success';
            }

        ($('#xnotificationModal') as any).modal('show');
        setTimeout(() => {
            ($('#xnotificationModal') as any).modal('hide');
        }, 1500);
    }


    scrollToHour(hour): void {
        const time = new Date();
        time.setHours(hour);
        time.setMinutes(0);
        time.setSeconds(0);
        time.setMilliseconds(0);

        const scheduler = $('#scheduler').data('kendoScheduler');
        const contentDiv = scheduler.element.find('div.k-scheduler-content');
        const rows = contentDiv.find('tr');

        for (let i = 0; i < rows.length; i++) {
            const element = $(rows[i]);
            const slot = scheduler.slotByElement(element);

            const slotTime = kendo.toString(slot.startDate, 'HH:mm');
            const targetTime = kendo.toString(time, 'HH:mm');

            if (targetTime === slotTime) {
                contentDiv.scrollTop(0);

                const elementTop = element.offset().top;
                const containerTop = contentDiv.offset().top;

                contentDiv.scrollTop(elementTop - containerTop);
            }
        }
    }

    partialAvailabilityClick(slotDate: string): void {
        $('#partialAvailabilityFormContent .k-invalid').removeClass('k-invalid')
        let dt = new Date(slotDate);
        this.selectedPartialAvlDate = dt.getFullYear() + '-' + (((dt.getMonth() + 1) > 9) ? '' + ((dt.getMonth() + 1)) : '0' + (dt.getMonth() + 1)) + '-' + ((dt.getDate() > 9) ? '' + dt.getDate() : '0' + dt.getDate());

        this.calendarService.checkSingleAvailability(this.userId, slotDate, this.role).subscribe(
            (sts: AvailabilityInfo) => {
                if (!sts.isPartial) {
                    this.partialAvailabilityForm.patchValue({
                        avlDate: slotDate,
                        avlTypeId: 1,
                        avlStart: '12:00',
                        avlEnd: '20:00'
                    });
                    setTimeout(() => {
                        $('#kendo-au-start').data('kendoTimePicker').value('12:00');
                        $('#kendo-au-end').data('kendoTimePicker').value('20:00');
                    }, 100);
                    var startTimeStr = '12:00';
                    var endTimeStr = '20:00';

                } else {
                    this.partialAvailabilityForm.patchValue({
                        avlDate: slotDate,
                        avlTypeId: 2,
                        avlStart: sts.partialFrom,
                        avlEnd: sts.partialTo
                    });
                    setTimeout(() => {
                        $('#kendo-au-start').data('kendoTimePicker').value(sts.partialFrom);
                        $('#kendo-au-end').data('kendoTimePicker').value(sts.partialTo);
                    }, 100);
                    var startTimeStr = sts.partialFrom;
                    var endTimeStr = sts.partialTo;
                }
                var startAUTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                var endAUTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);
                this.setEndTimeMin(startAUTime, 'au');
                this.setStartTimeMax(endAUTime, 'au');
                ($('#partialAvailabilityModal') as any).modal('show');

            },
            (error: any) => this.errorMessage = error as any
        );
    }

    updatePartialAvailability(type: number): void {
        var validator = null;
        if (type == 2) {
            validator = ($('#partialAvailabilityFormContent') as any).kendoValidator({
                rules: {
                    matches: function (input) {
                        if (input.data('greaterthan') == 'kendo-au-start') {
                            var startTimeStr = $('#kendo-au-start').val() as string;
                            var endTimeStr = $('#kendo-au-end').val() as string;
                            var startWDTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                            var endWDTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);
                            if (startWDTime < endWDTime) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return true
                        }
                    }
                },
            }).data('kendoValidator');
        }


        if (type == 2 && validator && validator.validate() || type == 1) {

            const partialAvailabilityModel = {} as PartialAvailabilityModel;
            partialAvailabilityModel.userId = this.userId;
            partialAvailabilityModel.avlDate = this.partialAvailabilityForm.get('avlDate').value;
            partialAvailabilityModel.avlType = type == 1 ? 1 : 2

            if (type == 2) {
                partialAvailabilityModel.avlFromTime = this.partialAvailabilityForm.get('avlStart').value;
                partialAvailabilityModel.avlToTime = this.partialAvailabilityForm.get('avlEnd').value;
            }

            this.calendarService.updatePartialAvailability(partialAvailabilityModel, this.role).subscribe(
                (status: boolean) => {
                    ($('#partialAvailabilityModal') as any).modal('hide');
                    const scheduler = $('#scheduler').data('kendoScheduler');
                    scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                    this.setNotificationData('partialavl', status);
                    this.signalRService.broadcastCalendarUpdates();
                },
                (error: any) => this.errorMessage = error as any
            );
        }



    }

    bookFromPartialAvailability(partialAvailabilityData: PartialAvailabilityInfo): void {
        ($('#partialAvailabilityListModal') as any).modal('hide');
        this.allocationForm.patchValue({
            startDate: this.allocationTryDate,
            endDate: this.allocationTryDate,
            weekdayStart: partialAvailabilityData.fromTime,
            weekdayEnd: partialAvailabilityData.toTime,
            weekdayLunch: this.allocationTryLunch,
            isPartialBooking: true
        });

        $('#kendo-end-date').data('kendoDatePicker').value(this.allocationTryDate);
        $('#kendo-end-date').data('kendoDatePicker').enable(false);
        $('#kendo-wd-start').data('kendoTimePicker').value(partialAvailabilityData.fromTime);
        $('#kendo-wd-start').data('kendoTimePicker').enable(false);
        $('#kendo-wd-end').data('kendoTimePicker').value(partialAvailabilityData.toTime);
        $('#kendo-wd-end').data('kendoTimePicker').enable(false);

        this.isBookingFromPartialAvailability = true;
        ($('#requestAllocationModal') as any).modal('show');
    }



    onResourceTypeChange(resourceTypeId: number): void {
        if (resourceTypeId == 0) {
            this.resourceTypeForAvalReq = null;
            if (this.availabilityCheckDates) {
                this.availabilityCheckDates.forEach(x => x.resourceTypeId = this.resourceTypeForAvalReq);
            }
            const scheduler = $('#scheduler').data('kendoScheduler');
            if (scheduler) {
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
            }
        } else {
            this.selectedResourceTypeId = resourceTypeId;
            this.resourceTypeForAvalReq = resourceTypeId;

            this.allocationForm.patchValue({
                resourceTypeId: resourceTypeId
            });
            if (this.availabilityCheckDates) {
                this.availabilityCheckDates.forEach(x => x.resourceTypeId = this.resourceTypeForAvalReq);
            }
            const scheduler = $('#scheduler').data('kendoScheduler');
            scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
        }
    }

    onSelectSwapType(swapType: number): void {
        // this.swapUserForm.reset()
        if (swapType == 1) {
            $('.availBtn').removeClass('btn-secondary')
            $('.availBtn').addClass('btn-primary')
            $('.sameDayBtn').removeClass('btn-primary')
            $('.sameDayBtn').addClass('btn-secondary')
            this.swapUserForm.patchValue({
                swapType: 1
            })
            this.getAvailableSwapEmployees(this.allocation.id)
        } else if (swapType == 2) {
            $('.sameDayBtn').removeClass('btn-secondary')
            $('.sameDayBtn').addClass('btn-primary')
            $('.availBtn').removeClass('btn-primary')
            $('.availBtn').addClass('btn-secondary')
            this.swapUserForm.patchValue({
                swapType: 2
            })
            this.getSameDaySwapEmployees()
        }
        this.swapAccessed = true
    }

    getAvailableSwapEmployees(allocationId: number): void {

        this.swapUserForm.patchValue({
            swapUserId: null,
            allocationId: this.allocation.id
        });
        this.calendarService.getAllocationSwapUserList(allocationId).subscribe(
            (userList: IUserInfo[]) => {
                this.swapUserList = userList;
                for (let [key, value] of Object.entries(this.swapUserList)) {
                    value['value'] = value.userId;
                    value['label'] = value.fullName;
                }
                // 35 When clicking on Swap Employee/Switch Employee, the first employee should be pre-selected.
                // add already booked employee to the avaialble emp dropwdonw

                // this.swapUserList.push({ value: this.allocation.employeeId, label: this.allocation.empName })
                // commented above line 2/15/2024 Shams Al Genaby (External)


                //end
                let user = this.swapUserList.length > 0 ? this.swapUserList.find(x => x.value == this.allocation.employeeId) : ''
                if (user) {
                    this.swapUserForm.patchValue({
                        swapUserId: user.userId
                    })
                }

            },
            (error: any) => this.errorMessage = error as any
        );
    }

    getSameDaySwapEmployees(): void {
        this.swapUserForm.patchValue({
            swapUserId: null,
            allocationId: this.allocation.id
        })
        var dataset = {
            allocationId: this.allocation.id,
            startDate: this.allocation.sDate,
            endDate: this.allocation.eDate,
            clientId: this.allocation.clientId
        }
        this.calendarService.getSameDaySwapEmployeesList(dataset).subscribe(
            (userList: IUserInfo[]) => {
                this.swapUserList = userList;
                // let firstUserId = this.swapUserList.length>0? this.swapUserList[0].value:''
                // this.swapUserForm.patchValue({
                //   swapUserId:firstUserId
                // })
            },
            (error: any) => this.errorMessage = error as any
        );
    }

    onSwapBtnClick() {
        this.isSwapEmployeeSelected = true;
    }
    onEditAllocationTimes(): void {
        $('#allocationTimeUpdateFormContent .k-invalid').removeClass('k-invalid')
        var startTimeStr = this.allocation.sDate.substring(11, 16);
        var endTimeStr = this.allocation.eDate.substring(11, 16);

        var startTLTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
        var endTLTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);
        this.setEndTimeMin(startTLTime, 'tu');
        this.setStartTimeMax(endTLTime, 'tu');

        this.allocationTimeUpdateForm.patchValue({
            allocationId: this.allocation.id,
            startDate: startTimeStr,
            endDate: endTimeStr,
            lunchTime: this.allocation.lunch
        })
        this.isAllocationTimeEdit = true;

        $('#kendo-tu-start').data('kendoTimePicker').value(startTimeStr);
        $('#kendo-tu-end').data('kendoTimePicker').value(endTimeStr);

    }

    onUpdateAllocationTimes(): void {
        const validator = ($('#allocationTimeUpdateFormContent') as any).kendoValidator({

            rules: {
                matches: function (input) {
                    if (input.data('greaterthan') == 'kendo-tu-start') {
                        var startTimeStr = $('#kendo-tu-start').val() as string;
                        var endTimeStr = $('#kendo-tu-end').val() as string;
                        var startTUTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                        var endTUTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);

                        if (startTUTime < endTUTime) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                }
            },
        }).data('kendoValidator');
        if (validator.validate()) {
            var allocationDate = this.allocation.sDate
            var newStartDate = allocationDate.substring(0, 11) + this.allocationTimeUpdateForm.get('startDate').value + allocationDate.substring(16, allocationDate.length);
            var newEndDate = allocationDate.substring(0, 11) + this.allocationTimeUpdateForm.get('endDate').value + allocationDate.substring(16, allocationDate.length);

            var dataObj = {
                allocationId: this.allocation.id,
                startDate: newStartDate,
                endDate: newEndDate,
                lunch: this.allocationTimeUpdateForm.get('lunchTime').value,
                weekdayStart: this.allocationTimeUpdateForm.get('startDate').value,
                weekdayEnd: this.allocationTimeUpdateForm.get('endDate').value,
                holidays: ''
            };

            var day = 60 * 60 * 24 * 1000;
            var isAllocationDateHoliday = this.calendarService.isHoliday(this.allocation.start);
            var nextDate = new Date(this.allocation.start.getTime() + day);
            var prevDate = new Date(this.allocation.start.getTime() - day);
            var isNextDateHoliday = this.calendarService.isHoliday(nextDate);
            var isPrevDateHoliday = this.calendarService.isHoliday(prevDate);

            //var holidayStr: string = isAllocationDateHoliday ? 'Holiday' : ((isNextDateHoliday && isPrevDateHoliday) ? 'InBetween' : (isPrevDateHoliday ? 'AfterHoliday' : (isNextDateHoliday ? 'BeforeHoliday' : 'NotHoliday')));
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var holidayStr: string = isAllocationDateHoliday ? 'Holiday' :
                ((isNextDateHoliday && !isAllocationDateHoliday) ? 'DayBeforeHoliday' : (
                    (isPrevDateHoliday && !isAllocationDateHoliday) ? 'DayAfterHoliday' : days[this.allocation.start.getDay()])); // 0 = Sunday, 6 = Saturday
            dataObj.holidays = holidayStr;

            this.calendarService.updateAllocationTime(dataObj).subscribe(
                (status: boolean) => {
                    const scheduler = $('#scheduler').data('kendoScheduler');
                    scheduler.dataSource.read({ userId: this.userId, userType: this.userType });
                    ($('#employeeBookingModal') as any).modal('hide');
                    this.setNotificationData('timeupdate', status);
                    this.signalRService.broadcastCalendarUpdates();
                    this.calendarService.emmitUserListAction('refresh');
                },
                (error: any) => this.errorMessage = error as any
            )
        }
    }

    setCommonTimes(item): void {
        this.startTime = new Date(2000, 0, 0, parseInt(item.startTime.slice(0, 2)), parseInt(item.startTime.slice(3, 5)), 0);
        this.endTime = new Date(2000, 0, 0, parseInt(item.endTime.slice(0, 2)), parseInt(item.endTime.slice(3, 5)), 0);
        this.setAvailableEmployees();
        this.allocationForm.patchValue({
            weekdayStart: item.startTime,
            weekdayEnd: item.endTime,
            weekdayLunch: item.lunch,
        });
        // $('#kendo-start-date').data('kendoDatePicker').value(eventStartDate);
        // $('#kendo-end-date').data('kendoDatePicker').value(item.endDate);
        $('#kendo-wd-start').data('kendoTimePicker').value(item.startTime);
        $('#kendo-wd-end').data('kendoTimePicker').value(item.endTime);
    }

    onViewHistoryBtnClick(allocationId: number) {
        this.toggleSendRequest();
        if (this.sendRequest) {
            this.calendarService.getAllocationHistory(allocationId).subscribe(
                (res) => {
                    this.allocationHistory = res;
                },
                (error: any) => this.errorMessage = error as any
            );

        }
    }

    toggleSendRequest() {
        this.sendRequest = !this.sendRequest
    }


    onMobileTabSelect(event) {
        if (event == 'userlist') {
            $('#myTab').css('display', 'flex')
            $('#myTabContent').css('display', 'block')
        } else if (event == 'bookings') {
            $('#myTab').css('display', 'none')
            $('#myTabContent').css('display', 'none')
            $('.btn-calendar-booking').click()
        } else if (event == 'inquiry') {
            $('#myTab').css('display', 'none')
            $('#myTabContent').css('display', 'none')
            $('.btn-calendar-inquiry').click()
        }
    }

    addInquiryOnpartial(allocationData) {
        this.allocationForm.patchValue({
            clientId: this.userId,
            isInquiry: true
        });
        this.calendarService.requestAdminAllocations(this.allocationForm.value).subscribe(
            (allocationInfo: BookingInfo) => {
                if (allocationInfo != null) {
                    ($('#partialAvailabilityListModal') as any).modal('hide');
                    this.signalRService.broadcastCalendarUpdates();
                    this.setNotificationData('inquiry', true);
                    this.allocationForm.patchValue({
                        startDate: '',
                        endDate: '',
                        weekdayStart: '',
                        weekdayEnd: '',
                        weekdayLunch: 0,
                        employeeId: ''
                    });
                    this.calendarService.emmitUserListAction('refresh');
                } else {
                    this.setNotificationData('inquiry', false);
                }
                $('.k-scheduler-table td').removeClass('cell-celected');
                // this.onAllocationRequestComplete(allocationInfo, this.allocationForm.value);
            },
            (error: any) => this.errorMessage = error as any
        );
    }

    openProfileViewModal(event) {
        this.employeeProfileId.next(event);
        ($('#employeeProfileViewModal') as any).modal('show');
    }

    onClickMakeBooking() {
        console.log("booking-calendar-component-onClickMakeBooking");
        this.clientCommonTimes = []
        if (this.isBookingSelected) {
            this.calendarService.getClientCommonTimes(this.userId).subscribe((response) => {
                this.clientCommonTimes = response;
                this.setAvailableEmployees();
            })
        } else {
            this.setAvailableEmployees();
        }
    }


    setAvailableEmployees() {
        const validator = ($('#allocationFormContent') as any).kendoValidator({
            rules: {
                matches: function (input) {
                    if (input.data('greaterthan') == 'kendo-wd-start') {
                        var startTimeStr = $('#kendo-wd-start').val() as string;
                        var endTimeStr = $('#kendo-wd-end').val() as string;

                        var startWDTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                        var endWDTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);

                        if (startWDTime < endWDTime) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                }
            },
        }).data('kendoValidator');
        if (true) {

            let date;
            const d = this.allocationForm.get('endDate').value;
            if (!d) {
                date = new Date();
            } else {
                date = d;
            }

            this.isWeekend = (date.getDay() === 6) || (date.getDay() === 0);
            const startTime = (this.isWeekend ? '10:00' : '09:00');
            const endTime = (this.isWeekend ? '15:00' : '18:00');
            const lunch = (this.isWeekend ? 0 : 45);

            this.allocationForm.patchValue({
                startDate: date,
                endDate: date,
                //  weekdayStart: startTime,
                //  weekdayEnd: endTime,
                weekdayLunch: lunch,
                experienceAreas: []
            });

            //  $('#kendo-wd-start').data('kendoTimePicker').value(startTime);
            //  $('#kendo-wd-end').data('kendoTimePicker').value(endTime);

            //  if (!this.IsOnChangeEvent) {
            this.selectedExpAreas = [];
            if (this.userType && this.userType === 'Client' && this.userId != 'all') {
                this.allocationForm.patchValue({
                    clientId: this.userId,
                    isInquiry: !this.isBookingSelected
                });
                this.resetModalValues();
                let resultsFetched: boolean = true;
                if (resultsFetched && this.role == 'Admin' || (this.role == 'SuperClient' && this.configClientIsAbleToSelectTheEmployeesInBooking == true)) {
                    this.availableEmployees = [];
                    this.calendarService.getAvailableEmployees(this.allocationForm.value).subscribe((availableEmpList) => {
                        // ($('#requestAllocationModal') as any).modal('hide');
                        resultsFetched = true;

                        if (availableEmpList) {
                            this.isBookingSelected = true
                            this.allocationForm.patchValue({
                                isInquiry: false
                            });
                            this.availableEmployees = availableEmpList
                            if (availableEmpList.length > 0) {
                                var preSystemSelectedEmployee = availableEmpList.find(element => {
                                    return element.isDefaultMatched == true
                                });
                            }
                            if (preSystemSelectedEmployee) {
                                //   setTimeout(() => {
                                this.allocationForm.get("employeeId").setValue(preSystemSelectedEmployee.value);
                                //   }, 200);
                            }
                        } else {
                            //  this.isBookingSelected = false;
                            this.allocationForm.patchValue({
                                employeeId: '',
                                isInquiry: true
                            });
                            this.availableEmployees = []
                        }
                        ($('#requestAllocationModal') as any).modal('show');
                        // this.showExperienceAreas = true;
                    })
                    resultsFetched = false;
                } else {
                    ($('#requestAllocationModal') as any).modal('show');
                }

            } else {
                ($('#requestAllocationModal') as any).modal('show');
            }
            //  }
        }
        this.IsOnChangeEvent = false;
    }

    getAdminConfigurationDetails(): void {
        const userId = null; // logged in user's id will be get from API
        this.configService.getUserWiseAdminCOnfigurations(userId).subscribe(data => {
            if (data.length) {
                if (data.find(a => a.title === 'configClientIsAbleToSelectTheEmployeesInBooking')) {
                    this.configClientIsAbleToSelectTheEmployeesInBooking = data.find(a => a.title === 'configClientIsAbleToSelectTheEmployeesInBooking').isActive;
                }
            }
        })
    }

    expAreaValidationFailedCreateInquiry() {
        ($('#bookingExpAreaFailedViewModal') as any).modal('hide');

        let expAreas: any = [];
        expAreas = this.allocationForm.get("experienceAreas").value;
        //  this.allocationForm.patchValue({"experienceAreas":[]});
        if (expAreas) {
            expAreas.forEach(element => {
                let expArea = this.experienceAreas.filter(item => item.value == element)[0];
                this.selectedExpAreas.push(expArea)
            });
        }
        this.isBookingSelected = false;
        $('#kendo-end-date').data('kendoDatePicker').enable(false);
        this.disableExpAreasDropdown = true;

        ($('#requestAllocationModal') as any).modal('show');
    }

    directCreateInquiry() {
        ($('#bookingExpAreaFailedViewModal') as any).modal('hide');


        this.isBookingSelected = false;
        this.disableExpAreasDropdown = true;

        ($('#requestAllocationModal') as any).modal('show');
    }

    resetModalValues() {
        this.allocationForm.patchValue({ "experienceAreas": [] });
        this.disableExpAreasDropdown = false;
    }

    onClickMakeLongBooking() {
        this.clientCommonTimes = []
        if (this.isBookingSelected) {
            this.calendarService.getClientCommonTimes(this.userId).subscribe((response) => {
                this.clientCommonTimes = response;
                this.setAvailableEmployeesForLongBooking();
            })
        } else {
            this.setAvailableEmployeesForLongBooking();
        }
    }

    setAvailableEmployeesForLongBooking() {
        const validator = ($('#allocationFormContentForLongBooking') as any).kendoValidator({
            rules: {
                matches: function (input) {
                    if (input.data('greaterthan') == 'kendo-wd-start') {
                        var startTimeStr = $('#kendo-wd-start').val() as string;
                        var endTimeStr = $('#kendo-wd-end').val() as string;

                        var startWDTime = new Date(2000, 0, 0, parseInt(startTimeStr.slice(0, 2)), parseInt(startTimeStr.slice(3, 5)), 0);
                        var endWDTime = new Date(2000, 0, 0, parseInt(endTimeStr.slice(0, 2)), parseInt(endTimeStr.slice(3, 5)), 0);

                        if (startWDTime < endWDTime) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                }
            },
        }).data('kendoValidator');
        if (validator.validate()) {
            if (!this.IsOnChangeEvent) {
                if (this.userType && this.userType === 'Client' && this.userId != 'all' && this.isBookingSelected) {
                    this.allocationForm.patchValue({
                        clientId: this.userId,
                        isInquiry: !this.isBookingSelected
                    });
                    if (this.role == 'Admin') {
                        this.calendarService.getAvailableEmployees(this.allocationForm.value).subscribe((availableEmpList) => {
                            if (availableEmpList) {
                                this.availableEmployees = availableEmpList
                                if (availableEmpList.length > 0) {
                                    var preSystemSelectedEmployee = availableEmpList.find(element => {
                                        return element.isDefaultMatched == true
                                    });
                                }
                                if (preSystemSelectedEmployee) {
                                    //   setTimeout(() => {
                                    this.allocationForm.get("employeeId").setValue(preSystemSelectedEmployee.value);
                                    //   }, 200);
                                }
                            } else {
                                this.allocationForm.patchValue({
                                    employeeId: '',
                                });
                                this.availableEmployees = []
                            }
                            ($('#requestAllocationForLongBookinModal') as any).modal('show');
                        })
                    } else {
                        ($('#requestAllocationForLongBookinModal') as any).modal('show');
                    }

                } else {
                    ($('#requestAllocationForLongBookinModal') as any).modal('show');
                }
            }
        }
        this.IsOnChangeEvent = false;
    }

    onTLstartChange(option?: string) {
        var picker: any
        if (option == 'tu') {
            picker = $('#kendo-tu-start')
        } else if (option == 'au') {
            picker = $('#kendo-au-start')
        } else {
            picker = $('#kendo-tl-start')
        }
        if (picker.val().toString().length == 2 || picker.val().toString().length == 1) {
            var newVal
            if (picker.val().toString().length == 2) {
                newVal = picker.val() + ':00'
                picker.val(newVal)
                picker.trigger('change', newVal)
            }
            if (picker.val().toString().length == 1) {
                newVal = '0' + picker.val() + ':00'
                picker.val(newVal)
                picker.trigger('change', newVal)
            }
        }
    }

    onTLEndChange(option?: string) {
        var picker: any
        if (option == 'tu') {
            picker = $('#kendo-tu-end')
        } else if (option == 'au') {
            picker = $('#kendo-au-end')
        } else {
            picker = $('#kendo-tl-end')
        }
        if (picker.val().toString().length == 2 || picker.val().toString().length == 1) {
            var newVal
            if (picker.val().toString().length == 2) {
                newVal = picker.val() + ':00'
                picker.val(newVal)
                picker.trigger('change', newVal)
            }
            if (picker.val().toString().length == 1) {
                newVal = '0' + picker.val() + ':00'
                picker.val(newVal)
                picker.trigger('change', newVal)
            }
        }


    }

    setViewDateRange(e) {
        var view = e.sender.view();

        switch (e.view) {
            case 'month':
                this.viewStartDate = new Date(e.date.getFullYear(), e.date.getMonth(), 1);
                this.viewEndDate = new Date(e.date.getFullYear(), e.date.getMonth() + 1, 1, 1);
                break;
            case 'day':
                this.viewStartDate = new Date(e.date.getFullYear(), e.date.getMonth(), 1);
                this.viewEndDate = new Date(e.date.getFullYear(), e.date.getMonth() + 1, 1, 1);
                break;
            case 'Agenda':
                this.viewStartDate = new Date(e.date.getFullYear(), e.date.getMonth(), e.date.getDate());
                this.viewEndDate = new Date(e.date.getFullYear(), e.date.getMonth() + 1, e.date.getDate());
                break;
            default:
                break;
        }


        if (this.role == "Employee") {

            setTimeout(() => {
                const scheduler = $('#scheduler').data('kendoScheduler');
                scheduler.dataSource.read({ userId: this.userId, userType: this.userType, viewStartDate: this.viewStartDate, viewEndDate: this.viewEndDate });
            }, 500);

        } else {
            const scheduler = $('#scheduler').data('kendoScheduler');
            scheduler.dataSource.read({ userId: this.userId, userType: this.userType, viewStartDate: this.viewStartDate, viewEndDate: this.viewEndDate });
        }


    }
    getCurrentConfigData(): void {
        this.userservice.getConfigData().subscribe((res) => {
            this.resourceTypes = res.resourceTypes

        })
    }
    getResourceNameById(id) {
        var resource = this.resourceTypes.find(x => x.id == id)
        if (resource) {
            return resource.displayName
        } else {
            return '';
        }

    }

}
