import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ConfigService } from "src/app/shared/services/config.service";
import { UserService } from "src/app/user/user.service";
import { CalendarService } from "../../calendar.service";
import { LongBookingService } from "src/app/shared/services/domain/booking/long-booking.service";

// @Component({
//   selector: "short-booking-list",
//   templateUrl: "./shortBooking-list.component.html",
//   styleUrls: ["./shortBooking-list.component.css"],
// })
// TODO : Build failed issue
@Component({
  selector: "app-shortbooking-list",
  templateUrl: "./shortbooking-list.component.html",
  styleUrls: ["./shortbooking-list.component.css"],
})
export class ShortBookingListComponent implements OnInit, OnChanges, OnDestroy {
  @Output() deleteTemplateFunc: EventEmitter<any> = new EventEmitter();

  showEdit: boolean;
  selectedIndex: number;
  clicked: boolean;
  changedValue: boolean;
  updatedInProgress: boolean;
  userInfoList: any = [];
  searchCritera: any = {};
  config: any = {};
  shortbookings: any[] = [];

  bookingModalHeader = "Make Long Bookings";
  ShortBookingSelected: boolean;

  constructor(
    private longBookingService: LongBookingService,
    private userService: UserService,
    private configService: ConfigService
  ) {}
  ngOnDestroy(): void {}

  ngOnInit() {
    this.searchCritera.pageNumber = 1;
    this.searchCritera.pageSize = 10;
    this.searchCritera.orderBy = "FirstName";
    this.searchCritera.isAscending = true;
    this.searchCritera.isActive = true;

    this.config = {
      itemsPerPage: this.searchCritera.pageSize,
      currentPage: this.searchCritera.pageNumber,
      totalItems: 10,
    };
    this.getShortBookings();
  }

  private getUsers(): void {
    this.userService.getAll(this.searchCritera).subscribe(
      (pagedResult) => {
        this.config.totalItems = pagedResult.totalNumberOfRecords;
        this.config.itemsPerPage = 10;
        this.config.currentPage = 1;

        this.userInfoList = pagedResult.results;
      }
      // error => this.errorMessage = error as any
    );
  }

  //   editShortBooking(allocation: any) {
  //     this.calendarService.emmitShortBookingDetails(allocation);
  //     ($('#employeeBookingModal') as any).modal('hide');
  //     this.bookingModalHeader = "Edit Long Booking";
  //     this.ShortBookingSelected = true;
  //     setTimeout(() => {
  //         ($('#requestAllocationForLongBookinModalType1') as any).modal('show');
  //     }, 500);
  // }

  pageChanged(event): void {
    this.searchCritera.pageNumber = event;
    this.getShortBookings();
    this.config.currentPage = event;
  }

  getShortBookings(): void {
    this.longBookingService
      .getShortBookings(this.searchCritera)
      .subscribe((data) => {
        console.log("data-", data.result);
        this.shortbookings = data.result.results;
        this.config.totalItems = data.result.totalNumberOfRecords;
        this.config.itemsPerPage = 10;
        this.config.currentPage = 1;
      });
  }

  close() {
    ($("#editTemplateForLongBookinModal") as any).modal("hide");
  }

  ngOnChanges(changes: SimpleChanges): void {}
}
