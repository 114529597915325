export class LongBookingShiftDay {
     Id :number;
     //DateTime : Date;
     Lunch: number;
     StartTime : string;
     EndTime : string; 
     Day : string;
     Date:Date;
     LongBookingShiftId : number;
}

