import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';
import { HttpInterceptorModule } from './security/http-interceptor-module';
import { ReportModule } from './report/report.module';
import { CalendarModuleA } from './calendar/calendar.module';
import { ConfigModule } from './config/config.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './security/login.component';
import { AuthGuard } from './security/auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForgotPasswordComponent } from './security/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './security/reset-password/reset-password.component';

import { WINDOW_PROVIDERS } from './window.providers';
import { HostService } from './shared/services/common/host.service';
import { SafeHTmlPipe } from './shared/pipes/safe-html.pipe';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LongBookingService } from './shared/services/domain/booking/long-booking.service';
import { DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from "primeng/calendar";
import { ToastrModule } from 'ngx-toastr';
import { StarRatingModule } from 'angular-star-rating';

//import { FileUploadComponent } from './config/file-upload/file-upload.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SafeHTmlPipe,
    //FileUploadComponent,
  ],
  imports: [
    ToastrModule.forRoot(),
    NgSelectModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpInterceptorModule,
    SharedModule,
    UserModule,
    ReportModule,
    CalendarModuleA,
    CalendarModule,
    ConfigModule,
    NgxSpinnerModule,
    StarRatingModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [TranslateModule],
  providers: [AuthGuard, WINDOW_PROVIDERS, HostService, LongBookingService, DatePipe
],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}


