import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IUserInfo } from '../user/user-info';
import { UserService } from '../user/user.service';
import { Subject, Subscription } from 'rxjs';
import { CalendarService } from '../calendar/calendar.service';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'wfm-user-client-list',
  templateUrl: './user-client-list.component.html',
  styleUrls: ['./user-client-list.component.css']
})
export class UserClientListComponent implements OnInit {

  userId: null;
  color: 'red';
  usersList: IUserInfo[];
  errorMessage: string;
  empType = 'Employee';
  bookingUnitName: '';
  empSubType = 1;
  resourceTypes: any[] = [];
  userFilter: any = { fullName: '' };
  refreshSubscription: Subscription
  selectedMonthSubscription: Subscription
  newSelectedUserSub: Subscription
  // @Input() selectedMonth: Subject<Date> = new Subject<Date>();
  @Input() userListAction: Subject<any> = new Subject<any>();
  @Output() userSelected: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() employeeProfileClick: EventEmitter<string> = new EventEmitter<string>();
  selectedUserData: any;
  selectedUserMonth: Date = new Date();
  isInitSubscription: boolean = true
  // isEmployeeProfileClick = false

  constructor(private userService: UserService, private calendarService: CalendarService, private configService: ConfigService) { }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.selectedMonthSubscription.unsubscribe()
    this.refreshSubscription.unsubscribe()
    this.newSelectedUserSub.unsubscribe()
  }

  ngOnInit() {


    console.log(this.empType);
    this.selectedMonthSubscription = this.calendarService.selectedMonth.subscribe(response => {
      this.selectedUserMonth = response;
      this.userService.getAdminUserList(this.empType, response, this.empSubType ? this.empSubType : 0).subscribe(
        (res: IUserInfo[]) => {
          // let filteredUserList = []
          // if (this.empType == 'Employee' && this.empSubType != 0) {
          //   if (this.empSubType == 1 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Pharmacist') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   } else if (this.empSubType == 2 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Cashier') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   }
          // } else {
          //   filteredUserList = res;
          // }

          this.usersList = res;

          if (this.selectedUserData && this.selectedUserData.userId) {
            setTimeout(() => {
              $('.employee-wrapper').removeClass('employee-wrapper-active');
              $('#employee-wrapper-' + this.selectedUserData.userId).addClass('employee-wrapper-active');
            }, 200);
          }
        },
        (error: any) => {
          this.errorMessage = error as any;
        }
      );
      // $('#employeeProfileViewModal').on('hide.bs.modal',()=>{
      //   this.isEmployeeProfileClick = false;
      // })
    });

    this.newSelectedUserSub = this.userService.newSelectedUser.subscribe(res => {
      console.log(res);
      if (res && res.userId) {
        this.userId = res.userId
        if (this.isInitSubscription) {
          this.setUserTypeTab(res.userId, res.userType, res.userSubType)
        }
        setTimeout(() => {
          $('.employee-wrapper').removeClass('employee-wrapper-active');
          $('#employee-wrapper-' + res.userId).addClass('employee-wrapper-active');
        }, 500);
      }
    })


    this.refreshSubscription = this.calendarService.userListAction.subscribe(res => {
      if (res == 'refresh') {
        this.getAdminUserList();
      }
    })

    this.getAdminUserList();
    this.isInitSubscription = false;

    this.getCurrentConfigData();

    this.getBookingUnit();

  }

  getAdminUserList() {
    this.userService.getAdminUserList(this.empType, this.selectedUserMonth, this.empSubType ? this.empSubType : 0).subscribe(
      (res: IUserInfo[]) => {
        this.usersList = res;
        if (this.selectedUserData && this.selectedUserData.userId) {
          setTimeout(() => {
            $('.employee-wrapper').removeClass('employee-wrapper-active');
            $('#employee-wrapper-' + this.selectedUserData.userId).addClass('employee-wrapper-active');
          }, 200);
        }
      },
      (error: any) => {
        this.errorMessage = error as any;
      }
    );
  }

  userSelect(userId: string, userType: string, userSubType: number, fullName: string, totalCalculatedHours: number, actualHoursBooked: number): void {
    this.selectedUserData = {
      userId,
      userType,
      userSubType,
      fullName,
      actualHoursBooked,
      totalCalculatedHours
    };
    $('.employee-wrapper').removeClass('employee-wrapper-active');
    $('#employee-wrapper-' + userId).addClass('employee-wrapper-active');

    if (this.userId == userId) {
      this.userId = null
      this.empType = userType;
      this.empSubType = userSubType ? userSubType : 0
      this.userService.emmitNewSelectedUser(null);
      $('.employee-wrapper').removeClass('employee-wrapper-active');
    } else {
      this.userService.emmitNewSelectedUser(this.selectedUserData);
    }
  }

  userTypeClick(empType: string, empSubType: number, event: any): void {
    this.empType = empType;
    this.empSubType = empSubType;
    this.userService.getAdminUserList(this.empType, this.selectedUserMonth, this.empSubType).subscribe(
      (res: IUserInfo[]) => {
        // let filteredUserList = []
        if (this.empType == 'Employee' && this.empSubType != 0) {
          //   if (this.empSubType == 1 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Pharmacist') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   } else if (this.empSubType == 2 ) {
          //     res.forEach(element => {
          //       if (element.resourceType=='Cashier') {
          //         filteredUserList.push(element)
          //       }
          //     });
          //   }
          // this.userSelected.emit(this.empSubType);
          this.userService.emmitNewSelectedUser(this.empSubType);
        } else {
          //  filteredUserList = res;
          this.userService.emmitNewSelectedUser(null);
        }
        this.usersList = res;

      },
      (error: any) => {
        this.errorMessage = error as any;
      }
    );
  }

  getBookingUnit() {
    this.configService.getBookingUnits().subscribe(res => {
      if (res) {
        this.bookingUnitName = (res.bookingUnits.length > 0) ? res.bookingUnits[0].name : '';
      }
    });
  }
  /**
   * Set the active employee type tab and highlight the user according to the selected user.
   * 
   * @param userId 
   * @param empType 
   * @param empSubType 
   */

  setUserTypeTab(userId: string, empType: string, empSubType: number): void {
    this.empType = empType;
    this.empSubType = empSubType;
    this.userService.getAdminUserList(this.empType, this.selectedUserMonth, this.empSubType ? this.empSubType : 0).subscribe(
      (res: IUserInfo[]) => {
        // let filteredUserList = []
        // if (this.empType == 'Employee' && this.empSubType != 0) {
        //   if (this.empSubType == 1 ) {
        //     res.forEach(element => {
        //       if (element.resourceType=='Pharmacist') {
        //         filteredUserList.push(element)
        //       }
        //     });
        //   } else if (this.empSubType == 2 ) {
        //     res.forEach(element => {
        //       if (element.resourceType=='Cashier') {
        //         filteredUserList.push(element)
        //       }
        //     });
        //   }
        // } else {
        //   filteredUserList = res;
        // }
        this.usersList = res;
        setTimeout(() => {
          $('.employee-wrapper').removeClass('employee-wrapper-active');
          $('#employee-wrapper-' + userId).addClass('employee-wrapper-active');
        }, 500);

      },
      (error: any) => {
        this.errorMessage = error as any;
      }
    );
  }

  onEmployeeProfileClick(userId: string): void {
    // this.isEmployeeProfileClick = true; 
    this.employeeProfileClick.emit(userId)
  }
  closeDrawer() {
    if ($('.mob-bookings').hasClass('last-selection')) {
      var tab = 'bookings'
    } else if ($('.mob-inquiry').hasClass('last-selection')) {
      var tab = 'inquiry'
    }
    $('.mob-tab').removeClass('btn-success')
    $('.mob-tab').addClass('tab-inactive')
    if (tab == 'userlist') {
      $('.mob-userlist').removeClass('tab-inactive')
      $('.mob-userlist').addClass('btn-success')
    } else if (tab == 'bookings') {
      $('.mob-bookings').removeClass('tab-inactive')
      $('.mob-bookings').addClass('btn-success')
    } else if (tab == 'inquiry') {
      $('.mob-inquiry').removeClass('tab-inactive')
      $('.mob-inquiry').addClass('btn-success')
    }
    $('#myTab').css('display', 'none')
    $('#myTabContent').css('display', 'none')
    setTimeout(() => {
      $('.k-scheduler-navigation').css('pointer-events', 'all');
      $('.k-scheduler-navigation').css('opacity', '1');
    }, 500);
  }


  getCurrentConfigData(): void {
    this.userService.getConfigData().subscribe((res) => {
      this.resourceTypes = res.resourceTypes;
      const Id = this.resourceTypes[0].id
      this.userTypeClick('Employee', Id, null)

      // setTimeout(() => {
      //   const div = document.getElementById("test");
      //   div.style.backgroundColor = "red"

      // }, 2000);

      setTimeout(() => {
        res.resourceTypes.forEach((resource,index) => {
          var divElement = document.getElementById("item-"+index);
          divElement.style.backgroundColor = resource.colorCode;
          console.log("code-",divElement.style.backgroundColor)
        });    
      }, 100);

    })
  }
}
